import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {reportSubjectChangeWithSupportingDoc} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import {CommonForeignIdCard} from '../../subject/commonInfo/common_foreign_id_card'

class ReportForeignIdCardComp extends Component {
    constructor(props) {
        super(props);
        this.state={
            isLoading: false,
        }
    }

    onSubmit(values) {
        this.setState({isLoading: true});
        const url = `subject/selfReporting/reportForeignIdCard`;
        this.props.reportSubjectChangeWithSupportingDoc(values, url, () => {
            this.props.showSuccessGrowl("Foreign ID Card Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        });
    }

    close = () => {
        this.props.history.push("/reporting/subject");
    }

    render() {
        return (
            <div className="reporting_bg">
                <div className="container">
                <Fragment>
                    <div className="heading_container">
                        <h3>Report Foreign ID Card</h3>
                    </div>
                       <CommonForeignIdCard onSubmit={this.onSubmit.bind(this)} onCancel={this.close} />   
                  </Fragment>
            </div>   
            {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            
        </div>
            
        )
    }
}



export const ReportForeignIdCard = connect(null, {reportSubjectChangeWithSupportingDoc, showSuccessGrowl, showErrorGrowl})(ReportForeignIdCardComp);