import React, { useCallback } from "react";
import {REPORTING_PASS_CODE, VERIFIED_EMAIL} from "../../../actions/auth_actions";

import Tooltip  from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import addressicon from "../../../assets/images/icons/Personal_Info/Address.png";
import Marital_statusicon from "../../../assets/images/icons/Personal_Info/Marital_status.png";
import cohabitanticon from "../../../assets/images/icons/Personal_Info/cohabitant.png";
import arresticon from "../../../assets/images/icons/Personal_Info/arrest.png";
import drug_rehabicon from "../../../assets/images/icons/Misc/drug_rehab.png";
import media_contacticon from "../../../assets/images/icons/Misc/media_contact.png";
import financial_issuesicon from "../../../assets/images/icons/Misc/financial_issues.png";

 import {Link} from "react-router-dom";
import {CommonUtils} from "../../../actions/common_utils";
import Button from "react-bootstrap/cjs/Button";

 const ReportingPersonalInformation =(props)=>{


    const address = props => (
        <Tooltip {...props}>Subject's home address</Tooltip>
      );

    const martialstatus = props => (
    <Tooltip {...props}>Updated marital status could require additional investigative requirements based on Federal Investigative Standards (FIS)</Tooltip>
    );

    const cohabitant  = props => (
        <Tooltip {...props}>A person with whom the covered individual resides and shares bonds of affection, obligation or other commitment.</Tooltip>
        );
    const Arrest   = props => (
        <Tooltip {...props}>Initial arrests, charges and convictions should be reported</Tooltip>
        );
    const drugtreatment  = props => (
        <Tooltip {...props}>Voluntary or mandated Alcohol and Drug Treatment</Tooltip>
        );
    const financialissue  = props => (
        <Tooltip {...props}>Bankruptcies, Inheritances and other financial anomalies</Tooltip>
        );
    const mediacontact  = props => (
        <Tooltip {...props}>Where media seeks access to classified or other information specifically prohibited by law from disclosure.</Tooltip>
        );
   
    return(
        <div className="reporting_bg">
              
        <>
           
            <div className="reporting_screen1 show_personal_info container ">
                <div className="heading_container">
                    <h3>Personal Information</h3>
                </div>

                <div className="col-lg-12 row justify-center m-0">
                    <div className="col-lg-3 mb-5">
                   
                        <div className="border-highlt">
                       
                        <Link to={"/reporting/subject/personalInfo/address"}>
                        <OverlayTrigger placement="bottom" overlay={address} className="tooltip">
                        <div className="h_icons" eventKey="#personalLink">
                       
                                <img src={addressicon} className="f_icons"></img>
                                <div className="tab_name">
                                <h5><Link to={"/reporting/subject/personalInfo/address"}>Address</Link></h5>
                                </div>
                               
                            </div>
                            </OverlayTrigger>
                        </Link>
                       
                        </div>
                        
                    </div>

                    <div className="col-lg-3 mb-5">
                        <div className="border-highlt"> 
                        <Link to={"/reporting/subject/personalInfo/maritalStatus"}>
                        <OverlayTrigger placement="bottom" overlay={martialstatus}>
                        <div className="h_icons" eventKey="#foreignLink">
                                <img src={Marital_statusicon} className="f_icons"></img>
                                <div className="tab_name">
                                <h5><Link to={"/reporting/subject/personalInfo/maritalStatus"}>Marital Status</Link></h5>
                                </div>
                            </div>
                            </OverlayTrigger>
                        </Link>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-5">
                        <div className="border-highlt" title=""> 
                        <Link to={"/reporting/subject/personalInfo/cohabitant"}>
                        <OverlayTrigger placement="bottom" overlay={cohabitant} className="tooltip">
                        <div className="h_icons">
                                <img src={cohabitanticon} className="f_icons"></img>

                                <div className="tab_name">
                                    <h5><Link to={"/reporting/subject/personalInfo/cohabitant"}>Cohabitant</Link></h5>
                                </div>
                            </div>
                            </OverlayTrigger>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-5">
                        <div className="border-highlt"> 
                        <Link to={"/reporting/subject/personalInfo/arrest"}>
                        <OverlayTrigger placement="bottom" overlay={Arrest} className="tooltip">
                        <div className="h_icons">
                                <img src={arresticon} className="f_icons"></img>

                                <div className="tab_name">
                                    <h5><Link to={"/reporting/subject/personalInfo/arrest"}>Arrest</Link></h5>
                                </div>
                            </div>
                            </OverlayTrigger>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-5">
                        <div className="border-highlt"> 
                        <Link to={"/reporting/subject/alcoholDrugTreatment"}>
                        <OverlayTrigger placement="bottom" overlay={drugtreatment} className="tooltip">
                        <div className="h_icons">
                                <img src={drug_rehabicon} className="f_icons"></img>

                                <div className="tab_name">
                                    <h5><Link to={"/reporting/subject/alcoholDrugTreatment"}>Alcohol & Drug Treatment</Link></h5>
                                </div>
                            </div>
                            </OverlayTrigger>
                            </Link>
                        </div>
                    </div>

                  

                    <div className="col-lg-3 mb-5">
                        <div className="border-highlt"> 
                        <Link to={"/reporting/subject/financialIssueAnomaly"}>
                        <OverlayTrigger placement="bottom" overlay={financialissue} className="tooltip">
                        <div className="h_icons">
                                <img src={financial_issuesicon} className="f_icons"></img>

                                <div className="tab_name">
                                    <h5><Link to={"/reporting/subject/financialIssueAnomaly"}>Financial Issue & Anomaly</Link></h5>
                                </div>
                            </div>
                            </OverlayTrigger>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-5">
                        <div className="border-highlt"> 
                        <Link to={"/reporting/subject/other/mediaContact"}>
                        <OverlayTrigger placement="bottom" overlay={mediacontact} className="tooltip">
                        <div className="h_icons">
                                <img src={media_contacticon} className="f_icons"></img>

                                <div className="tab_name">
                                    <h5><Link to={"/reporting/subject/other/mediaContact"}>Media Contact</Link></h5>
                                </div>
                            </div>
                            </OverlayTrigger>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="back_btn_block">
                    <Button className="btn btn-back" onClick={()=>{props.history.push('/reporting/subject')}}>Back</Button>
                    <Button className="btn btn-back" onClick={()=>{
                        CommonUtils.handleExit();
                        props.history.push('/reporting/subject/emailCheck')}
                    }>Exit</Button>
                </div>
            </div>
           
        </>
</div>
    )
}
export default ReportingPersonalInformation