import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Field, reduxForm} from "redux-form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {getInvalidationTypesByReportableType} from "../../../actions/fieldValue_actions";
import {FieldRBDatePicker, FieldRBSelectWithKeys} from "../../../components/redux_form/field_components_rb";
import {DateUtils} from "../../../actions/common_utils";
import {PersonalInfoAddress} from "./personalInfo/review_personalInfo_address";
import {showSuccessGrowl} from "../../../actions/msg_actions";
import {PersonalInfoMaritalStatus} from "./personalInfo/review_personalInfo_maritalstatus";
import {ReportableForeignContact} from "./foreignTravel/review_foreign_contacts";
import {PersonalInfoCohabitant} from "./personalInfo/review_personalInfo_cohabitant";
import {PersonalInfoArrest} from "./personalInfo/review_personalInfo_arrest";
import {ReportableForeignCitizenship} from "./foreignTravel/review_foreign_citizenship";
import {ReportableForeignBankAccount} from "./foreignTravel/review_foreign_bank_account";
import {ReportableForeignProperty} from "./foreignTravel/review_foreign_property";
import {ReportableForeignIdCard} from "./foreignTravel/review_foreign_id_card";
import {ReportableForeignBusinessInvolvement} from "./foreignTravel/review_foreign_business_involvement";

class ReportingSubjectReviewDetailsModalComponent extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            showModal: false,
            availInvalidateReasons: [{id: "none", name: "Select Reason..."}]
        }
    }

    open() {
        getInvalidationTypesByReportableType(this.props.reportable.type).then((invalidationTypes) => {
            const reasons = [{id: "none", name: "Select Reason..."}];

            invalidationTypes.forEach((type) => {
                reasons.push({
                    id: type.id,
                    name: type.displayName
                });
            });

            this.setState({
                availInvalidateReasons: reasons
            });
        });

        this.setState({
            showModal: true
        });
    }

    close() {
        this.props.change("dateInvalidated", null);
        this.props.change("reasonInvalidated", "none");

        this.setState({
            showModal: false
        });
    }

    onSubmit(values) {
        this.props.onInvalidate(this.props.reportable.id, values.dateInvalidated, values.reasonInvalidated,
            this.state.availInvalidateReasons.find(r => r.id === values.reasonInvalidated).name
        );
        this.close();
    }

    onMarkReviewed() {
        this.props.onReviewed(this.props.reportable.id);
        this.close();
    }

    getReportableContent() {
        const {reportable} = this.props;

        switch (reportable.type) {
            case "PERSONNEL_INFO_ADDRESS":
                return <PersonalInfoAddress address={reportable}/>;
            case "PERSONNEL_INFO_MARITAL_STATUS":
                return <PersonalInfoMaritalStatus maritalStatus={reportable}/>;
            case "FOREIGN_CONTACT":
                return <ReportableForeignContact contact={reportable}/>;
            case "COHABITANT":
                return <PersonalInfoCohabitant cohabitant={reportable}/>;
            case "ARRESTS":
                return <PersonalInfoArrest arrest={reportable}/>;
            case "FOREIGN_CITIZENSHIP":
                return <ReportableForeignCitizenship citizenship={reportable}/>;
            case "FOREIGN_BUSINESS_INVOLVEMENT":
                return <ReportableForeignBusinessInvolvement businessInvolvement={reportable} />;
            case "FOREIGN_BANK_ACCOUNT":
                return <ReportableForeignBankAccount bankAcct={reportable} />;
            case "FOREIGN_PROPERTY_OWNERSHIP":
                return <ReportableForeignProperty property={reportable} />;
            case "FOREIGN_PASSPORT_ID_POSSESSION":
                return <ReportableForeignIdCard idCard={reportable} />;
        }
        return "";
    }

    render() {
        const {showModal, availInvalidateReasons} = this.state;
        const {handleSubmit, reportable} = this.props;

        return (
            <Fragment>
                <Button onClick={this.open} variant="primary">Review</Button>

                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Review Information</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Body>
                            <Container>
                                {this.getReportableContent()}
                                <Form.Row>
                                    <Form.Group as={Col} md={6} controlId="startDate">
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control plaintext readOnly
                                                      value={reportable.startDate ? DateUtils.getDateString(reportable.startDate) : "N/A"}/>
                                    </Form.Group>
                                    <Col md={6}>
                                        <Field name="dateInvalidated" label="End Date" component={FieldRBDatePicker}
                                               required/>
                                    </Col>
                                </Form.Row>
                                <Field name="reasonInvalidated" label="Reason for Change"
                                       component={FieldRBSelectWithKeys}
                                       options={availInvalidateReasons} required/>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit">Apply Changes</Button>
                                <Button variant="secondary" onClick={() => this.onMarkReviewed()}>No Changes</Button>
                                <Button variant="danger" onClick={this.close}>Cancel</Button>
                            </ButtonToolbar>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.reasonInvalidated || values.reasonInvalidated === "none") {
        errors.reasonInvalidated = "Reason is required!";
    }

    if (!values.dateInvalidated) {
        errors.dateInvalidated = "End Date is required!";
    }

    return errors;
}

export const ReportingSubjectReviewDetailsModal = reduxForm({
    validate,
    form: "ReportingSubjectReviewForm",
    initialValues: {
        reasonInvalidated: "none"
    }
})(
    connect(null, {showSuccessGrowl})(ReportingSubjectReviewDetailsModalComponent)
);

ReportingSubjectReviewDetailsModal.propTypes = {
    reportable: PropTypes.object.isRequired,
    onInvalidate: PropTypes.func.isRequired,
    onReviewed: PropTypes.func.isRequired
};