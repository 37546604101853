import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {DateUtils} from "../../../../actions/common_utils";
import {ButtonToolbar} from "react-bootstrap";
import {SubjectReportableUpdateModal} from "../subject_reportable_update_modal";
import {getSubjectForSelfReporting} from "../../../../actions/subject_actions";

export class ReportingSubjectForeignCitizenshipDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            foreignCitizenships: []
        };
    }

    async componentDidMount() {
        await this.refreshSubject();
    }

    refreshSubject = async () => {
        this.setState({
            foreignCitizenships: []
        });

        const subject = await getSubjectForSelfReporting();

        this.setState({
            foreignCitizenships: subject.foreignCitizenships
        });
    }

    getForeignCitizenships() {
        return this.state.foreignCitizenships.map((citizenship) => {
            return <tr key={citizenship.id}>
                <td>{citizenship.country}</td>
                <td>{citizenship.basis}</td>
                <td>{citizenship.startDate ? DateUtils.getDateString(citizenship.startDate) : "Unknown"}</td>
                <td>{citizenship.dateInvalidated ? DateUtils.getDateString(citizenship.dateInvalidated) : "Present"}</td>
                <td>
                    <ButtonToolbar className="p-0 mb-0 float-right" style={{flexWrap: "nowrap"}}>
                        {!citizenship.dateInvalidated &&
                        <SubjectReportableUpdateModal reportable={citizenship}
                                                      reportableTypeString={"Foreign Citizenship"}
                                                      onUpdate={this.refreshSubject}/>
                        }
                    </ButtonToolbar>
                </td>
            </tr>
        })
    }

    render() {
        const {foreignCitizenships} = this.state;

        if (!foreignCitizenships || foreignCitizenships.length === 0) {
            return <Alert variant="danger">No Foreign Citizenships!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Country</th>
                        <th>Basis of Citizenship</th>
                        <th>Date Acquired</th>
                        <th>Date Relinquished</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignCitizenships()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}