import React, {Component, Fragment} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {reportSubjectInitiateReview} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Card from "react-bootstrap/Card";
import {FieldRBInput} from "../../../../components/redux_form/field_components_rb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import {Link} from "react-router-dom";

class ReportInitiateSubjectReviewComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        values.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        this.props.reportSubjectInitiateReview(values).then(() => {
            this.props.showSuccessGrowl("Employee Review Initiated", "Please check your email for further information!");
            this.props.history.push("/reporting/subject");
        }).catch((error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
        });
    }

    render() {
        const {handleSubmit} = this.props;

        console.log('Email Address is ', sessionStorage.getItem(VERIFIED_EMAIL))
        return (
            <div className="container-fluid self_reporting"> 
            <div className="col-lg-8 margin-auto">
            <Fragment>
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div className="heading_container">
                    <h3>Initiate Employee Information Review</h3>
                    </div>
                   
                    <Card>
                        <Card.Header>Confirm Employee Review for {sessionStorage.getItem(VERIFIED_EMAIL)}</Card.Header>
                    </Card>

                    <div className="btn_block">
                    <ButtonToolbar>
                        <Button variant="primary" type="submit">Confirm</Button>
                        <Link to="/reporting/subject" className="btn btn-danger">Cancel</Link>
                    </ButtonToolbar>
                    </div>
                 
                </Form>
            </Fragment>
            </div>
            </div>
        )
    }
}

function validate(values) {
    const errors = {};

    return errors;
}

export const ReportInitiateSubjectReview = reduxForm({
    validate,
    form: "ReportInitiateSubjectReviewForm",
    initialValues: {
        emailAddress: sessionStorage.getItem(VERIFIED_EMAIL)
    }
})(
    connect(null, {reportSubjectInitiateReview, showSuccessGrowl, showErrorGrowl})(ReportInitiateSubjectReviewComponent)
);