import React, {Component, Fragment} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {reportAddressChange} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Card from "react-bootstrap/Card";
import {
    FieldRBCountrySelector, FieldRBDatePicker,
    FieldRBInput,
    FieldRBRegionSelector
} from "../../../../components/redux_form/field_components_rb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {ConditionalDisplay, isReadOnlyRole} from "../../../util/shouldDisplay";

import { withRouter } from 'react-router-dom';
import { CommonPersonalInfoAddress } from "../commonInfo/common_personalInfo_address";

class ReportPersonalInfoAddress extends Component {
    constructor(props) {
        super(props);

        this.state={
            isLoading: false,
        }

        const handleClick = () => {
            console.log('Button clicked!');
          };
    }

   

    handleGoBack = () => {
        this.props.history && this.props.history.goBack();
      }

    onSubmit(values) {
        this.setState({isLoading: true});
        values.current = true;
        this.props.reportAddressChange(values, () => {
            this.props.showSuccessGrowl("Address Change Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
            this.setState({isLoading: false});
        });
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {handleSubmit, sel_country} = this.props;

        return (

        <div className="reporting_bg">
            <div className="container">
                <div className="heading_container">
                    <h3>Report Address Change Form</h3>
                </div>
                <CommonPersonalInfoAddress onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
            </div>
            {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
        </div>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.address1) {
        errors.address1 = "Street Address is required!";
    }
    if (!values.city) {
        errors.city = "City is required!";
    }
    if (!values.country) {
        errors.country = "Country is required!";
    }
    if (!values.postalCode) {
        errors.postalCode = "Postal or Zip Code is required!";
    }
    if (!values.startDate) {
        errors.startDate = "Enter date moved in!";
    }

    return errors;
}

function mapStateToProps(state) {
    const formValues = state.form.ReportAddressChangeForm;
    let selCountry = "";
    if (formValues.values && formValues.values.country) {
        selCountry = formValues.values.country;
    }
    return {
        sel_country: selCountry,
    }
}

export default reduxForm({
    validate,
    form: "ReportAddressChangeForm",
    initialValues: {
        country: "US"
    }
})(
    connect(mapStateToProps, {reportAddressChange, showSuccessGrowl, showErrorGrowl})(ReportPersonalInfoAddress)
);