import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {getSubjectForSelfReporting} from "../../../../actions/subject_actions";
import {ButtonToolbar} from "react-bootstrap";
import {SubjectReportableUpdateModal} from "../subject_reportable_update_modal";
import {DateUtils} from "../../../../actions/common_utils";

export class ReportingSubjectMaritalDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            spouseHistory: []
        };
    }

    async componentDidMount() {
        await this.refreshSubject();
    }

    refreshSubject = async () => {
        this.setState({
            spouseHistory: []
        });

        const subject = await getSubjectForSelfReporting();

        this.setState({
            spouseHistory: subject.spouseHistory
        });
    }

    getMartitalStatusHistory() {
        return this.state.spouseHistory.map((spouse) => {
            return <tr key={spouse.id}>
                <td>{spouse.firstName} {spouse.lastName}</td>
                <td>{spouse.startDate ? DateUtils.getDateString(spouse.startDate) : "Unknown"}</td>
                <td>
                    {spouse.dateInvalidated ?
                        `${DateUtils.getDateString(spouse.dateInvalidated)} (${spouse.reasonInvalidated ? spouse.reasonInvalidated.displayName : "Unknown"})`
                        : "Present"}
                </td>
                <td>
                    <ButtonToolbar className="p-0 mb-0 float-right" style={{flexWrap: "nowrap"}}>
                        {!spouse.dateInvalidated &&
                        <SubjectReportableUpdateModal reportable={spouse}
                                                      reportableTypeString={"Marital Status"}
                                                      onUpdate={this.refreshSubject}/>
                        }
                    </ButtonToolbar>
                </td>
            </tr>
        })
    }

    render() {
        const {spouseHistory} = this.state;

        if (!spouseHistory || spouseHistory.length === 0) {
            return <Alert variant="danger">No Marital Status History!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {this.getMartitalStatusHistory()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}