import React, { Component } from "react";
import { Button, Card, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { connect } from "react-redux";
import { reportAlcoholDrugUse } from "../../../actions/subject_actions";
import { showErrorGrowl, showSuccessGrowl } from "../../../actions/msg_actions";
import { VERIFIED_EMAIL } from "../../../actions/auth_actions";
import { Link } from "react-router-dom";
import DeleteConfirmationModal from "../../../components/delete_confirm_modal";
import { CommonReportAlcoholDrug } from "./commonInfo/common_report_alcohol_drug_treatment";

class ReportAlcoholDrugTreatmentComp extends Component {
    constructor(props) {
        super(props);

        this.state={
            isLoading: false,
        }
    }

    onSubmit = (values) => {
        this.setState({isLoading: true});
        this.props.reportAlcoholDrugUse(values, () => {
            this.props.showSuccessGrowl("Your information is Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')) {
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        })
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <div className="reporting_bg">
                <div className="container">
                    <div className="heading_container">
                        <h3>Report Alcohol & Drug Treatment</h3>
                    </div>
                    {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
                   <CommonReportAlcoholDrug onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel.bind(this)} />
                </div>
            </div>
        );
    }
}

export const ReportAlcoholDrugTreatment = connect(null, { reportAlcoholDrugUse, showSuccessGrowl, showErrorGrowl })(ReportAlcoholDrugTreatmentComp);