/**
 * Created by pshivaraman on 3/23/18.
 */

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import OrgItem from "./org_item";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideBar from "../../components/sidebar";
import {fetchOrgsAndWait, setOrg} from "../../actions/org_actions";
import {fetchOrgFacilities} from "../../actions/facility_actions";
import {showErrorGrowl} from "../../actions/msg_actions";
import {CommonUtils} from "../../actions/common_utils";
import { Card } from "react-bootstrap";
import { ChatBotComponent } from "../../components/chatbotComponent";

class OrgList extends Component {
    constructor(props) {
        super(props);
        this.state =  {

        }

    }
    componentDidMount() {
        this.getOrgs();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log('Next props', nextProps)
        this.setState({
            orgs: nextProps.orgs
        })
    }

    getOrgs = async () => {
        let orgs = await fetchOrgsAndWait(
            (error) => {
                this.props.showErrorGrowl("Server Error", "A server error occured!");
                console.log('Error is ', error);
            }
        )
        if (orgs && orgs.length === 1) { //Check ===
            console.log(orgs);
            const orgObj = orgs[0].org
            setOrg(orgObj.id);
            fetchOrgFacilities(orgObj.id);
            this.props.history.push('/org/dash');
        }
        this.setState({orgs: orgs});
        console.log(orgs);
    }

    render(){

        const {orgs} = this.state;

        if (!orgs) {
            return (
                <div>Loading...</div>
            )
        }
        // if (orgs.length === 0) {
        //     return (
        //         <Alert variant="danger">No Organizations Found!</Alert>
        //     )
        // }
        const orgItems = orgs.map((org, i) => {
            return <OrgItem key={i} org={org}/>;
        });
        return (

            <div className="reporting_bg">
                <div className="container-fluid">

                
                <div className="heading_container">
                    <h3>Organization</h3>
                </div>
              
                <Fragment>
                <Row>
                    <Col sm={4} md={3}>
                        <SideBar {...this.props}/>
                    </Col>
                    <Col sm={8} md={9}>

                                <Card>
                                    <Card.Header>
                                        <Card.Title>
                                            Organization Results
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="table-responsive react-bootstrap-table">
                                            <table className="table">
                                                <thead>
                                                    <tr className="fw-bold text-muted"><th>Authors</th>
                                                        <th>Clearence Level/Subject Count</th>
                                                        <th>Location Count</th>
                                                        {/* <th>Compliance</th> */}
                                                        {/* <th>Location Count</th> */}
                                                        {/* <th>Subject Count</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {orgItems}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>
                    </Col>
                </Row>
            </Fragment>

            </div>
            <ChatBotComponent />
            </div>

          
        )
    }
}

function mapStateToProps({orgs}) {
    return {
        orgs, // same as orgs: orgs
    }
}

export default connect( mapStateToProps, {showErrorGrowl})(OrgList);