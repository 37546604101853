import React, {Component, Fragment} from "react";
import {Button, ButtonToolbar, Card, Col, Form, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class AddEmergencyContactComponent extends Component {
    constructor(props) {
        super(props);

    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onAddEmergencyContact(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        let {previewdata} = this.props;
        const {previousPage} = this.props;
        return (
            <Fragment>
                <Formik
                    initialValues={{
                        emergencyContactName: previewdata.emergencyContactName,
                        emergencyContactPhone: previewdata.emergencyContactPhone,
                        emergencyContactEmail: previewdata.emergencyContactEmail,
                        emergencyContactAddress: previewdata.emergencyContactAddress
                    }}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    validationSchema={
                        Yup.object().shape({
                            emergencyContactName: Yup.string().required('Please enter an emergency contact name'),
                            emergencyContactPhone: Yup.string().when(['emergencyContactEmail', 'emergencyContactAddress'], {
                                is: (emergencyContactEmail, emergencyContactAddress) => !emergencyContactEmail && !emergencyContactAddress,
                                then: Yup.string().required('One of the contact type is required')
                            }),
                            emergencyContactEmail: Yup.string().email('Enter a valid email').when(['emergencyContactPhone', 'emergencyContactAddress'], {
                                is: (emergencyContactPhone, emergencyContactAddress) => !emergencyContactPhone && !emergencyContactAddress,
                                then: Yup.string().required('One of the contact type is required')
                            }),
                            emergencyContactAddress: Yup.string().when(['emergencyContactEmail', 'emergencyContactPhone'], {
                                is: (emergencyContactEmail, emergencyContactPhone) => !emergencyContactEmail && !emergencyContactPhone,
                                then: Yup.string().required('One of the contact type is required')
                            })
                        }, [
                            ['emergencyContactEmail', 'emergencyContactAddress'],
                            ['emergencyContactPhone', 'emergencyContactAddress'],
                            ['emergencyContactPhone', 'emergencyContactEmail']
                        ])
                    }
                >
                    {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            
                            <Card>
                                <Card.Header>Emergency Contact</Card.Header>
                                <Card.Body>
                                    <p>Enter the Name and at least ONE method of communication for the Emergency
                                        Contact</p>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control required type="text" name={'emergencyContactName'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.emergencyContactName && !!errors.emergencyContactName}
                                                          value={values.emergencyContactName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.emergencyContactName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Phone No.</Form.Label>
                                            <Form.Control required type="text" name={'emergencyContactPhone'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.emergencyContactPhone && !!errors.emergencyContactPhone}
                                                          value={values.emergencyContactPhone}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.emergencyContactPhone}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control required type="text" name={'emergencyContactEmail'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.emergencyContactEmail && !!errors.emergencyContactEmail}
                                                          value={values.emergencyContactEmail}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.emergencyContactEmail}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control required type="text" name={'emergencyContactAddress'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.emergencyContactAddress && !!errors.emergencyContactAddress}
                                                          value={values.emergencyContactAddress}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.emergencyContactAddress}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </Card.Body>
                                <Card.Footer>
                                <div className={"pull-right1"}>
                                    <ButtonToolbar>
                                    <Button variant="primary" onClick={previousPage}>Previous</Button>
                                        <Button variant="primary" disabled={isSubmitting} type="submit">Next</Button>
                                        {/* <Button variant="danger" onClick={this.props.onCancel}>Cancel</Button> */}
                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Foreign Travel"
                                                         handleDeleteCallback={this.handleCancel}/>
                                    </ButtonToolbar>
                                </div>
                            </Card.Footer>
                            </Card>
                           
                           
                        </Form>
                    )}
                </Formik>
            </Fragment>
        )
    }
}

AddEmergencyContactComponent.propTypes = {
    onAddEmergencyContact: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    previewdata: PropTypes.object.isRequired
};

export const AddEmergencyContact = AddEmergencyContactComponent;