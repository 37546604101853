import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { FieldRBDatePicker, FieldRBInput, FieldRBSelect, FieldRBSelectWithKeys } from "../../../../components/redux_form/field_components_rb";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";

class CommonReportForeignPropertyComponent extends Component {
    constructor(props) {
        super(props); 
    }

    onSubmit(values) { 
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }
 
    render() {
        const { handleSubmit } = this.props;
        return ( 
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Card>
                <Card.Header>Foreign Property Information</Card.Header>
                <Card.Body>
                    <div className="col-lg-12 row m-0 p-0">
                        <div className="col-lg-4">
                            <Field name="location" component={FieldRBInput} label="Location" required horizontal/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="valueUsd" component={FieldRBInput} label="Property Value (USD)" required horizontal/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="balanceDue" component={FieldRBInput} label="Balance Due (USD)" required horizontal/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="purpose" component={FieldRBInput} label="Purpose" required horizontal/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="howAcquired" component={FieldRBInput} label="How was the property acquired?" required horizontal/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="startDate" component={FieldRBDatePicker} label="Purchase Date" required horizontal/>
                        </div>
                    </div>  
                </Card.Body>
                <Card.Footer>
                <ButtonToolbar>
                <Button variant="primary" type="submit">Submit</Button>
                {/* <Link to="/reporting/subject/reportingForeignInformation" className="btn btn-danger">Cancel</Link> */}
                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Foreign Property Form"
                                                 handleDeleteCallback={this.handleCancel.bind(this)}/>
            </ButtonToolbar>
                </Card.Footer>

               
            </Card>
          
        </Form>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.location) {
        errors.location = "Location is required!";
    }
    if (!values.valueUsd) {
        errors.valueUsd = "Value is required!";
    } else if (!/^\d+(\.\d{1,2})?$/.test(values.valueUsd)) {
        errors.valueUsd = "Invalid dollar amount. Please enter a valid amount, e.g., 100.00";
    }

    if (!values.balanceDue) {
        errors.balanceDue = "Balance Due is required!";
    } else if (!/^\d+(\.\d{1,2})?$/.test(values.balanceDue)) {
        errors.balanceDue = "Invalid dollar amount. Please enter a valid amount, e.g., 100.00";
    }
    if (!values.purpose) {
        errors.purpose = "Purpose is required!";
    }
    if (!values.howAcquired) {
        errors.howAcquired = "How Property Was Acquired is required!";
    }
    if (!values.startDate) {
        errors.startDate = "Enter Start Date!";
    }
    return errors;
}

export const CommonReportForeignProperty = reduxForm({
    validate,
    form: "ReportForeignPropertyForm"
})(
    connect(null, { showSuccessGrowl, showErrorGrowl })(CommonReportForeignPropertyComponent)
);
 