import React, { useCallback } from "react";
import Tooltip  from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import {REPORTING_PASS_CODE, VERIFIED_EMAIL} from "../../../actions/auth_actions";

import Travel_icon from "../../../assets/images/icons/Foreign_Information/foreign_Travel.png";
import Contacts_icon from "../../../assets/images/icons/Foreign_Information/Foreign_Contacts.jpeg";
import Intelligence_icon from "../../../assets/images/icons/Foreign_Information/Foreign_Intelligence.png";
import voting_icon from "../../../assets/images/icons/Foreign_Information/foreign_voting.png";
import Citizenship_icon from "../../../assets/images/icons/Foreign_Information/Foreign_Citizenship.png";
import Bank_icon from "../../../assets/images/icons/Foreign_Information/Foreign_Bank.png";
import Business_icon from "../../../assets/images/icons/Foreign_Information/Foreign_Business.png";
import Property_icon from "../../../assets/images/icons/Foreign_Information/Foreign_Property.png";
import adoption_icon from "../../../assets/images/icons/Foreign_Information/foreign_adoption.png";
import blackmail_icon from "../../../assets/images/icons/Foreign_Information/blackmail.png";
import id_card from "../../../assets/images/icons/Foreign_Information/id_card.png";
 import {Link} from "react-router-dom";
import {CommonUtils} from "../../../actions/common_utils";
import Button from "react-bootstrap/cjs/Button";

 const ReportingForeignInformation =(props)=>{


    const travel = props => (
        <Tooltip {...props}>Covered Individuals shall submit an itinerary for unofficial foreign travel.</Tooltip>
      );
      const contacts = props => (
        <Tooltip {...props}>Unofficial contact and continuing association of a foreign national that involves the exchange of personal information.</Tooltip>
      );
      const card = props => (
        <Tooltip {...props}>Foreign identification cards besides citizenship documents</Tooltip>
      );
      const voting = props => (
        <Tooltip {...props}>Voting in foreign elections</Tooltip>
      );
      const citizenship = props => (
        <Tooltip {...props}>Foreign Citizenship and Foreign Identification Documents</Tooltip>
      );
      const bankaccount = props => (
        <Tooltip {...props}>Foreign Bank Account information</Tooltip>
      );
      const business = props => (
        <Tooltip {...props}>Nature and Country of involvement and Business name</Tooltip>
      );
      const property = props => (
        <Tooltip {...props}>Ownership or financial interest in Foreign Property </Tooltip>
      );
      const adoption = props => (
        <Tooltip {...props}>Adoption of non-U.S. citizen children</Tooltip>
      );
      const blackmail = props => (
        <Tooltip {...props}>Attempted elicitation, exploitation, or enticement to obtain classified or other information specifically prohibited by law from disclosure.</Tooltip>
      );
   
    return(
        <div className="reporting_bg">
              
        <>
           
        <div className="reporting_screen1 foreign_info container ">
                        <div className="heading_container">
                            <h3>Foreign Information</h3>
                        </div>

                        <div className="col-lg-12 row justify-center m-0">
                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/main"}>
                                    <OverlayTrigger placement="bottom" overlay={travel} className="tooltip">
                                        <div className="h_icons" eventKey="#personalLink">
                                            <img src={Travel_icon} className="f_icons"></img>

                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/main"}>Travel</Link></h5>

                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/idCard"}>
                                    <OverlayTrigger placement="bottom" overlay={card} className="tooltip">
                                        <div className="h_icons" eventKey="#foreignLink">
                                            <img src={id_card} className="f_icons"></img>

                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/idCard"}>Cards</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/contacts"}>
                                    <OverlayTrigger placement="bottom" overlay={contacts} className="tooltip">
                                        <div className="h_icons" eventKey="#foreignLink">
                                            <img src={Contacts_icon} className="f_icons"></img>

                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/contacts"}>Contacts</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/intelligenceContact"}>
                                    <OverlayTrigger placement="bottom" overlay={contacts} className="tooltip">
                                        <div className="h_icons" eventKey="#foreignLink">
                                            <img src={Intelligence_icon} className="f_icons"></img>

                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/intelligenceContact"}>Intelligence Contact</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/voting"}>
                                    <OverlayTrigger placement="bottom" overlay={voting} className="tooltip">
                                        <div className="h_icons">
                                            <img src={voting_icon} className="f_icons"></img>

                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/voting"}>Voting</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/citizenship"}>
                                    <OverlayTrigger placement="bottom" overlay={citizenship} className="tooltip">
                                        <div className="h_icons">
                                            <img src={Citizenship_icon} className="f_icons"></img>

                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/citizenship"}>Citizenship</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/bankAccount"}>
                                    <OverlayTrigger placement="bottom" overlay={bankaccount} className="tooltip">
                                        <div className="h_icons">
                                            <img src={Bank_icon} className="f_icons"></img>

                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/bankAccount"}>Bank Account</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/businessInvolvement"}>
                                    <OverlayTrigger placement="bottom" overlay={business} className="tooltip">
                                        <div className="h_icons">
                                            <img src={Business_icon} className="f_icons"></img>

                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/businessInvolvement"}>Business Involvement</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/property"}>
                                    <OverlayTrigger placement="bottom" overlay={property} className="tooltip">
                                        <div className="h_icons">
                                            <img src={Property_icon} className="f_icons"></img>
                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/property"}>Property</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/adoption"}>
                                    <OverlayTrigger placement="bottom" overlay={adoption} className="tooltip">
                                        <div className="h_icons">
                                            <img src={adoption_icon} className="f_icons"></img>
                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/adoption"}>Child Adoption</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 mb-5">
                            <div className="border-highlt">
                                <Link to={"/reporting/subject/foreignTravel/blackmail"}>
                                    <OverlayTrigger placement="bottom" overlay={blackmail} className="tooltip">
                                        <div className="h_icons">
                                            <img src={blackmail_icon} className="f_icons"></img>
                                            <div className="tab_name">
                                                <h5><Link to={"/reporting/subject/foreignTravel/blackmail"}>Blackmail / Elicitation</Link></h5>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>
                        </div>

                        <div className="back_btn_block">
                            <Button className="btn btn-back" onClick={()=>{props.history.push('/reporting/subject')}}>Back</Button>
                            <Button className="btn btn-back" onClick={()=>{
                                CommonUtils.handleExit();
                                props.history.push('/reporting/subject/emailCheck')}
                            }>Exit</Button>
                        </div>
                    </div>
           
        </>
</div>
    )
}
export default ReportingForeignInformation