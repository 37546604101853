import React, {Component, Fragment} from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";

export class PersonalInfoMaritalStatus extends Component {
    render(){
        const {maritalStatus} = this.props;

        return (
            <Fragment>
                <Form.Row>
                    <Form.Group as={Col} md={6} controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control plaintext readOnly value={maritalStatus.firstName}/>
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control plaintext readOnly value={maritalStatus.lastName}/>
                    </Form.Group>
                </Form.Row>
            </Fragment>
        )
    }
}

PersonalInfoMaritalStatus.propTypes = {
    maritalStatus: PropTypes.object.isRequired
};