import React, {Component, Fragment} from "react";
import {Formik} from "formik";
import {Button, Card, Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {getForeignContactQuestionTypes} from "../../../../../actions/fieldValue_actions";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ForeignContactQuestionnaireComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            generalQuestionOptions: []
        }
    }

    componentDidMount() {
        // Get General Question Types
        getForeignContactQuestionTypes().then((response) => {
            const questionOptions = response.data.map((questionType) => {
                return {
                    questionType: questionType,
                    response: false,
                    comments: ""
                };
            });

            this.setState({
                generalQuestionOptions: questionOptions
            });
        });
    }

    onSubmit = (values, actions) => {
        if (values && values.generalQuestions) {
            for (let eachQuestion of values.generalQuestions) {
                eachQuestion.questionType = eachQuestion?.questionType?.name;
            }
        }
        this.props.addQuestionnaireResponse(values);
        actions.setSubmitting(false);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {previousPage} = this.props;
        return (
            <Fragment>
                <Formik
                    initialValues={{
                        "generalQuestions": this.state.generalQuestionOptions,
                        "additionalComments": ""
                    }}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit.bind(this)}
                >
                    {({isSubmitting, values, handleChange, handleSubmit, touched, errors, handleBlur}) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Modal.Body>
                                <Card>
                                    <Card.Header>Foreign Contact Questionnaire (Select if answer is yes)</Card.Header>
                                    <Card.Body>
                                        {values.generalQuestions && values.generalQuestions.map((question, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            name={`generalQuestions.${index}.response`}
                                                            label={question?.questionType?.displayName}
                                                            onChange={handleChange}
                                                            isInvalid={touched.generalQuestions && errors.generalQuestions && touched.generalQuestions[index].response && !!errors.generalQuestions[index].response}
                                                            feedback={errors.generalQuestions && errors.generalQuestions[index].response}
                                                            id={`generalQuestions.${index}.response`}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId={`generalQuestions.${index}.comments`}>
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name={`generalQuestions.${index}.comments`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Comments"
                                                            isInvalid={touched.generalQuestions && errors.generalQuestions && touched.generalQuestions[index].comments && !!errors.generalQuestions[index].comments}
                                                            value={values.generalQuestions[index].comments}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.generalQuestions && errors.generalQuestions[index].comments}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Fragment>
                                            )
                                        })}
                                        <Form.Group controlId="additionalComments">
                                            <Form.Label>Additional Comments</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="additionalComments"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Additional Comments"
                                                isInvalid={touched.additionalComments && !!errors.additionalComments}
                                                value={values.additionalComments}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.additionalComments}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Card.Body>
                                    <Card.Footer>
                                    <div className={"pull-right1"}>
                                    <ButtonToolbar>
                                    <Button variant="primary" onClick={previousPage}>Previous</Button>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                                        {/* <Button variant="danger" onClick={this.props.onCancel}>Cancel</Button> */}
                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Foreign Travel"
                                                         handleDeleteCallback={this.handleCancel}/>
                                    </ButtonToolbar>
                                </div>
                                    </Card.Footer>
                                </Card>
                            </Modal.Body>
                            
                        </form>
                    )}
                </Formik>
            </Fragment>
        );
    }
}

export const ReportForeignContactQuestionnaire = connect(null, {})(ForeignContactQuestionnaireComponent);

ReportForeignContactQuestionnaire.propTypes = {
    addQuestionnaireResponse: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired
}