import React, {Component, Fragment} from "react";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";

export class ReportableForeignBusinessInvolvement extends Component {
    render() {
        const {businessInvolvement} = this.props;

        return (
            <Fragment>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control plaintext readOnly value={businessInvolvement.name}/>
                </Form.Group>
                <Form.Group controlId="natureOfInvolvment">
                    <Form.Label>Nature of Involvement</Form.Label>
                    <Form.Control plaintext readOnly value={businessInvolvement.natureOfInvolvment}/>
                </Form.Group>
                <Form.Group controlId="countriesInvolved">
                    <Form.Label>Countries Involved</Form.Label>
                    <Form.Control plaintext readOnly value={businessInvolvement.countriesInvolved}/>
                </Form.Group>
            </Fragment>
        )
    }
}

ReportableForeignBusinessInvolvement.propTypes = {
    businessInvolvement: PropTypes.object.isRequired
};