import React, {Component, Fragment} from "react";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import Button from "react-bootstrap/Button";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import AddLodgingModal from "./add_lodging";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {connect} from "react-redux";
import AddForeignContactModal from "./add_foreign_contact";

class ForeignContactList extends Component {

    constructor(props) {
        super(props);
    }

    onContactAdd = (contact) => {
        this.props.contactAdded(contact)
        this.setState({selected: []});
    }

    removeContact = (contact) => {
        this.props.contactRemoved(contact)
        this.setState({selected: []});
    }

    render() {
        const {addedContacts, updateParentStateWhenSubmitting, viewOnly} = this.props;

        const columns = [{
            dataField: 'firstName',
            text: 'First Name',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'lastName',
            text: 'Last Name',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'citizenCountry',
            text: 'Citizenship Country',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'placeOfContact',
            text: 'Place of Contact',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'contactReason',
            text: 'Reason for Contact',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <Fragment>
                        {!viewOnly &&
                        <Button variant={'link'} className="close" onClick={() => this.removeContact(row)}>
                            <span aria-hidden="true" title={"Remove Contact From Travel"} style={{padding: 10}} aria-label="Remove Contact From Travel">
                            <i class="fa fa-times-circle"></i>
                            </span>
                        </Button>
                        }
                    </Fragment>
                )
            }
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                <div className={"pull-right"}>
                    {!viewOnly &&
                    <ButtonToolbar>
                        <AddForeignContactModal updateParentStateWhenSubmitting={updateParentStateWhenSubmitting}
                                                onAddContact={this.onContactAdd}/>
                    </ButtonToolbar>
                    }
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="lastName"
                                data={addedContacts}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
            </Fragment>
        )
    }

}

ForeignContactList.propTypes = {
    addedContacts: PropTypes.array.isRequired,
    contactAdded: PropTypes.func.isRequired,
    contactRemoved: PropTypes.func.isRequired,
    updateParentStateWhenSubmitting: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool
}

export default connect(null, {
    showErrorGrowl,
    showSuccessGrowl
})(ForeignContactList);