import * as PropTypes from "prop-types";
import React, {Component, Fragment} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {DateUtils} from "../../../../actions/common_utils";

export class PersonalInfoArrest extends Component {
    render(){
        const {arrest} = this.props;

        return (
            <Fragment>
                <Form.Row>
                    <Form.Group as={Col} md={6} controlId="incidentDate">
                        <Form.Label>Incident Date</Form.Label>
                        <Form.Control plaintext readOnly value={arrest.incidentDate ? DateUtils.getDateString(arrest.incidentDate) : "Unknown"}/>
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="incidentLocation">
                        <Form.Label>Incident Location</Form.Label>
                        <Form.Control plaintext readOnly value={arrest.incidentLocation}/>
                    </Form.Group>
                </Form.Row>
                <Form.Group controlId="charges">
                    <Form.Label>Charges</Form.Label>
                    <Form.Control plaintext readOnly value={arrest.charges}/>
                </Form.Group>
                <Form.Group controlId="disposition">
                    <Form.Label>Disposition</Form.Label>
                    <Form.Control plaintext readOnly value={arrest.disposition}/>
                </Form.Group>
            </Fragment>
        )
    }
}

PersonalInfoArrest.propTypes = {
    arrest: PropTypes.object.isRequired
};