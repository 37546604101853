/**
 * Created by pshivaraman on 3/23/18.
 */

import React from "react";
import {Link} from "react-router-dom";
import {setOrg} from "../../actions/org_actions";
import {connect} from "react-redux";
import {fetchOrgFacilities} from "../../actions/facility_actions";
import ProgressBar from 'react-bootstrap/ProgressBar';

const OrgItem = ({org, fetchOrgFacilities}) => {
    // const now = 60;
    if (!org) {
        return <div/>
    }
    const facCount = org.facCount ? org.facCount : 0;
    const subCount = org.subjectCount ? org.subjectCount : 0;
    const orgObj = org.org;
    const orgInfo = [];
    if (orgObj.pocName) orgInfo.push(orgObj.pocName);
    if (orgObj.pocPhone) orgInfo.push(orgObj.pocPhone);
 
    const makeLink = (text) => {
        return (
            <Link to={`/org/dash`} onClick={() => {
                setOrg(orgObj.id);
                fetchOrgFacilities(orgObj.id);
            }}>{text}</Link>
        )
    };

    const topFacLevel = org.highestLevel ? org.highestLevel.displayName : "None";
    const name = () => {
        if (org.matchedFacility) {
            return (
                <div>{makeLink(orgObj.name)} (Matched Location:
                    <Link to={`/org/dash/facility/${org.matchedFacility.facility.id}`} onClick={() => {
                        setOrg(orgObj.id);
                        fetchOrgFacilities(orgObj.id);
                    }}>{org.matchedFacility.facility.name}</Link>)
                </div>
            )
        }
        return <div className="inter_black"><a href="#">{makeLink(orgObj.name)}</a></div>;
    };
    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="org_icon">
                    <i className="fa fa-user"></i>
                    </div>

                    <div className="d-grid">
                        <a href="#">{name()}</a>
                        <span>{makeLink(orgInfo.join(", "))}</span>
                    </div>
                </div>
            </td>

            <td>
            <div className="d-grid">
                        <a href="#">{makeLink(topFacLevel)}</a>
                        <span>{makeLink(subCount)}</span>
                    </div>
            </td>
           
            {/* <th>{makeLink(orgObj.pocPhone)}</th> */}
            {/* <td>{makeLink(topFacLevel)}</td> */}
            <td>{makeLink(facCount)}</td>
            {/* <td>{makeLink(subCount)}</td> */}


            {/* <td>
                <label className="text-muted fs-7 fw-semibold">{`${now}%`}</label>
                <ProgressBar className="h-6px" striped variant="success" now={now} />
            </td> */}
        </tr>
    )
};

export default connect(null, {fetchOrgFacilities})(OrgItem);