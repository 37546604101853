import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { FieldRBDatePicker, FieldRBInput } from "../../../../components/redux_form/field_components_rb";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";

class CommonPersonalInfoCohabitantComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) { 
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Card>
                        <Card.Header>Cohabitant Information</Card.Header>
                        <Card.Body>

                            <div className="row col-lg-12 m-0 p-0">
                                <div className="col-lg-6">
                                    <Field name="firstName" component={FieldRBInput} label="First Name" required horizontal/>
                                </div>

                                <div className="col-lg-6">
                                    <Field name="lastName" component={FieldRBInput} label="Last Name" required horizontal/> 
                                </div>

                                <div className="col-lg-6">
                                    <Field name="citizenCountry" component={FieldRBInput} label="Country of Citizenship" required horizontal/>
                                </div>

                                <div className="col-lg-6">
                                    <Field name="birthPlace" component={FieldRBInput} label="Birth Place" required horizontal/>
                                </div>

                                <div className="col-lg-6">
                                    <Field name="birthDate" component={FieldRBDatePicker} label="Birth Date" required horizontal/>
                                </div>

                                <div className="col-lg-6">
                                    <Field name="startDate" component={FieldRBDatePicker} label="Date of First Contact" required horizontal/>
                                </div>
                            </div>
                           
                            
                        </Card.Body>
                        <Card.Footer>
                        <ButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Cohabitant Change Form"
                                                         handleDeleteCallback={this.handleCancel.bind(this)}/>
                    </ButtonToolbar>
                        </Card.Footer>
                       
                    </Card>
                   
                </Form>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.firstName) {
        errors.firstName = "First Name is required!";
    }
    if (!values.lastName) {
        errors.lastName = "Last Name is required!";
    }
    if (!values.citizenCountry) {
        errors.citizenCountry = "Country of Citizenship is required!";
    }
    if (!values.birthPlace) {
        errors.birthPlace = "Place of Birth is required!";
    }
    if (!values.birthDate) {
        errors.birthDate = "Enter date of birth!";
    }
    if (!values.startDate) {
        errors.startDate = "Enter Start Date!";
    }

    return errors;
}
export const CommonPersonalInfoCohabitant = reduxForm({
    validate,
    form: "ReportPersonalInfoCohabitantForm"
})(
    connect(null, { showSuccessGrowl, showErrorGrowl })(CommonPersonalInfoCohabitantComponent)
);
 