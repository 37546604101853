import React, {Component, Fragment} from "react";
import * as PropTypes from "prop-types";
import {Field, Formik, getIn} from "formik";
import * as Yup from "yup";
import {getInvalidationTypesByReportableType} from "../../../actions/fieldValue_actions";
import {PersonalInfoAddress} from "./personalInfo/review_personalInfo_address";
import {PersonalInfoMaritalStatus} from "./personalInfo/review_personalInfo_maritalstatus";
import {ReportableForeignContact} from "./foreignTravel/review_foreign_contacts";
import {PersonalInfoCohabitant} from "./personalInfo/review_personalInfo_cohabitant";
import {PersonalInfoArrest} from "./personalInfo/review_personalInfo_arrest";
import {ReportableForeignCitizenship} from "./foreignTravel/review_foreign_citizenship";
import {ReportableForeignBusinessInvolvement} from "./foreignTravel/review_foreign_business_involvement";
import {ReportableForeignBankAccount} from "./foreignTravel/review_foreign_bank_account";
import {ReportableForeignProperty} from "./foreignTravel/review_foreign_property";
import {ReportableForeignIdCard} from "./foreignTravel/review_foreign_id_card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {CommonUtils, DateUtils} from "../../../actions/common_utils";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {FormikDatePickerComponent} from "../../../components/formik/formik_date_picker";
import {invalidateSubjectReportable} from "../../../actions/subject_actions";
import {connect} from "react-redux";
import {showSuccessGrowl} from "../../../actions/msg_actions";

class SubjectReportableUpdateModalComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            availInvalidateReasons: [{id: "", name: "Select Reason..."}],
            foreignContactListIndex: -1,
            foreignContactObject: null
        }
    }

    open = async () => {
        const {reportable, foreignContactIndex} = this.props;

        let listIndex = -1;
        let foreignContactObj = null;
        if (reportable && reportable.type === "FOREIGN_CONTACTS_QUESTIONNAIRE" && reportable.contacts && foreignContactIndex) {
            listIndex = reportable.contacts.findIndex((contact) => contact.id === foreignContactIndex);
            foreignContactObj = reportable.contacts[listIndex];
        } else if (reportable && reportable.type === "FOREIGN_TRAVEL" && reportable.foreignContacts && foreignContactIndex) {
            listIndex = reportable.foreignContacts.findIndex((contact) => contact.id === foreignContactIndex);
            foreignContactObj = reportable.foreignContacts[listIndex];
        }

        this.setState({
            showModal: true,
            foreignContactListIndex: listIndex,
            foreignContactObject: foreignContactObj
        });

        // Get Invalidation reasons
        const reportableType = foreignContactObj ?
            "FOREIGN_CONTACT" :
            reportable.type;
        const invalidationTypes = await getInvalidationTypesByReportableType(reportableType);

        const reasons = [{id: "", name: "Select Reason..."}];

        invalidationTypes.forEach((type) => {
            reasons.push({
                id: type.id,
                name: type.displayName
            });
        });

        this.setState({
            availInvalidateReasons: reasons
        });
    }

    close = () => {
        this.setState({
            showModal: false
        });
    }

    onSubmit = async (values) => {
        const {onUpdate, reportable, reportableTypeString = "Reportable Activity"} = this.props;
        const {foreignContactListIndex} = this.state;

        values.id = reportable.id;
        values.internalListId = foreignContactListIndex;
        await invalidateSubjectReportable(values);
        this.close();
        this.props.showSuccessGrowl(`${reportableTypeString} Invalidated Successfully!`, `The ${reportableTypeString} was updated successfully!`);
        onUpdate();
    }

    getReportableContent() {
        const {reportable} = this.props;
        const {foreignContactObject} = this.state;

        switch (reportable.type) {
            case "PERSONNEL_INFO_ADDRESS":
                return <PersonalInfoAddress address={reportable}/>;
            case "PERSONNEL_INFO_MARITAL_STATUS":
                return <PersonalInfoMaritalStatus maritalStatus={reportable}/>;
            case "FOREIGN_CONTACT":
                return <ReportableForeignContact contact={reportable}/>;
            case "FOREIGN_CONTACTS_QUESTIONNAIRE":
            case "FOREIGN_TRAVEL":
                if (foreignContactObject) {
                    return <ReportableForeignContact contact={foreignContactObject}/>;
                }
                return "";
            case "COHABITANT":
                return <PersonalInfoCohabitant cohabitant={reportable}/>;
            case "ARRESTS":
                return <PersonalInfoArrest arrest={reportable}/>;
            case "FOREIGN_CITIZENSHIP":
                return <ReportableForeignCitizenship citizenship={reportable}/>;
            case "FOREIGN_BUSINESS_INVOLVEMENT":
                return <ReportableForeignBusinessInvolvement businessInvolvement={reportable}/>;
            case "FOREIGN_BANK_ACCOUNT":
                return <ReportableForeignBankAccount bankAcct={reportable}/>;
            case "FOREIGN_PROPERTY_OWNERSHIP":
                return <ReportableForeignProperty property={reportable}/>;
            case "FOREIGN_PASSPORT_ID_POSSESSION":
                return <ReportableForeignIdCard idCard={reportable}/>;
        }
        return "";
    }

    render() {
        const {reportable} = this.props;
        const {showModal, availInvalidateReasons, foreignContactObject} = this.state;

        const startDate = foreignContactObject ? foreignContactObject.startDate : reportable.startDate;

        return (
            <Fragment>
                <Button onClick={this.open} variant={"link"} className={"close"}>
                     <span aria-hidden="true" title={"Update"}
                           aria-label="Remove User">
                        <i className="fa fa-square-pen"/>
                     </span>
                </Button>

                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Information</Modal.Title>
                    </Modal.Header>

                    <Formik
                        initialValues={{
                            dateInvalidated: "",
                            reasonInvalidated: ""
                        }}
                        validationSchema={Yup.object().shape({
                            dateInvalidated: Yup.date().required("Invalidation Date is Required"),
                            reasonInvalidated: Yup.string().required("Invalidation Reason is required")
                        })}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                    >
                        {({
                              isSubmitting,
                              values,
                              handleChange,
                              handleSubmit,
                              touched,
                              errors,
                              handleBlur
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Container>
                                        {this.getReportableContent()}
                                        <Form.Row>
                                            <Form.Group as={Col} md={6} controlId="startDate">
                                                <Form.Label>Start Date</Form.Label>
                                                <Form.Control plaintext readOnly
                                                              value={startDate ? DateUtils.getDateString(startDate) : "N/A"}/>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>End Date</Form.Label>
                                                <Field
                                                    id="dateInvalidated"
                                                    name="dateInvalidated"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Enter End Date"}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Group>
                                            <Form.Label>Reason for Change</Form.Label>
                                            <Form.Control required as="select" name={'reasonInvalidated'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={getIn(touched, "reasonInvalidated") && !!getIn(errors, "reasonInvalidated")}
                                                          value={getIn(values, "reasonInvalidated")}
                                            >
                                                {CommonUtils.getOptionsForSelect(availInvalidateReasons)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {getIn(errors, "reasonInvalidated")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>Update</Button>
                                        <Button variant="danger" onClick={this.close}
                                                disabled={isSubmitting}>Cancel</Button>
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        );
    }
}

export const SubjectReportableUpdateModal = connect(null, {showSuccessGrowl})(SubjectReportableUpdateModalComponent);

SubjectReportableUpdateModal.propTypes = {
    foreignContactIndex: PropTypes.string,
    reportable: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    reportableTypeString: PropTypes.string
};