import React, {Component, Fragment} from "react"; 
import {connect} from "react-redux";
import {reportCohabitantChange, reportSubjectChange} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import { CommonPersonalInfoCohabitant } from "../commonInfo/common_personalInfo_cohabitant";

class ReportPersonalInfoCohabitantComponent extends Component {
    constructor(props) {
        super(props);

        this.state={
            isLoading: false,
        }
    }

    onSubmit(values) {
        this.setState({isLoading: true});
        this.props.reportCohabitantChange(values).then(() => {
            this.props.showSuccessGrowl("Cohabitant Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }).catch((error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
            this.setState({isLoading: false});
        });
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <div className="reporting_bg">
                <div className="container">
                    <div className="heading_container">
                        <h3>Report Cohabitant Change Form</h3>
                    </div>
                    <CommonPersonalInfoCohabitant onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>
        )
    }
}

 
export const ReportPersonalInfoCohabitant = connect(null, {reportCohabitantChange, showSuccessGrowl, showErrorGrowl})(ReportPersonalInfoCohabitantComponent);