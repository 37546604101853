import React, { Component, Fragment } from "react";
import { connect } from "react-redux";  
import ButtonToolbar from "react-bootstrap/ButtonToolbar"; 
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";
import { Button, Card, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup"; 

class CommonFinancialIssueAnomalyComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) { 
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() { 
        return (
            <Formik
            initialValues={{
                "typeOfIssue": "",
                "valueUsd": "",
                "mitigatingCondition": "",
            }}
            enableReinitialize={true}
            onSubmit={this.onSubmit.bind(this)}
            validationSchema={
                Yup.object().shape({
                    typeOfIssue: Yup.string().required('Enter a type'),
                    valueUsd: Yup.number().required('Enter a value in USD').typeError('Please enter a number indicating value in USD')
                })
            }
        >
            {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    {/*<h3>Report Foreign Contacts Form</h3>*/}
                    <Card>
                        <Card.Header>
                              Financial Issue & Anomaly
                               </Card.Header>
                        <Card.Body>
                        <Form.Row>
                                <Form.Group as={Col} md="6" controlId={'typeOfIssue'}>
                                <Form.Label>Issue Type</Form.Label>
                                    <Form.Control required type="text" name={'typeOfIssue'}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur} placeholder={'Bankruptcy, Inheritance, etc'}
                                                  isInvalid={touched.typeOfIssue && !!errors.typeOfIssue}
                                                  value={values.typeOfIssue}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.typeOfIssue}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId={'valueUsd'}>
                                <Form.Label>Property Value (USD)</Form.Label>
                                    <Form.Control required type="text" name={'valueUsd'}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur} placeholder={'Enter a value in US dollars'}
                                                  isInvalid={touched.valueUsd && !!errors.valueUsd}
                                                  value={values.valueUsd}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.valueUsd}
                                    </Form.Control.Feedback>
                                    </Form.Group>

                                <Form.Group as={Col} md="6" controlId={'mitigatingCondition'}>
                                <Form.Label>Mitigating Circumstances</Form.Label>
                                    <Form.Control required type="text" name={'mitigatingCondition'}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur} placeholder={'Enter any mitigating circumstances related to the report'}
                                                  isInvalid={touched.mitigatingCondition && !!errors.mitigatingCondition}
                                                  value={values.mitigatingCondition}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.mitigatingCondition}
                                    </Form.Control.Feedback>
                                    </Form.Group>
                            </Form.Row>
                        </Card.Body>
                        <Card.Footer>
                        <ButtonToolbar>
                                <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Financial Issue & Anomaly"
                                                     handleDeleteCallback={this.handleCancel.bind(this)}/>
                            </ButtonToolbar>
                        </Card.Footer>
                    </Card>
                </Form>
            )}
        </Formik>
        )
    }
}
 
export const CommonFinancialIssueAnomaly = connect(null, { showSuccessGrowl, showErrorGrowl })(CommonFinancialIssueAnomalyComponent)
 