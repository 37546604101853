import React, {Component, Fragment} from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import {FieldRBCheckbox, FieldRBDatePicker, FieldRBInput} from "../../../../components/redux_form/field_components_rb";
import {Field, reduxForm} from "redux-form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {reportMediaContact} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { CommonPersonalInfoMediaContact } from "../commonInfo/common_personalInfo_mediacontact";

class ReportMediaContactComponent extends Component {
    constructor(props){
        super(props);

        this.state={
            isLoading: false,
        }
    }

    onSubmit(values){
        this.setState({isLoading: true});
        this.props.reportMediaContact(values).then(() => {
            this.props.showSuccessGrowl("Media Contact Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }).catch((error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.");
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        });
    }

    handleCancel = () => {
        window.history.back();
    }

    render(){
        const {handleSubmit} = this.props;

        return (

            <div className="reporting_bg">
                <div className="container">
                <div className="heading_container">
                    <h3>Report Media Contact Form</h3>
                    </div>
                <Fragment>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
                 <CommonPersonalInfoMediaContact onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
            </Fragment>
                </div>
            </div>
         
        )
    }
}

 

export const ReportMediaContact =  connect(null, {reportMediaContact, showSuccessGrowl, showErrorGrowl})(ReportMediaContactComponent)
 