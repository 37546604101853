import React, {Fragment} from "react";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import MaskedInput from "react-input-mask";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import {CommonUtils, DateUtils} from "../../../../actions/common_utils";
import {fetchOrgsToPick, reportVisit, modifyVisit} from "../../../../actions/visit_actions";
import {getSubjectUsingEmailForVisit} from "../../../../actions/subject_actions";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {downloadDocument} from "../../../../actions/doc_actions";
import {Card} from "react-bootstrap";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import SubjectForeignTravelDetailsModal from "../../../../components/subject/subject_foreign_travel_details_modal";
import BootstrapTable from "react-bootstrap-table-next";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";

class VisitNewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            availSecurityClearanceLevel: (['NONE', 'CONFIDENTIAL', 'SECRET', 'TOP_SECRET']).sort(),
            showModal: false,
            orgsToShow: [],
            otherSubjects: [],
            isLoading: false
        }
    }

    componentDidMount() {
        this.fetchOrgs();
    }

    getSubjectUsingEmail = async (email) => {
        try {
            let {otherSubjects} = this.state;
            // check for duplicates
            for (let eachSubject of otherSubjects){
                if (eachSubject.emailAddressOfCurrentOrg === email){
                    this.props.showErrorGrowl("Subject has already been added", "Subject with email " + email + " is already added");
                    return;
                }
            }
            let response = await getSubjectUsingEmailForVisit(email);
            let subject = response.data;
            if (subject){
                otherSubjects.push(subject);
                this.setState({
                    otherSubjects
                })
            }
        } catch (error) {
            console.log('Error searching subject', error)
            this.props.showErrorGrowl("Unable to search Subject", error.response.data);
        }
    }

    fetchOrgs = async () => {
        try {
            let response = await fetchOrgsToPick();
            console.log('Orgs are ', response);
            this.setState({
                orgsToShow: response
            })
        } catch (e) {
            this.props.showErrorGrowl("Fetching Organization Error", "Unable to fetch organizations the subject belongs to");
        }
    }

    open() {
        this.setState({showModal: true});
    }

    close() {
        this.setState({
            showModal: false
        });
    }

    handleDelete = (subjectId) => {
        let {otherSubjects} = this.state;
        let newSubs = [];
        for (let eachSubject of otherSubjects){
            if (eachSubject.id !== subjectId){
                newSubs.push(eachSubject);
            }
        }
        this.setState({
            otherSubjects: newSubs
        })
    }

    getOtherSubjectsTable = () => {
        let {otherSubjects} = this.state;
        const columns = [{
            dataField: 'personFirstName',
            text: 'First Name',
            formatter: (cell, row) => cell,
            sort: true,
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => cell,
            sort: true,
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <div className="custom_btn">
                        <ButtonToolbar>
                            {/* <DeleteConfirmationModal deleteEntity="Subject" handleDeleteCallback={this.handleDelete} entityId={row.id} buttonText={'Cancel'} />  */}
                            <DeleteConfirmationModal deleteEntity="Subject"  actionText={"remove"} entityId={row.id} handleDeleteCallback={this.handleDelete}
                                                 buttonText={'Remove'} jsxButtonType={<span aria-hidden="true"><i class="fa fa-trash"></i></span>}
                        />
                        </ButtonToolbar>
                    </div>
                )
            }
        }];

        return (
            <Card>
                <Card.Header>Visiting Subjects</Card.Header>
                <Card.Body>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={otherSubjects}
                                columns={columns} filter={filterFactory()}/>
                </Card.Body>
            </Card>
        );

    }

    async onSubmit(values) {
        this.setState({isLoading: true});
        values.subjectEmailList = []
        if (!values.visitForSelf) {
            for (let eachSubject of this.state.otherSubjects){
                values.subjectEmailList.push(eachSubject.emailAddressOfCurrentOrg);
            }
        }

        const {modifySource} = this.props;
        if (modifySource) {
            await this.props.modifyVisit(values, () => {

                // Success Response
                this.props.showSuccessGrowl("Visit Reported", "Visit was reported successfully and is under review");
                this.setState({
                    showModal: false
                });
                this.props.history.push("/reporting/subject/visits");
                this.setState({isLoading: false});
            }, (error) => {
                console.log('Error is ', error);
                if (error.response.status === 404) {
                    this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                    this.props.history.push("/reporting/subject/emailCheck");
                } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')) {
                    this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                    sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                    this.props.history.push("/reporting/subject/emailCheck");
                }
                this.setState({isLoading: false});
            });
        } else {
            await this.props.reportVisit(values, () => {
                // Success Response
                this.props.showSuccessGrowl("Visit Reported", "Visit was reported successfully and is under review");
                this.setState({
                    showModal: false
                });
                this.props.history.push("/reporting/subject/visits");
                this.setState({isLoading: false});
            }, (error) => {
                console.log('Error is ', error);
                if (error.response.status === 404) {
                    this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                    this.props.history.push("/reporting/subject/emailCheck");
                } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')) {
                    this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                    sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                    this.props.history.push("/reporting/subject/emailCheck");
                }
                this.setState({isLoading: false});
            });
        }
    }

    initializeEmailAddressIfModify = (visit) => {
        let {otherSubjects} = this.state;
        if (visit.subjects && otherSubjects.length === 0){
            for (let eachSubject of visit.subjects){
                otherSubjects.push(eachSubject);
            }
        }
    }

    render() {
        const {availSecurityClearanceLevel, showModal, orgsToShow, otherSubjects} = this.state;
        const {cloneSource, modifySource} = this.props;

        let visitIssueTempObj = {
            visitingSecurityOfficeName: "",
            visitingSecurityOfficeAddress: "",
            technicalPOCName: "",
            technicalPOCNumber: '',
            securityPOCName: '',
            securityPOCNumber: '',
            smoCode: '',
            classificationLevel: 'NONE',
            purpose: "",
            orgId: '',
            visitStartDate: '',
            visitForSelf: true,
            visitEndDate: '',
            subjectEmail: '',
        };
        if (orgsToShow && orgsToShow.length > 0) {
            visitIssueTempObj.orgId = orgsToShow[0].id;
        }
        if (cloneSource) {
            Object.assign(visitIssueTempObj, cloneSource);
            this.fixEmailAddressesIfNeeded(visitIssueTempObj);
            visitIssueTempObj.visitStartDate = '';
            visitIssueTempObj.visitEndDate = '';
            visitIssueTempObj.visitForSelf = false;
        }
        if (modifySource) {
            Object.assign(visitIssueTempObj, modifySource);
            visitIssueTempObj.changeComments = '';
            visitIssueTempObj.visitForSelf = false;
            if (!visitIssueTempObj.visitEndDate){
                visitIssueTempObj.visitEndDate = '';
            }
            this.fixEmailAddressesIfNeeded(visitIssueTempObj);
            this.initializeEmailAddressIfModify(modifySource);
        }
        return (
            <React.Fragment>
                {!cloneSource && !modifySource &&
                <Button onClick={this.open} variant="primary">Create Visit</Button>}
                {cloneSource &&
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" title={"Clone Visit"} style={{padding: 10}} aria-label="Clone Visit">
                            <i className="fa fa-clone"/>
                        </span>
                </Button>}
                {modifySource &&
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" title={"Modify Visit"} style={{padding: 10}} aria-label="Modify Visit">
                            <i className="fa fa-square-pen"/>
                        </span>
                </Button>}

                <Modal size="lg" show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modifySource ? 'Modify Visit' : 'Create Visit'}</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={visitIssueTempObj}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                subjectEmail: Yup.string().test(
                                    'subject-email-test',
                                    'Please add at least one subject for visit',
                                    function (value) {
                                        if (this.parent.visitForSelf){
                                            return true;
                                        }
                                        return (otherSubjects && otherSubjects.length > 0);
                                    }
                                ),
                                visitingSecurityOfficeName: Yup.string().required('Enter visiting office name'),
                                visitingSecurityOfficeAddress: Yup.string().required('Address is required'),
                                technicalPOCName: Yup.string().required("Technical Point of Contact Name is required"),
                                technicalPOCNumber: Yup.string().required('Technical Point of Contact phone number is required'),
                                securityPOCName: Yup.string().required('Security Point of Contact Name is required a contact location'),
                                securityPOCNumber: Yup.string().required('Security Point of Contact phone number is required'),
                                classificationLevel: Yup.string().required('Select a classification level'),
                                orgId: Yup.string().required('Pick an organization you want to report visit under'),
                                smoCode: Yup.string().required('Enter a smo code'),
                                purpose: Yup.string().required('Enter a visit reason'),
                                visitStartDate: Yup.date().required('Enter date of visit'),
                                visitEndDate: Yup.date()
                                    .when('visitStartDate', (visitStartDate) => {
                                        if (visitStartDate) {
                                            return Yup.date()
                                                .min(visitStartDate, 'End Date must be after Start Date')
                                        }
                                    }),
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        {!cloneSource && !modifySource &&
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Who is the Visit for</Form.Label>
                                            <Form.Check
                                                name="visitForSelf"
                                                label="Visit is for self"
                                                checked={values.visitForSelf}
                                                onChange={handleChange}
                                                isInvalid={touched.visitForSelf && !!errors.visitForSelf}
                                                feedback={errors.visitForSelf}
                                                id="visitForSelf"
                                            />
                                        </Form.Group>
                                        }
                                        {!values.visitForSelf &&
                                            <Fragment>
                                                <Form.Group as={Col} md="6" controlId="subjectEmail">
                                                    <Form.Label>Visiting Subjects' email addresses</Form.Label>

                                                    <div className="visiting_txt_field">
                                                    <Form.Control
                                                        type="text"
                                                        name="subjectEmail"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="Enter subject email address and hit add"
                                                        isInvalid={touched.subjectEmail && !!errors.subjectEmail}
                                                        value={values.subjectEmail}
                                                    />
                                                      <Button controlId="addButton" disabled={!values.subjectEmail || values.subjectEmail.length === 0} onClick={() => {
                                                        this.getSubjectUsingEmail(values.subjectEmail);
                                                        setFieldValue("subjectEmail",'');
                                                    }} variant={"primary"}>Add</Button>
                                                    </div>
                                                

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.subjectEmail}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                {/* <Form.Group as={Col} md="4" >
                                                  
                                                </Form.Group> */}
                                            </Fragment>
                                        }
                                    </Form.Row>
                                    {!values.visitForSelf &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                        {this.getOtherSubjectsTable()}
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="VisitNewName">
                                            <Form.Label>Visiting Security Office Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="visitingSecurityOfficeName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter visiting office name"
                                                isInvalid={touched.visitingSecurityOfficeName && !!errors.visitingSecurityOfficeName}
                                                value={values.visitingSecurityOfficeName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitingSecurityOfficeName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="visitingSecurityOfficeAddress">
                                            <Form.Label>Visiting Security Office Address</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="visitingSecurityOfficeAddress"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter visiting office address"
                                                isInvalid={touched.visitingSecurityOfficeAddress && !!errors.visitingSecurityOfficeAddress}
                                                value={values.visitingSecurityOfficeAddress}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitingSecurityOfficeAddress}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="technicalPOCName">
                                            <Form.Label>Technical POC Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="technicalPOCName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter technical point of contact name"
                                                isInvalid={touched.technicalPOCName && !!errors.technicalPOCName}
                                                value={values.technicalPOCName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.technicalPOCName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="technicalPOCNumber">
                                            <Form.Label>Technical POC Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                as={MaskedInput}
                                                name="technicalPOCNumber"
                                                mask="999-999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.technicalPOCNumber && !!errors.technicalPOCNumber}
                                                value={values.technicalPOCNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.technicalPOCNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="securityPOCName">
                                            <Form.Label>Security POC Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="securityPOCName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter security point of contact name"
                                                isInvalid={touched.securityPOCName && !!errors.securityPOCName}
                                                value={values.securityPOCName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.securityPOCName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="securityPOCNumber">
                                            <Form.Label>Security POC Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                as={MaskedInput}
                                                name="securityPOCNumber"
                                                mask="999-999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.securityPOCNumber && !!errors.securityPOCNumber}
                                                value={values.securityPOCNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.securityPOCNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId={"classificationLevel"}>
                                            <Form.Label>Classification Level</Form.Label>
                                            <Form.Control required as="select" name={'classificationLevel'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.classificationLevel && !!errors.classificationLevel}
                                                          value={values.classificationLevel}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(availSecurityClearanceLevel))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.classificationLevel}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId={"orgId"}>
                                            <Form.Label>Organization</Form.Label>
                                            <Form.Control required as="select" name={'orgId'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.orgId && !!errors.orgId}
                                                          value={values.orgId}
                                            >
                                                {CommonUtils.getOptionsForSelect(orgsToShow)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.orgId}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="smoCode">
                                            <Form.Label>SMO Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="smoCode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter SMO Code"
                                                isInvalid={touched.smoCode && !!errors.smoCode}
                                                value={values.smoCode}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.smoCode}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="purpose">
                                            <Form.Label>Purpose</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="purpose"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Purpose of Visit"
                                                isInvalid={touched.purpose && !!errors.purpose}
                                                value={values.purpose}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.purpose}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Visit Date</Form.Label>
                                            <Field
                                                id="visitStartDate"
                                                name="visitStartDate"
                                                minDate={new Date()}
                                                component={FormikDatePickerComponent}
                                                placeholder={"Enter the date of Visit"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Visit End Date</Form.Label>
                                            <Field
                                                id="visitEndDate"
                                                name="visitEndDate"
                                                minDate={new Date()}
                                                component={FormikDatePickerComponent}
                                                placeholder={"End date if visit is for multiple days"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    {modifySource &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="changeComments">
                                            <Form.Label>Change Reason</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="changeComments"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter a reason for the change. Ex: Adding additional member"
                                                isInvalid={touched.changeComments && !!errors.changeComments}
                                                value={values.changeComments}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.changeComments}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit"
                                                disabled={isSubmitting}>Create</Button>
                                        <Button variant="danger" onClick={this.close}
                                                disabled={isSubmitting}>Cancel</Button>
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                    {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
                </Modal>
            </React.Fragment>
        )
    }

    fixEmailAddressesIfNeeded(visitIssueTempObj) {
        if (visitIssueTempObj.subjectEmailList && Array.isArray(visitIssueTempObj.subjectEmailList)) {
            console.log(visitIssueTempObj.subjectEmailList);
            visitIssueTempObj.subjectEmailList = visitIssueTempObj.subjectEmailList.join(",");
        }
    }
}

export const
    VisitNew = withRouter(connect(null, {reportVisit, modifyVisit, showSuccessGrowl, showErrorGrowl})(VisitNewComponent));

VisitNew.propTypes = {
    cloneSource: PropTypes.object,
    modifySource: PropTypes.object,
}