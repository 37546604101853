import React, {Component, Fragment} from "react";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import Button from "react-bootstrap/Button";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import AddLodgingModal from "./add_lodging";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {connect} from "react-redux";

class LodgingList extends Component {

    constructor(props) {
        super(props);
        this.onLodgingAdd = this.onLodgingAdd.bind(this);
        this.removeLodging = this.removeLodging.bind(this);
    }

    onLodgingAdd(lodge) {
        this.props.lodgeAdded(lodge)
        this.setState({selected: []});
    }

    removeLodging(lodge) {
        this.props.lodgeRemoved(lodge)
        this.setState({selected: []});
    }

    render() {
        const {addedLodges, updateParentStateWhenSubmitting, viewOnly} = this.props;

        const columns = [{
            dataField: 'name',
            text: 'Lodge Name',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'city',
            text: 'City',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'country',
            text: 'Country',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <Fragment>
                        {!viewOnly &&
                        <Button className="close close_icon" onClick={() => this.removeLodging(row)}>
                            <span aria-hidden="true" title={"Remove Lodge From Travel"} style={{padding: 10}} aria-label="Remove Lodge From Travel">
                            <i className="fa fa-times-circle" style={{color: "black"}}></i>
                            </span>
                        </Button>
                        }
                    </Fragment>
                )
            }
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                <div className={"pull-right"}>
                    {!viewOnly &&
                    <ButtonToolbar>
                        <AddLodgingModal updateParentStateWhenSubmitting={updateParentStateWhenSubmitting}
                                         onAddLodge={this.onLodgingAdd}/>
                    </ButtonToolbar>
                    }
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="name"
                                data={addedLodges}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
            </Fragment>
        )
    }

}

LodgingList.propTypes = {
    addedLodges: PropTypes.array.isRequired,
    lodgeAdded: PropTypes.func.isRequired,
    lodgeRemoved: PropTypes.func.isRequired,
    updateParentStateWhenSubmitting: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool
}

export default connect(null, {
    showErrorGrowl,
    showSuccessGrowl
})(LodgingList);