import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {DateUtils} from "../../../../actions/common_utils";
import {getSubjectForSelfReporting} from "../../../../actions/subject_actions";
import {ButtonToolbar} from "react-bootstrap";
import {SubjectReportableUpdateModal} from "../subject_reportable_update_modal";

export class ReportingSubjectForeignBankDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            foreignBankAccts: []
        };
    }

    async componentDidMount() {
        await this.refreshSubject();
    }

    refreshSubject = async () => {
        this.setState({
            foreignBankAccts: []
        });

        const subject = await getSubjectForSelfReporting();

        this.setState({
            foreignBankAccts: subject.foreignBankAccts
        });
    }

    getForeignBanks() {
        return this.state.foreignBankAccts.map((bank) => {
            return <tr key={bank.id}>
                <td>{bank.instName}</td>
                <td>{bank.country}</td>
                <td>{bank.accountNeedOrUsedFor}</td>
                <td>{bank.startDate ? DateUtils.getDateString(bank.startDate) : "Unknown"}</td>
                <td>
                    {bank.dateInvalidated ?
                        `${DateUtils.getDateString(bank.dateInvalidated)} (${bank.reasonInvalidated ? bank.reasonInvalidated.displayName : "Unknown"})`
                        : "N/A"}
                </td>
                <td>
                    <ButtonToolbar className="p-0 mb-0 float-right" style={{flexWrap: "nowrap"}}>
                        {!bank.dateInvalidated &&
                        <SubjectReportableUpdateModal reportable={bank}
                                                      reportableTypeString={"Foreign Bank Account"}
                                                      onUpdate={this.refreshSubject}/>
                        }
                    </ButtonToolbar>
                </td>
            </tr>
        })
    }

    render() {
        const {foreignBankAccts} = this.state;

        if (!foreignBankAccts || foreignBankAccts.length === 0) {
            return <Alert variant="danger">No Foreign Banks</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Bank Name</th>
                        <th>Country</th>
                        <th>Purpose</th>
                        <th>Account Opened Date</th>
                        <th>Account Closed Date</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignBanks()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}