import React from "react";
import {deleteOrg, fetchCurOrg, fetchOrgs, updateOrg} from "../../actions/org_actions";
import {connect} from "react-redux";
import BreadcrumbLink from "../../components/bootstrap_addons/breadcrumb_link";
import {fetchOrgFacilities} from "../../actions/facility_actions";
import DeleteConfirmationModal from "../../components/delete_confirm_modal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CollapsibleCard from "../../components/bootstrap_addons/collapsible-card";
import {ConditionalDisplay} from "../util/shouldDisplay";
import {ButtonToolbar} from "react-bootstrap";

class OrgDetail extends React.Component {
    constructor(props) {
        super(props);
        this.goToEditPage = this.goToEditPage.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.state = {
            sigLoaded: false
        }

    }

    handleDelete() {
        const {orgObj} = this.props;
        const {org} = orgObj;

        this.props.deleteOrg(org.id, ()=>{
            this.props.fetchOrgs();
            this.props.history.push("/");
        });



    }

    componentDidMount() {
    }


    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Settings
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    goToEditPage() {
        this.props.history.push(`/org/dash/settings/${this.props.match.params.id}/update`);
    }

    render() {

        const {orgObj} = this.props;
        const {org} = orgObj;

        if (!orgObj || !org || !org.id) {
            return (
                <div>Loading...</div>
            );
        }
        return (
            <div>

                {/* {this.getBreadCrumb()} */}
                {ConditionalDisplay(
                <div className="float-right">
                    <ButtonToolbar>
                        <Button variant="primary" onClick={() => this.goToEditPage()}><span className="fa fa-square-pen"
                                                                                            aria-hidden="true"/> Edit</Button>

                        <DeleteConfirmationModal deleteEntity="Organization" handleDeleteCallback={this.handleDelete}/>
                    </ButtonToolbar>
                </div>
                )}
                <h3 className="page_title_left">Organization Details</h3>
                <br/>
                <CollapsibleCard header="General" defaultOpen={true}>
                        <Row className={'mb=3'}>

                        </Row>
                        <Row className={'mb-3'}>
                            <Col sm={3} md={2} className="form-label">
                                Name
                            </Col>
                            <Col sm={3} md={4}>
                                {org.name}
                            </Col>
                            <Col sm={3} md={2} className="form-label">
                                Description
                            </Col>
                            <Col sm={3} md={4}>
                                {org.description}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col sm={3} md={2} className="form-label">
                                Address
                            </Col>
                            <Col sm={3} md={4}>
                                {org.street}, {org.city}, {org.state} {org.zipcode}, {org.country}
                            </Col>
                            <Col sm={3} md={2} className="form-label">
                                Signatures used this month:
                            </Col>
                            <Col sm={3} md={2}>
                                {orgObj.signatureUsageForTheCurrentMonth}
                            </Col>
                        </Row>
                    <Row className={'mb-3'}>
                        <Col sm={6} md={2} className="form-label">
                            Allow Broadcast email from System Administrators:
                        </Col>
                        <Col sm={3} md={2}>
                            {org.allowBroadcastEmail?'Yes':'No'}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={6} md={2} className="form-label">
                            Disable Email communication to anyone in the Org:
                        </Col>
                        <Col sm={3} md={2}>
                            {org.doNotAllowEmailNotificationWithinOrg?'Yes':'No'}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <br/>
                <CollapsibleCard header="Point of Contact Information" defaultOpen={true}>
                        <Row className={'mb-3'}>
                            <Col sm={3} md={2} className="form-label">
                                Name
                            </Col>
                            <Col sm={3} md={4}>
                                {org.pocName}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col sm={3} md={2} className="form-label">
                                Email Address
                            </Col>
                            <Col sm={3} md={4}>
                                {/*<a href={`mailto:${org.pocEmail}`} target="_top">*/}
                                    {org.pocEmail}
                                {/*</a>*/}
                            </Col>
                            <Col sm={3} md={2} className="form-label">
                                Phone Number
                            </Col>
                            <Col sm={3} md={4}>
                                {org.pocPhone}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3} md={2} className="form-label">
                                Email Validation Status
                            </Col>
                            <Col sm={3} md={4}>
                                {org.pocEmailNotValidated === true? 'Not Validated':'Validated'}
                            </Col>
                        </Row>
                </CollapsibleCard>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        orgObj: state.cur_org
    }
}

export default connect(mapStateToProps, {updateOrg, fetchOrgFacilities, fetchOrgs, deleteOrg})(OrgDetail);