import React, {Component, Fragment} from "react";
import {Form, Card, Col, Button} from "react-bootstrap";
import * as Yup from "yup";
import {Formik, Field} from "formik";
import {Link} from "react-router-dom";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {connect} from "react-redux";
import {reportForeignIntelligenceContact} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { CommonForeignIntelligenceContact } from "../commonInfo/common_foreign_intelligence_contect";

class ReportForeignIntelligenceContactComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state={
            isLoading: false,
        }
    }

    onSubmit = (values) => {
        this.setState({isLoading: true});
        console.log('Submitting ', values);
        this.props.reportForeignIntelligenceContact(values, () => {
            this.props.showSuccessGrowl("Foreign Intelligence Contact Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        })
    }

    handleCancel = () => {
        window.history.back();
    }


    render() {
        const {handleSubmit} = this.props;

        return (
            <div className="reporting_bg">
                <div className="container">
                    <div className="heading_container">
                        <h3>Report Foreign Intelligence Contact Form</h3>
                    </div>
                 <CommonForeignIntelligenceContact onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>
        )
    }
}

export const ReportForeignIntelligenceContact = connect(null, {reportForeignIntelligenceContact, showSuccessGrowl, showErrorGrowl})(ReportForeignIntelligenceContactComponent);