import React, {Component, Fragment} from "react";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";

export class ReportableForeignCitizenship extends Component {
    render() {
        const {citizenship} = this.props;

        return (
            <Fragment>
                <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control plaintext readOnly value={citizenship.country}/>
                </Form.Group>
                <Form.Group controlId="basis">
                    <Form.Label>Basis of Citizenship</Form.Label>
                    <Form.Control plaintext readOnly value={citizenship.basis}/>
                </Form.Group>
            </Fragment>
        )
    }
}

ReportableForeignCitizenship.propTypes = {
    citizenship: PropTypes.object.isRequired
};