import React, {Component} from "react";
// import {Field, reduxForm} from "redux-form";
// import {
//     FieldRBDatePicker,
//     FieldRBInput,
//     FieldRBSelectWithKeys
// } from "../../../components/redux_form/field_components_rb";
import {connect} from "react-redux";
import {reportFinancialIssueAndAnomaly} from "../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../actions/msg_actions";
// import Row from "react-bootstrap/Row";
import {VERIFIED_EMAIL} from "../../../actions/auth_actions"; 
import { CommonFinancialIssueAnomaly } from "./commonInfo/common_finanacial_issue_anomaly";

class ReportFinancialIssueAnomalyComp extends Component {
    constructor(props) {
        super(props);

        this.state={
            isLoading: false,
        }
    }

    onSubmit = (values) => {
        this.setState({isLoading: true});
        this.props.reportFinancialIssueAndAnomaly(values, () => {
            this.props.showSuccessGrowl("Financial Issue & Anomaly Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        })
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <div className="reporting_bg">
                <div className="container">
                    <div className="heading_container">
                        <h3>Report Financial Issue & Anomaly</h3>
                    </div>
                    <CommonFinancialIssueAnomaly onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>
        );
    }
}

export const ReportFinancialIssueAnomaly = connect(null, {reportFinancialIssueAndAnomaly, showSuccessGrowl, showErrorGrowl})(ReportFinancialIssueAnomalyComp);