import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { FieldRBCheckbox, FieldRBDatePicker, FieldRBInput } from "../../../../components/redux_form/field_components_rb";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";

class CommonPersonalInfoMediaContactComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Card>
                        <Card.Header>Media Contact Reporting</Card.Header>
                        <Card.Body>
                            <div className="row col-lg-12 m-0 p-0">
                                <div className="col-lg-6">
                                    <Field name="contactDate" component={FieldRBDatePicker} label="Contact Date" required horizontal />
                                </div>

                                <div className="col-lg-6">
                                    <Field name="mediaOutletName" component={FieldRBInput} label="Media Outlet Name" required horizontal />
                                </div>

                                <div className="col-lg-6">
                                    <Field name="firstName" component={FieldRBInput} label="Contact First Name" required horizontal />
                                </div>

                                <div className="col-lg-6">
                                    <Field name="lastName" component={FieldRBInput} label="Contact Last Name" required horizontal />
                                </div>

                                <div className="col-lg-6">
                                    <Field name="contactReason" component={FieldRBInput} label="Reason for Contact" required horizontal />
                                </div>

                            </div>

                            <div className="col-lg-12">

                                <Field name="classifiedInformation" component={FieldRBCheckbox} label="Classified or Prohibited Information Involved" />

                            </div>

                        </Card.Body>
                        <Card.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit">Submit</Button>
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Media Contact Form"
                                    handleDeleteCallback={this.handleCancel.bind(this)} />
                            </ButtonToolbar>
                        </Card.Footer>

                    </Card>

                </Form>
            </Fragment>
        )
    }
}
function validate(values){
    const errors = {};

    if (!values.contactDate){
        errors.contactDate = "Contact Date is required!";
    }
    if (!values.mediaOutletName){
        errors.mediaOutletName = "Media Outlet Name is required!";
    }
    if (!values.firstName){
        errors.firstName = "First Name is required!";
    }
    if (!values.lastName){
        errors.lastName = "Last Name is required!";
    }
    if (!values.contactReason){
        errors.contactReason = "Contact Reason is required!";
    }

    return errors;
}
export const CommonPersonalInfoMediaContact = reduxForm({
    validate,
    form: "ReportPersonalInfoCMediaContactForm"
})(
    connect(null, { showSuccessGrowl, showErrorGrowl })(CommonPersonalInfoMediaContactComponent)
);
