import React, {Component, Fragment} from "react";
import {Button, Modal, Form, Col, Alert, Card, InputGroup, CardDeck, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {Field, FieldArray, Formik, getIn} from "formik";
import * as Yup from "yup";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import CollapsibleCard from "../../../../../components/bootstrap_addons/collapsible-card";
import {parse} from 'date-fns';

export class ForeignCountryVisitDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
        this.setState({
            showModal: false
        });
    }

    open = () => {
        this.setState({
            showModal: true
        });
    }

    close = () => {
        this.setState({
            showModal: false
        });
    }

    onSubmit = (values, actions) => {
        // Remove lodges if doNotLodge is checked
        if (values.didNotLodge) {
            values.lodges = [];
        }

        values.completed = true;

        // Run callback to save changes
        this.props.onChange(values);

        this.close();
    }

    render() {
        const {showModal} = this.state;
        const {value, previewData} = this.props;

        console.log('Preview data is ', previewData)
        const initialValues = {
            country: value.country,
            arrivalDate: value.arrivalDate ? value.arrivalDate : "",
            departureDate: value.departureDate ? value.departureDate : "",
            carrierNumber: value.carrierNumber ? value.carrierNumber : "",
            modeOfTransport: value.modeOfTransport ? value.modeOfTransport : "",
            didNotLodge: value.didNotLodge ? value.didNotLodge : false,
            lodges: value.lodges ? value.lodges : [],
            completed: value.completed ? value.completed : false
        }

        return (
            <Fragment>
                <Button onClick={this.open}>Edit Details</Button>

                <Modal show={showModal} onHide={this.close} size={'lg'}>
                    <Modal.Header>
                        <Modal.Title>{value.country} Visit Details</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                country: Yup.string().required(),
                                arrivalDate: Yup.date().required('Please enter arrival date').test(
                                    'arrivalDate-sanity-test',
                                    'Arrival date cannot be after Departure date',
                                    function (value) {
                                        if (this.parent.departureDate && value && value > this.parent.departureDate){
                                            return false;
                                        }
                                        return true;
                                    }
                                ),
                                departureDate: Yup.date().required('Please enter departure date').test(
                                    'departureDate-sanity-test',
                                    'Departure date cannot be before Arrival date',
                                    function (value) {
                                        if (this.parent.arrivalDate && value && value < this.parent.arrivalDate){
                                            return false;
                                        }
                                        return true;
                                    }
                                ),
                                carrierNumber: Yup.string().required('Please enter the carrier number'),
                                modeOfTransport: Yup.string().required('Please enter contact information'),
                                didNotLodge: Yup.boolean(),
                                lodges: Yup.array().of(
                                    Yup.object().shape({
                                        name: Yup.string().required("Please enter an establishment name"),
                                        address: Yup.string().required("Please enter an address"),
                                        city: Yup.string().required("Please enter the city"),
                                        country: Yup.string().required(),
                                        contactInfo: Yup.string().required("Please enter contact information"),
                                    })
                                ).when("didNotLodge", {
                                    is: false,
                                    then: (schema) => schema.min(1, "Please enter at least 1 lodge")
                                })
                            })
                        }
                    >
                        {({
                              isSubmitting,
                              values,
                              handleChange,
                              setFieldValue,
                              setFieldTouched,
                              handleSubmit,
                              touched,
                              errors,
                              handleBlur
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId={'arrivalDate'}>
                                            <Form.Label>Date of Arrival</Form.Label>
                                            <Field
                                                id="arrivalDate"
                                                name="arrivalDate"
                                                minDate={previewData.departureDate?parse(previewData.departureDate):previewData.departureDate}
                                                maxDate={previewData.arrivalDate?parse(previewData.arrivalDate):previewData.arrivalDate}
                                                component={FormikDatePickerComponent}
                                                placeholder={"Arrival Date (MM/DD/YYYY)"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId={'departureDate'}>
                                            <Form.Label>Date of Departure</Form.Label>
                                            <Field
                                                id="departureDate"
                                                name="departureDate"
                                                minDate={previewData.departureDate?parse(previewData.departureDate):previewData.departureDate}
                                                maxDate={previewData.arrivalDate?parse(previewData.arrivalDate):previewData.arrivalDate}
                                                component={FormikDatePickerComponent}
                                                placeholder={"Departure Date (MM/DD/YYYY)"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Carrier Number</Form.Label>
                                            <Form.Control required type="text" name={'carrierNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'Example: Flight/Train/Bus number etc'}
                                                          isInvalid={touched.carrierNumber && !!errors.carrierNumber}
                                                          value={values.carrierNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.carrierNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Mode of Transport</Form.Label>
                                            <Form.Control required type="text" name={'modeOfTransport'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'Example: Plane/Bus/Car/Train etc'}
                                                          isInvalid={touched.modeOfTransport && !!errors.modeOfTransport}
                                                          value={values.modeOfTransport}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.modeOfTransport}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group>
                                        <Form.Check name="didNotLodge" type="checkbox"
                                                    label="Did Not Lodge In This Country"
                                                    onChange={e => {
                                                        if (e.target.checked){
                                                            setFieldValue('lodges', []);
                                                        }
                                                        setFieldValue('didNotLodge', e.target.checked);
                                                    }}
                                                    checked={values.didNotLodge}
                                        />
                                    </Form.Group>

                                    {!values.didNotLodge &&
                                    <Fragment>

                                        <FieldArray name="lodges">
                                            {({insert, remove, push}) => (
                                                <Fragment>
                                                    <h3>
                                                        <span>Lodges</span>
                                                        <div className={'text-right'}>
                                                            <Button className="btn-primary" variant="outline-primary" onClick={() => push({
                                                                name: "",
                                                                address: "",
                                                                city: "",
                                                                country: values.country,
                                                                contactInfo: ""
                                                            })}><i className="fa fa-plus" aria-hidden="true" /> Add Lodge
                                                            </Button>
                                                        </div>
                                                    </h3>


                                                    {errors.lodges && typeof errors.lodges === 'string' &&
                                                    <Alert variant="danger">{errors.lodges}</Alert>
                                                    }
                                                    <Row>
                                                        {values.lodges.length > 0 &&
                                                        values.lodges.map((lodge, index) => (
                                                            <Col lg={12} key={index}>
                                                                <Card className={"mb-3"}>
                                                                    <Card.Body>
                                                                        <div className={"text-right"}>
                                                                            <Button variant="outline-danger"
                                                                                    onClick={() => remove(index)}>
                                                                                <i className="fa fa-trash" aria-hidden="true" />
                                                                            </Button>{' '}
                                                                        </div>
                                                                        <Form.Row>
                                                                            <Form.Group as={Col} md="6" >
                                                                            <Form.Label>Establishment Name</Form.Label>
                                                                            <Form.Control required type="text"
                                                                                          name={`lodges.${index}.name`}
                                                                                          onChange={handleChange}
                                                                                          onBlur={handleBlur}
                                                                                          isInvalid={getIn(touched, `lodges.${index}.name`) &&
                                                                                          !!getIn(errors, `lodges.${index}.name`)}
                                                                                          value={values.lodges[index].name}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {getIn(errors, `lodges.${index}.name`)}
                                                                            </Form.Control.Feedback>
                                                                            </Form.Group>

                                                                            <Form.Group as={Col} md="6">
                                                                                <Form.Label>Establishment
                                                                                    Address</Form.Label>
                                                                                <Form.Control required type="text"
                                                                                              name={`lodges.${index}.address`}
                                                                                              onChange={handleChange}
                                                                                              onBlur={handleBlur}
                                                                                              isInvalid={getIn(touched, `lodges.${index}.address`) &&
                                                                                              !!getIn(errors, `lodges.${index}.address`)}
                                                                                              value={values.lodges[index].address}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {getIn(errors, `lodges.${index}.address`)}
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md="6">
                                                                                <Form.Label>City</Form.Label>
                                                                                <InputGroup>
                                                                                    <Form.Control required type="text"
                                                                                                  name={`lodges.${index}.city`}
                                                                                                  onChange={handleChange}
                                                                                                  onBlur={handleBlur}
                                                                                                  isInvalid={getIn(touched, `lodges.${index}.city`) &&
                                                                                                  !!getIn(errors, `lodges.${index}.city`)}
                                                                                                  value={values.lodges[index].city}
                                                                                    />
                                                                                    <InputGroup.Append>
                                                                                        <InputGroup.Text>, {values.lodges[index].country}</InputGroup.Text>
                                                                                    </InputGroup.Append>
                                                                                </InputGroup>
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {getIn(errors, `lodges.${index}.city`)}
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>

                                                                            <Form.Group as={Col} md="6">
                                                                            <Form.Label>Lodge Contact Information</Form.Label>
                                                                            <Form.Control required type="text"
                                                                                          name={`lodges.${index}.contactInfo`}
                                                                                          onChange={handleChange}
                                                                                          placeholder="Enter email or phone number"
                                                                                          onBlur={handleBlur}
                                                                                          isInvalid={getIn(touched, `lodges.${index}.contactInfo`) &&
                                                                                          !!getIn(errors, `lodges.${index}.contactInfo`)}
                                                                                          value={values.lodges[index].contactInfo}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {getIn(errors, `lodges.${index}.contactInfo`)}
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                            
                                                                        </Form.Row>
                                                                       
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </Fragment>
                                            )}
                                        </FieldArray>
                                    </Fragment>
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.close()}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

ForeignCountryVisitDetails.propTypes = {
    value: PropTypes.object.isRequired,
    previewData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}