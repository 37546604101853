import React, {Component} from "react";
import {Form, Card, Col, Button} from "react-bootstrap";
import * as Yup from "yup";
import {Field, Formik} from "formik";
import {Link} from "react-router-dom";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {connect} from "react-redux";
import {reportForeignVoting} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import {CommonUtils} from "../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { CommonForeignVoting } from "../commonInfo/common_foreign_voting";

class ReportForeignVoting extends Component {
    constructor(props) {
        super(props);
        this.state={
            isLoading: false,
        }
    }

    onSubmit = (values) => { 
        this.setState({isLoading: true});
        this.props.reportForeignVoting(values, () => {
            this.props.showSuccessGrowl("Foreign Voting Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        })
    }

    handleCancel = () => {
        window.history.back();
    }

    render() { 

        return (
            <div className="reporting_bg">
                <div className="container">
                    <div className="heading_container">
                        <h3>Report Foreign Voting Form</h3>
                    </div>
                    <CommonForeignVoting onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>
        )
    }
}

export default connect(null, {reportForeignVoting, showSuccessGrowl, showErrorGrowl})(ReportForeignVoting);