import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {reportingSecondFactor} from "../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../actions/msg_actions";
import {Field, reduxForm} from "redux-form";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import {FieldRBCheckbox, FieldRBDatePicker, FieldRBInput} from "../../../components/redux_form/field_components_rb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {VERIFIED_EMAIL, REPORTING_PASS_CODE} from "../../../actions/auth_actions";

class ReportSecondFactorComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        values.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        console.log(values);
        this.props.reportingSecondFactor(values).then((response) => {
            // email is valid.. if the device is recognized then go to the reporting page
            if (response){
                sessionStorage.setItem(REPORTING_PASS_CODE, values.passCode);
                this.props.history.push("/reporting/subject/taskList/Outstanding");
            } else {
                this.props.showErrorGrowl("Pass code is not valid.")
            }
            console.log(response);
        }).catch((error) => {
            this.props.showErrorGrowl("Unexpected error please contact system administrator.");
            console.log(error);
        });
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <Fragment>


<div className="container-fluid self_reporting">
                    <div className="col-lg-6 margin-auto top-20">
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div className="heading_container">
                    <h3>Self Reporting</h3>
                    </div>
               
                    <Card>
                        <Card.Header>Passcode Verification</Card.Header>
                        <Card.Body>
                            <Form.Label>Please enter the pass code sent to your email</Form.Label>
                            <Field name="passCode" component={FieldRBInput} label="Pass Code" required
                                   horizontal/>
                            <Field name="trustDevice" label="Remember Me (Uses cookies so you wont be asked for the pass code again on this device)" component={FieldRBCheckbox}
                                   type="checkbox" horizontal/>
                        </Card.Body>

                        <Card.Footer>
                        <ButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Link to="/" className="btn btn-danger">Cancel</Link>
                    </ButtonToolbar>
                        </Card.Footer>
                       
                    </Card>
                   
                </Form>
                </div>
                </div>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.passCode) {
        errors.passCode = "Enter pass code";
    }

    return errors;
}

export const ReportSecondFactor = reduxForm({
    validate,
    form: "ReportSecondFactorForm"
})(
    connect(null, {reportingSecondFactor, showSuccessGrowl, showErrorGrowl})(ReportSecondFactorComponent)
);