import React, { Component, Fragment } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { connect } from "react-redux";
import { showErrorGrowl } from '../../actions/msg_actions';
import { CommonUtils, DateUtils } from "../../actions/common_utils";
import { fetchAllUploadTasksForSubject } from "../../actions/task_actions";
import { Link } from "react-router-dom";
import { ISSUE_TO_UPLOAD } from "../../actions/session_constants";
import { Button, ButtonToolbar, Card, Col, Form } from "react-bootstrap";
import { ReportingSubjectMenu } from "./subject/reporting_subject_menu";

class SubjectIssueListComp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buttonCaption: this.props.match.params.type ? "Menu" : "Back"
        }
    }

    getSubjectIssues = async () => {
        try {
            const subjectTasks = await fetchAllUploadTasksForSubject();
            // for no name in the task use the issue name and description
            for (let task of subjectTasks) {
                if (!task.name) {
                    let eachPartArr = task.issueType.id.split("_");
                    let returnStr = '';
                    for (let eachPart of eachPartArr) {
                        returnStr = returnStr + eachPart.substr(0, 1) + eachPart.substr(1).toLowerCase() + " ";
                    }
                    task.name = returnStr;
                    task.description = task.issueType.description;
                }
            }
            console.log('Tasks are ', subjectTasks)
            this.setState({
                subjectTasks
            })
        } catch (error) {
            this.props.showErrorGrowl("Unexpected error occurred", "Error fetching subject issues.");
            console.log(error);
        };
    }

    componentDidMount() {
        this.getSubjectIssues();
    }

    getCellLink = (row, cellData) => {
        if (row.requiredDocs && row.requiredDocs.length > 0) {
            return (<Link to={`/task/email/uploadDocDirectly/${row.identifiableHash}`} onClick={() => {
                sessionStorage.setItem(ISSUE_TO_UPLOAD, row.identifiableHash);
            }}>{cellData}</Link>);
        } else if (row.issueType.id === 'FOREIGN_TRAVEL_POST_QUESTIONNAIRE') {
            return (<Link to={`/reporting/subject/foreignTravel/postTravel/${row.identifiableHash}`} onClick={() => {
                sessionStorage.setItem(ISSUE_TO_UPLOAD, row.identifiableHash);
            }}>{cellData}</Link>);
        } else {
            return cellData;
        }
    }

    getColumns() {
        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        }

        return [{
            dataField: 'name',
            text: 'Name',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: 'description',
            text: 'Description',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: 'dueDate',
            text: 'Due Date',
            formatter: (cell, row) => this.getCellLink(row, (cell ? DateUtils.getDateTimeString(cell) : cell)),
            sort: true,
            headerFormatter: headerFormatter
        }];
    }

    render() {
        let { subjectTasks,buttonCaption } = this.state;

        if (!subjectTasks) {
            return <div>Loading...</div>
        }

        return (
            <div className="reporting_bg">
                <Fragment>
                    <div className="reporting_screen1 container">
                        <div className="heading_container">
                            <h3>Tasks</h3>
                        </div>

                        <Card>
                            {subjectTasks && subjectTasks.length > 0 ?
                                <Fragment>
                                    <Card.Header>Tasks List</Card.Header>
                                    <Card.Body className="custom_table_h">
                                        <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="issueId" data={subjectTasks}
                                            columns={this.getColumns()} />
                                    </Card.Body>
                                </Fragment> :
                                <Fragment> 
                                    <Card.Body className="custom_table_h mt-5">
                                        <label className="alert alert-info w-100 align-center">
                                            You are all caught up. There are no tasks for you to complete.
                                        </label>
                                    </Card.Body>
                                    </Fragment>
                            }
                            <Card.Footer>
                                <div className="back_btn_block mt-3 mb-3">
                                    <Button className="btn btn-back" onClick={() => { this.props.history.push('/reporting/subject') }}>{buttonCaption}</Button>
                                    <Button className="btn btn-back" onClick={() => {
                                        CommonUtils.handleExit();
                                        this.props.history.push('/reporting/subject/emailCheck')
                                    }
                                    }>Exit</Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </div>
                </Fragment>
            </div>
        )
    }
}

export const SubjectIssueList = connect(null, { showErrorGrowl })(SubjectIssueListComp);