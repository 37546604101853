import React, {Component, Fragment} from "react";
import * as Yup from "yup";
import {connect} from "react-redux";
import {reportForeignBusinessInvolvement} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {Card, Button, ButtonToolbar, Form, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import {Formik, Field} from "formik";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";

class ReportForeignBusinessInvolvementComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state={
            isLoading: false,
        }
    }

    onSubmit = (values) => {
        this.setState({isLoading: true});
        this.props.reportForeignBusinessInvolvement(values).then(() => {
            this.props.showSuccessGrowl("Foreign Business Involvement Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }).catch((error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        });
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <Formik
                initialValues={{
                    "name": "",
                    "natureOfInvolvment": "",
                    "countriesInvolved": "",
                    "startDate": ""
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string().required('Name is required!'),
                        natureOfInvolvment: Yup.string().required('Nature of involvement is required!'),
                        countriesInvolved: Yup.string().required('Countries involved is required!'),
                        startDate: Yup.date().required('Enter a start date')
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldTouched, setFieldValue, handleSubmit, touched, errors, handleBlur}) =>(
                  
                  <div className="reporting_bg">
                    <div className="container">
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="heading_container">
                            <h3>Report Foreign Business Involvement Form</h3>
                        </div>
                       
                        <Card>
                            <Card.Header>Foreign Business Involvement Information</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId={'name'}>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control required type="text" name={'name'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter the name'}
                                                      isInvalid={touched.name && !!errors.name}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'natureOfInvolvment'}>
                                        <Form.Label>Nature of Involvement</Form.Label>
                                        <Form.Control required type="text" name={'natureOfInvolvment'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter the nature of involvement'}
                                                      isInvalid={touched.natureOfInvolvment && !!errors.natureOfInvolvment}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.natureOfInvolvment}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'countriesInvolved'}>
                                        <Form.Label>Countries Involved</Form.Label>
                                        <Form.Control required type="text" name={'countriesInvolved'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter the names of the countries involved'}
                                                      isInvalid={touched.countriesInvolved && !!errors.countriesInvolved}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.countriesInvolved}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'startDate'}>
                                        <Form.Label>Incident Date</Form.Label>
                                        <Field
                                            id="startDate"
                                            name="startDate"
                                            component={FormikDatePickerComponent}
                                            placeholder={"Involvement Date (MM/DD/YYYY)"}

                                        />
                                    </Form.Group>
                                </Form.Row>
                               
                              
                            </Card.Body>

                            <Card.Footer>
                            <ButtonToolbar>
                                    <Button variant="primary" type="submit">Submit</Button>
                                    {/* <Link to="/reporting/subject/reportingForeignInformation" className="btn btn-danger">Cancel</Link> */}
                                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Foreign Business Involvement Form"
                                                         handleDeleteCallback={this.handleCancel}/>
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>
                    </Form>
                    </div>
                    {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
                  </div>
                  
                
                )}
            </Formik>
            // <Fragment>
            //     <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            //         <h3>Report Foreign Business Involvement Form</h3>
            //         <Card>
            //             <Card.Header>Foreign Business Involvement Information</Card.Header>
            //             <Card.Body>
            //                 <Field name="name" component={FieldRBInput} label="Name" required horizontal/>
            //                 <Field name="natureOfInvolvment" component={FieldRBInput} label="Nature of Involvement" required horizontal/>
            //                 <Field name="countriesInvolved" component={FieldRBInput} label="Countries Involved" required horizontal/>
            //                 <Field name="startDate" component={FieldRBDatePicker} label="Start Date" required horizontal/>
            //             </Card.Body>
            //         </Card>
            //         <ButtonToolbar>
            //             <Button variant="primary" type="submit">Submit</Button>
            //             <Link to="/reporting/subject" className="btn btn-danger">Cancel</Link>
            //         </ButtonToolbar>
            //     </Form>
            // </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.name) {
        errors.name = "Name is required!";
    }
    if (!values.natureOfInvolvment) {
        errors.natureOfInvolvment = "Nature of Involvement is required!";
    }
    if (!values.countriesInvolved) {
        errors.countriesInvolved = "Countries Involved is required!";
    }
    if (!values.startDate) {
        errors.startDate = "Enter Start Date!";
    }

    return errors;
}

export const ReportForeignBusinessInvolvement = connect(null, {reportForeignBusinessInvolvement, showSuccessGrowl, showErrorGrowl})(ReportForeignBusinessInvolvementComponent);