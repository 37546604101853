/**
 * Created by shiva on 12/4/19.
 */
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions"
import {getForeignContactQuestionTypes} from "../../../../actions/fieldValue_actions";
import {CommonForeignTravel} from '../commonInfo/common_foreign_travel.js'
import {REPORTING_PASS_CODE, VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import {fetchPriorPassportInfoIfAny} from "../../../../actions/task_actions";
import {
    cancelForeignTravel,
    reportForeignTravel,
    reportingSecondFactor,
    getForeignTravel
} from '../../../../actions/subject_actions';

let stepCount = 0;
const STEP_FILL_ITINERARY = stepCount++;

class ForeignTravelReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // Itinerary Transport - itinerary_transport.js
            activeStep: STEP_FILL_ITINERARY,
            formValues: {
                travelReason: "Foreign Relationship Visit",
                departureDate: "",
                arrivalDate: "",
                countriesVisited: [],
                countryVisits: [],
                transportation: {
                    departureCarrierName: "",
                    departFrom: "",
                    arrivalCarrierName: "",
                    arriveFrom: "",
                },

                // Passport Details - passport_details.js
                passportDetails: {
                    passportNumber: "",
                    issueDate: "",
                    expiryDate: "",
                    passportType: "BOOK",
                },
                // Emergency Contact - emergency_contact.js
                emergencyContactName: "",
                emergencyContactPhone: "",
                emergencyContactEmail: "",
                emergencyContactAddress: "",

                foreignContacts: []
            },
            generalQuestionOptions: [],
            countryVisitStatusMsg: 'Add visit details for each country travelled',
            isLoading: false
        }
    }

    fetchPriorPassportIfAny = async () => {
        let priorPassportInfo = await fetchPriorPassportInfoIfAny();
        if (priorPassportInfo){
            let {formValues} = this.state;
            formValues.passportDetails = priorPassportInfo;
            this.setState({
                ppWarnMessage: "Please review your Passport Information for accuracy. It has been retrieved from your latest trip."
            })
        }
    }


    componentDidMount() {

        // Check if editing existing foreign travel
        const {foreignTravelId} = this.props.match.params;
        if (foreignTravelId){
            this.setState({
                activeStep: -1
            });
            getForeignTravel(foreignTravelId).then((response) => {
                const foreignTravelObj = response.data;
                const countryVisitsMap = {};

                // Put lodges back into country visits
                foreignTravelObj.countryVisits.forEach((countryVisit) => {
                    countryVisit.lodges = [];
                    countryVisit.completed = true;
                    countryVisit.didNotLodge = true;
                    countryVisitsMap[countryVisit.country] = countryVisit;
                });

                foreignTravelObj.lodges.forEach((lodge) => {
                    countryVisitsMap[lodge.country].didNotLodge = false;
                    countryVisitsMap[lodge.country].lodges.push(lodge);
                });

                foreignTravelObj.countryVisits = Object.values(countryVisitsMap);

                if (!foreignTravelObj.transportation){
                    foreignTravelObj.transportation = {
                        departureCarrierName: "",
                        departFrom: "",
                        arrivalCarrierName: "",
                        arriveFrom: "",
                    }
                }

                if (!foreignTravelObj.foreignContacts){
                    foreignTravelObj.foreignContacts = [];
                }

                this.setState({
                    activeStep: STEP_FILL_ITINERARY,
                    formValues: foreignTravelObj,
                    countryVisitStatusMsg: ''
                });
            });
        } else {
            this.fetchPriorPassportIfAny();
        }
        // Get General Question Types
        getForeignContactQuestionTypes().then((response) => {
            const questionOptions = response.data.map((questionType) => {
                return {
                    questionType: questionType,
                    response: false,
                    comments: ""
                };
            });

            this.setState({
                generalQuestionOptions: questionOptions
            });
        });
    }

    onSubmit = async (values) => {
        this.setState({isLoading: true});
        const {foreignTravelId} = this.props.match.params;
        // Extract lodges for backend
        values.lodges = [];
        values.countryVisits.forEach((visit) => {
            values.lodges = values.lodges.concat(visit.lodges);
        });

        // Check if we're editing
        if (foreignTravelId){
            // Delete the foreign travel first
            try {
                const emailCheck = {
                    passCode: sessionStorage.getItem(REPORTING_PASS_CODE),
                    emailAddress: sessionStorage.getItem(VERIFIED_EMAIL)
                }
                await reportingSecondFactor(emailCheck);
                await cancelForeignTravel(foreignTravelId);
                this.setState({isLoading: false});
            } catch (error){
                if (error.response.status === 404) {
                    this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                    this.props.history.push("/reporting/subject/emailCheck");
                } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')) {
                    this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                    sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                    this.props.history.push("/reporting/subject/emailCheck");
                } else {
                    console.log('Error is ', error)
                    this.props.showErrorGrowl("Unable to edit", error.response.data.message);
                    return;
                }
                this.setState({isLoading: false});
            }
        }

        this.props.reportForeignTravel(values, () => {
                this.props.showSuccessGrowl("Foreign Travel Reported Successfully.");
                if (foreignTravelId){
                    this.props.showSuccessGrowl("Previous Foreign Travel Deleted", "Your previous foreign travel has been deleted.")
                }
                this.props.history.push("/reporting/subject");
                this.setState({isLoading: false});
            }, (error) => {
                if (error.response.status === 404) {
                    this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                    this.props.history.push("/reporting/subject/emailCheck");
                } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')) {
                    this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                    sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                    this.props.history.push("/reporting/subject/emailCheck");
                }
                this.setState({isLoading: false});
            }
        )
    }

    close = () => {
        this.props.history.push("/reporting/subject");
    }

    render() {
        return (
            <div className="reporting_bg">
                <div className="container">
                    <div className="heading_container">
                        <h3>Foreign Travel</h3>
                    </div>
                   <CommonForeignTravel  onSubmit={this.onSubmit.bind(this)} onCancel={this.close} />
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>


        )
    }
}

export default connect(null, {reportForeignTravel, showSuccessGrowl, showErrorGrowl})(ForeignTravelReport);
