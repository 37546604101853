import React from "react";
import {fetchVisits} from "../../../../actions/visit_actions";
import {connect} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from "react-bootstrap-table2-filter";
import {VisitNew} from './visit_new';
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import {showErrorGrowl} from "../../../../actions/msg_actions";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {Link} from "react-router-dom";
import {CommonUtils} from "../../../../actions/common_utils";
import Button from "react-bootstrap/cjs/Button";


class VisitListComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.getVisits();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props){
            this.getVisits();
        }
    }

    getVisits = async () => {
        try {
            const visits = await fetchVisits();
            console.log('Visits got are ', visits);
            this.setState({visits});
        } catch(error){
            console.log('Error is ', error);
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')) {
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
        };
    }

    render() {
        const {visits} = this.state;
        console.log('Visits are ', visits)
        if (!visits){
            return <div>Loading...</div>;
        }

        const columns = [
            {
                dataField: "visitingSecurityOfficeName",
                text: "Visiting Office Name",
                sort: true
            },
            {
                dataField: "securityPOCName",
                text: "Security Point of Contact Name"
            },
            {
                dataField: "smoCode",
                text: "SMO Code"
            },
            {
                dataField: "purpose",
                text: "Purpose of Visit"
            },
            {
                dataField: "visitDatesStr",
                text: "Dates of Visit"
            }, {
                dataField: "actions",
                text: "Actions",
                formatter: (cell, row) => (
                    <ButtonToolbar style={{flexWrap: "nowrap"}}>
                        <VisitNew cloneSource={row}/>
                        <VisitNew modifySource={row}/>
                    </ButtonToolbar>
                )
            }
        ];

        return (
            <div className="container-fluid self_reporting">
<div className="col-lg-8 margin-auto">
                <div className="visits_sec">
                    
                <div className="pull-right">
                    <VisitNew onCreate={() => this.props.fetchVisits()}/>
                </div>

                <div className="heading_container">
                <h3>Visits</h3>
                </div>
                
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={visits ? visits : []} columns={columns}
                                filter={filterFactory()}/>
                </div>
            </div>
                <div className="back_btn_block mt-5">
                    <Button className="btn btn-back" onClick={()=>{this.props.history.push('/reporting/subject')}}>Back</Button>
                    <Button className="btn btn-back" onClick={()=>{
                        CommonUtils.handleExit();
                        this.props.history.push('/reporting/subject/emailCheck')}
                    }>Exit</Button>
                </div>
            </div>
            
        )
    }
}

export const VisitList = connect(null, {fetchVisits, showErrorGrowl})(VisitListComponent);