import React, {Component, Fragment} from 'react';
import {ButtonToolbar, Card} from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import {getSubjectForSelfReporting} from "../../../../actions/subject_actions";
import {SubjectReportableUpdateModal} from "../subject_reportable_update_modal";
import {SubjectForeignContactQuestionnaireModal} from "../../../../components/subject/subject_foreign_contact_questionnaire_modal";
import SubjectForeignTravelDetailsModal from "../../../../components/subject/subject_foreign_travel_details_modal";

export class ReportingSubjectForeignContactsDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contactReportables: [],
            travelReportables: []
        };

        // ID Maps
        this.contactIdToContactRepIdMap = new Map();    // Contact ID => Foreign Contact Reportable ID
        this.contactIdToTravelRepIdMap = new Map();     // Contact ID => Foreign Travel Reportable ID
        this.contactRepIdToObjMap = new Map();          // Foreign Contact Reportable ID => Object
        this.travelRepIdToObjMap = new Map();           // Foreign Travel Reportable ID => Object
    }

    async componentDidMount() {
        await this.refreshSubject();
    }

    refreshSubject = async () => {
        this.setState({
            contactReportables: [],
            travelReportables: []
        });

        const subject = await getSubjectForSelfReporting();

        this.setState({
            contactReportables: subject.foreignContactsQuestionnaireReportables,
            travelReportables: subject.foreignTravels
        });
    }

    processReportable = (reportable, contacts = [], repIdToObjMap, contactIdToRepMap, repType, i = 0) => {
        // Map Reportable ID to Reportable Object
        repIdToObjMap.set(reportable.id, reportable);

        let contactsList = [];
        // Loop through contacts in reportable
        if (contacts) {
            for (let contact of contacts) {
                // Generate ID if it doesn't have one
                if (!contact.id) {
                    contact.id = `${contact.firstName}${contact.lastName}${i}`;
                    i++;
                }

                // Set whether the contact belongs to a Foreign Contact Reportable or Foreign Travel Reportable
                contact.type = repType;

                // Map Contact ID to Reportable ID
                contactIdToRepMap.set(contact.id, reportable.id);

                contactsList.push(contact);
            }
        }

        return contactsList;
    }

    getForeignContacts() {
        // Reset maps
        this.contactIdToContactRepIdMap = new Map();
        this.contactIdToTravelRepIdMap = new Map();
        this.contactRepIdToObjMap = new Map();
        this.travelRepIdToObjMap = new Map();

        // Get Contact & Travel Reportables from Subject Details
        const {contactReportables, travelReportables} = this.state;

        // Generate a list of contacts.
        let contactsList = [];

        // Loop through each reportable (for... of is equivalent to foreach in ES6)
        if (contactReportables) {
            for (const reportable of contactReportables) {
                // Get contacts from reportable
                const repContacts = this.processReportable(
                    reportable,
                    reportable.contacts,
                    this.contactRepIdToObjMap,
                    this.contactIdToContactRepIdMap,
                    "FOREIGN_CONTACT_REPORTABLE",
                    contactsList.length);

                contactsList = contactsList.concat(repContacts);
            }
        }

        if (travelReportables) {
            for (const reportable of travelReportables) {
                // Get contacts from reportable
                const repContacts = this.processReportable(
                    reportable,
                    reportable.foreignContacts,
                    this.travelRepIdToObjMap,
                    this.contactIdToTravelRepIdMap,
                    "FOREIGN_TRAVEL_REPORTABLE",
                    contactsList.length);

                contactsList = contactsList.concat(repContacts);
            }
        }

        return contactsList;
    }

    render() {
        // Get contacts
        const contacts = this.getForeignContacts();

        // Check if list is empty or undefined
        if (contacts.length === 0) {
            return <Alert variant="danger">No Foreign Contacts!</Alert>;
        }

        const columns = [{
            dataField: 'fullName',
            text: 'Name',
            sort: true,
            filter: textFilter(),
            formatter: (cell, row) => `${row.lastName}, ${row.firstName}`
        }, {
            dataField: 'citizenCountry',
            text: 'Country',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'startDate',
            text: 'Start Date',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'dateInvalidated',
            text: 'End Date',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                if (row.type === "FOREIGN_CONTACT_REPORTABLE") {
                    const reportable = this.contactRepIdToObjMap.get(this.contactIdToContactRepIdMap.get(row.id));

                    return (
                        <ButtonToolbar className="p-0 mb-0 float-right" style={{flexWrap: "nowrap"}}>
                            {!row.dateInvalidated &&
                            <SubjectReportableUpdateModal reportable={reportable}
                                                          reportableTypeString={"Foreign Contact"}
                                                          foreignContactIndex={row.id}
                                                          onUpdate={this.refreshSubject}/>
                            }
                            <SubjectForeignContactQuestionnaireModal
                                questionnaire={this.contactRepIdToObjMap.get(this.contactIdToContactRepIdMap.get(row.id))}
                                selectedContactId={row.id}
                            />
                        </ButtonToolbar>
                    )
                } else {
                    const reportable = this.travelRepIdToObjMap.get(this.contactIdToTravelRepIdMap.get(row.id));

                    return (
                        <ButtonToolbar className="p-0 mb-0 float-right" style={{flexWrap: "nowrap"}}>
                            {!row.dateInvalidated &&
                            <SubjectReportableUpdateModal reportable={reportable}
                                                          reportableTypeString={"Foreign Contact"}
                                                          foreignContactIndex={row.id}
                                                          onUpdate={this.refreshSubject}/>
                            }
                            <SubjectForeignTravelDetailsModal
                                foreignTravelDetail={this.travelRepIdToObjMap.get(this.contactIdToTravelRepIdMap.get(row.id))}
                            />
                        </ButtonToolbar>
                    )
                }
            }
        }];

        return (
            <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                            data={this.getForeignContacts()}
                            columns={columns}
                            filter={filterFactory()}/>
        )
    }
}