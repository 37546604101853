import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {DateUtils} from "../../../../actions/common_utils";
import {getSubjectForSelfReporting} from "../../../../actions/subject_actions";
import {ButtonToolbar} from "react-bootstrap";
import {SubjectReportableUpdateModal} from "../subject_reportable_update_modal";

export class ReportingSubjectMediaContactDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            mediaContacts: []
        };
    }

    async componentDidMount() {
        await this.refreshSubject();
    }

    refreshSubject = async () => {
        this.setState({
            mediaContacts: []
        });

        const subject = await getSubjectForSelfReporting();

        this.setState({
            mediaContacts: subject.mediaContacts
        });
    }

    getMediaContacts() {
        return this.state.mediaContacts.map((contact) => {
            return <tr key={contact.id}>
                <td>{contact.contactDate ? DateUtils.getDateString(contact.contactDate) : "Unknown"}</td>
                <td>{contact.mediaOutletName}</td>
                <td>{contact.firstName} {contact.lastName}</td>
                <td>{contact.contactReason}</td>
                <td>{contact.classifiedInformation ? "Yes" : "No"}</td>
                <td>
                    <ButtonToolbar className="p-0 mb-0 float-right" style={{flexWrap: "nowrap"}}>
                        {!contact.dateInvalidated &&
                        <SubjectReportableUpdateModal reportable={contact}
                                                      reportableTypeString={"Media Contact"}
                                                      onUpdate={this.refreshSubject}/>
                        }
                    </ButtonToolbar>
                </td>
            </tr>
        })
    }

    render(){
        const {mediaContacts} = this.state;

        if (!mediaContacts || mediaContacts.length === 0) {
            return <Alert variant="danger">No Media Contacts!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Contact Date</th>
                        <th>Media Outlet Name</th>
                        <th>Media Representative Name</th>
                        <th>Contact Reason</th>
                        <th>Classified or Prohibited Information</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {this.getMediaContacts()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}