import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {Form, Card, Col, Button} from "react-bootstrap";
import * as Yup from "yup";
import {Field, Formik} from "formik";
import ButtonToolbar from "react-bootstrap/ButtonToolbar"; 
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { CommonUtils } from "../../../../actions/common_utils";
import { FormikDatePickerComponent } from "../../../../components/formik/formik_date_picker";
import { FormikFieldDropzone } from "../../../../components/formik/formik_field_dropzone";

export class CommonForeignCitizenShip extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() { 
        return (
            <Fragment>
                 <Formik
                initialValues={{
                    "country": "",
                    "basis": "",
                    "startDate": "",
                    "supportingDocs": ""
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit.bind(this)}
                validationSchema={
                    Yup.object().shape({
                        country: Yup.string().required('Country is required!'),
                        basis: Yup.string().required('Basis of Citizenship is required!'),
                        startDate: Yup.date().required('Enter Start Date!'),
                        supportingDocs: Yup.mixed().test(
                            'test-name',
                            'There must be a file uploaded',
                            function(value) {
                                return value && value.length > 0;
                            }
                        ).nullable().required('You must upload a supporting document')
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    
                    
                        <Form noValidate onSubmit={handleSubmit}>
                       
                        
                        <Card>
                            <Card.Header>Foreign Citizenship Information</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId={'country'}>
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control required type="text" name={'country'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter the Country of foreign citizenship'}
                                                      isInvalid={touched.country && !!errors.country}
                                                      value={values.country}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.country}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'basis'}>
                                        <Form.Label>Basis of Citizenship</Form.Label>
                                        <Form.Control required type="text" name={'basis'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Basis of citizenship'}
                                                      isInvalid={touched.basis && !!errors.basis}
                                                      value={values.basis}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.basis}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'startDate'}>
                                        <Form.Label>Issuance Date</Form.Label>
                                        <Field
                                            id="startDate"
                                            name="startDate"
                                            component={FormikDatePickerComponent}
                                            placeholder={'Issuance Date (MM/DD/YYYY'}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'supportingDocs'}>
                                    <Form.Label>Upload document supporting citizenship. Passport, certificate of citizenship...</Form.Label>
                                        <Field
                                            name="supportingDocs"
                                            component={FormikFieldDropzone}
                                            multiple={false}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                
                            </Card.Body>
                            <Card.Footer>
                            <ButtonToolbar>
                                    <Button variant="primary" type="submit">Submit</Button>
                                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Foreign Citizenship Form"
                                                         handleDeleteCallback={this.handleCancel.bind(this)}/>
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>
                    </Form> 
                )}
            </Formik>
            </Fragment>
        )
    }
}

