/**
 * Created by shiva on 12/4/19.
 */
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import { reportForeignTravel} from '../../../../actions/subject_actions';
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions"
import Card from "react-bootstrap/Card";
import {FieldRBDatePicker, FieldRBDropzone, FieldRBInput} from "../../../../components/redux_form/field_components_rb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { reduxForm, Field} from "redux-form";
import Col from "react-bootstrap/Col";


 class  CommonForeignIdCardComp extends Component {
    constructor(props) {
        super(props)
    }

    onSubmit(values) { 
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        // close action is differ compare to reporting and subject
        this.props.onCancel();
    }

    onFileChange = (files) => {
        if (files[0]) {
            this.props.change("filename", files[0].name);
            this.props.change("filetype", files[0].type);
            this.props.change("previewUrl", files[0].preview);
        }
    }


    render() {
        const { handleSubmit } = this.props;
        return (        
            <Fragment>
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Card>
                        <Card.Header>Foreign Citizenship Information</Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} md="4" controlId="country">
                                    <Field name="country" component={FieldRBInput} label="Country" required horizontal />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="idCardNumber">
                                    <Field name="idCardNumber" component={FieldRBInput} label="ID Card Number" required horizontal />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="reason">
                                    <Field name="reason" component={FieldRBInput} label="Reason" required horizontal />
                                </Form.Group>

                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} md="4" controlId="startDate">
                                    <Field name="startDate" component={FieldRBDatePicker} label="Issuance Date" required horizontal />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="expireDate">
                                    <Field name="expireDate" component={FieldRBDatePicker} label="Expiration Date" horizontal />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                            <Form.Group as={Col} md="12" controlId="supportingDocs">
                                    <Field name="supportingDocs" component={FieldRBDropzone} label="ID Document" onChange={this.onFileChange}
                                        multiple={false} placeHolder={'Upload document supporting foreign citizenship. Passport, certificate of citizenship..'} required horizontal />
                                </Form.Group>
                            </Form.Row>

                        </Card.Body>
                        <Card.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit">Submit</Button>
                                {/* <Link to="/reporting/subject" className="btn btn-danger">Cancel</Link> */}
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Foreign Travel Form"
                                    handleDeleteCallback={this.handleCancel} />
                            </ButtonToolbar>
                        </Card.Footer>
                    </Card>
               
            </Form>
         </Fragment>   
        )
    }
}
function validate(values) {
    const errors = {};

    if (!values.idCardNumber) {
        errors.idCardNumber = "ID Card/Passport Number is required!";
    }
    if (!values.reason) {
        errors.reason = "Reason is required!";
    }
    if (!values.country) {
        errors.country = "Country is required!";
    }
    if (!values.startDate) {
        errors.startDate = "Enter Start Date!";
    }
    if (!values.file || values.file.size < 1) {
        errors.file = "You must upload a supporting document";
    }

    return errors;
}
export const CommonForeignIdCard = reduxForm({
    validate,
    form: "ReportForeignIdCardForm"
})(
    connect(null, {reportForeignTravel, showSuccessGrowl, showErrorGrowl})(CommonForeignIdCardComp)
);
