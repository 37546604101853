import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectForeignBusinessInvolvementDetail extends Component {
    getData() {
        return this.props.foreignBusInvolvements.map((foreignBusInvolvement) => {
            return <tr key={foreignBusInvolvement.id}>
                <td>{foreignBusInvolvement.name}</td>
                <td>{foreignBusInvolvement.natureOfInvolvment}</td>
                <td>{foreignBusInvolvement.countriesInvolved}</td>
            </tr>
        })
    }
    render() {
        const {foreignBusInvolvements} = this.props;

        if (!foreignBusInvolvements || foreignBusInvolvements.length === 0) {
            return <Alert variant="danger">No Foreign Business Involvements!</Alert>
        }
        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Nature of Involvement</th>
                        <th>Countries Involved</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getData()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}
