import React, {Component, Fragment} from "react";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Card, Col, Form, Modal, Button, Alert} from "react-bootstrap";
import {AVAILABLE_COUNTRIES, CommonUtils, DateUtils} from "../../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";
import {ForeignCountryVisitDetails} from "./foreign_country_visit_details";

export class ForeignCountryVisitForm extends Component {
    constructor(props) {
        super(props);
    }

    handleVisitChange = (visit) => {
        const {previewData, onChange} = this.props;

        let newVisits = previewData.countryVisits;
        const visitIndex = newVisits.findIndex((v) => v.country === visit.country);
        newVisits[visitIndex] = visit;

        // Call onChange handler
        onChange(newVisits);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {previousPage} = this.props;
        const {previewData, errorMessage} = this.props;

        const columns = [{
            dataField: 'country',
            text: 'Country',
            sort: true,
            filter: textFilter()
        }, {
            dataField: "arrivalDate",
            text: "Arrival Date",
            formatter: (cell, row) => DateUtils.getDateString(cell),
            sort: true,
        }, {
            dataField: "departureDate",
            text: "Departure Date",
            formatter: (cell, row) => DateUtils.getDateString(cell),
            sort: true,
        }, {
            dataField: "lodges",
            text: "Lodges",
            formatter: (cell, row) => cell.length
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <Fragment>
                        <ForeignCountryVisitDetails previewData={previewData} value={row} onChange={this.handleVisitChange} />
                    </Fragment>
                )
            }
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                <Card>
                    <Card.Header>Foreign Country Visits</Card.Header>
                    <Card.Body>
                            {(errorMessage && errorMessage !== '') &&
                            <Alert variant="danger">{errorMessage}</Alert>
                            }
                            <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="country"
                                            data={previewData.countryVisits}
                                            columns={columns} pagination={pagination}
                                            filter={filterFactory()}
                                            rowClasses={(row) => row.completed ? "table-success" : "table-danger"}/>
                        </Card.Body>

                    <Card.Footer>
                    <div className={"pull-right1"}>
                        <ButtonToolbar>
                            <Button variant="primary" onClick={previousPage}>Previous</Button>
                            <Button variant="primary" disabled={(errorMessage && errorMessage !== '')}
                                    onClick={this.props.onContinue}>Next</Button>
                            {/* <Button variant="danger" onClick={this.props.onCancel}>Cancel</Button> */}
                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"}
                                                     deleteEntity="Foreign Travel"
                                                     handleDeleteCallback={this.handleCancel}/>
                        </ButtonToolbar>
                    </div>
                    </Card.Footer>
                </Card>
                
            </Fragment>
        )
    }

}

ForeignCountryVisitForm.propTypes = {
    previewData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
    previousPage: PropTypes.func.isRequired
}