import React, {Component, Fragment} from "react";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import * as Yup from "yup";
import {Card, Col, Form, Modal, Button} from "react-bootstrap";
import {CommonUtils} from "../../../../../actions/common_utils";
import {ForeignContactNew} from "./foreign_contact_new";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ForeignContactComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddContactForm: false
        }
    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.addContact(values);
        this.setState({showAddContactForm: false});
    }

    removeContact = (contact) => {
        this.props.removeContact(contact);
        this.setState({showAddContactForm: false});
    }

    open = () => {
        this.setState({showAddContactForm: true});
    }
    close = () => {
        this.setState({showAddContactForm: false});
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {previousPage} = this.props;
        const {addedContacts, viewOnly, canContinue, disableContactSubmitButton} = this.props;

        const columns = [{
            dataField: 'firstName',
            text: 'First Name',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'lastName',
            text: 'Last Name',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'citizenCountry',
            text: 'Citizenship Country',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'placeOfContact',
            text: 'Place of Contact',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'contactReason',
            text: 'Reason for Contact',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <Fragment>
                        {!viewOnly &&
                        <Button className="close" onClick={() => this.removeContact(row)}>
                            <span aria-hidden="true" title={"Remove Contact From Travel"} style={{padding: 10}} aria-label="Remove Contact From Travel">
                            <i className="fa fa-times-circle" style={{color: "black"}}></i>
                            </span>
                        </Button>
                        }
                    </Fragment>
                )
            }
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        const {showAddContactForm} = this.state;

        return (
            <Fragment>
                <Modal.Body>
                    <Card>
                        <Card.Header>Foreign Contacts (If none click on Complete to submit)</Card.Header>
                        <Card.Body>
                            <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="firstName"
                                            data={addedContacts}
                                            columns={columns} pagination={pagination}
                                            filter={filterFactory()}/>
                        </Card.Body>
                        <Card.Footer>
                            <div className={"text-right"}>
                                <Button onClick={this.open} variant="primary">Add Foreign Contact</Button>
                            </div>
                            <Modal show={showAddContactForm} onHide={this.close} size={'lg'} scrollable={false}>
                                <ForeignContactNew onSubmit={this.onSubmit} onClose={this.close}/>
                            </Modal>
                        </Card.Footer>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"pull-right1"}>
                        <ButtonToolbar>
                            {/*{(addedContacts && addedContacts.length > 0) &&*/}
                            <Button variant="primary" onClick={previousPage}>Previous</Button>
                            {console.log('Disable contact submit is ', disableContactSubmitButton)}
                            <Button variant="primary" disabled={!canContinue || disableContactSubmitButton}
                                    onClick={this.props.onContinue}>{(!canContinue || (addedContacts && addedContacts.length > 0)) ? 'Next' : 'Complete'}</Button>
                            {/* <Button variant="danger" onClick={this.props.onCancel}>Cancel</Button> */}
                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Foreign Travel"
                                                         handleDeleteCallback={this.handleCancel}/>
                        </ButtonToolbar>
                    </div>
                </Modal.Footer>
            </Fragment>
        )
    }

}

export const ForeignContact = connect(null, {})(ForeignContactComponent);

ForeignContact.propTypes = {
    addedContacts: PropTypes.array.isRequired,
    addContact: PropTypes.func.isRequired,
    removeContact: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    canContinue: PropTypes.bool.isRequired, // Notifies if you can move to the next step. Calling methods control this. Reused by foreign travel reporting and foreign contacts and they have different criteria
    previousPage: PropTypes.func.isRequired,
    disableContactSubmitButton: PropTypes.bool.isRequired
}
