import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {Form, Card, Col, Button} from "react-bootstrap";
import * as Yup from "yup";
import {Field, Formik} from "formik";
import ButtonToolbar from "react-bootstrap/ButtonToolbar"; 
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { CommonUtils } from "../../../../actions/common_utils";
import { FormikDatePickerComponent } from "../../../../components/formik/formik_date_picker";
import { reportForeignContact } from "../../../../actions/subject_actions";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";
import { getForeignContactQuestionTypes } from "../../../../actions/fieldValue_actions";
import { ForeignContact } from "../foreignTravel/travelWizard/foreignContacts";
import { ReportForeignContactQuestionnaire } from "../foreignTravel/travelWizard/foreign_contact_questionnaire";
import {Steps} from "primereact/steps";


const STEP_ADD_CONTACT = 0;
const STEP_CONTACT_QUESTIONNAIRE = 1;

export class CommonForeignContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: STEP_ADD_CONTACT,
            formValues: {contacts: []},
            generalQuestionOptions: [],
            stepItems: [
                {label: "Foreign Contact"},
                {label: "Contact Questionnaire"}
            ]
        }
    }
    componentDidMount() {
        // Get General Question Types
        getForeignContactQuestionTypes().then((response) => {
            const questionOptions = response.data.map((questionType) => {
                return {
                    questionType: questionType,
                    response: false,
                    comments: ""
                };
            });

            this.setState({
                generalQuestionOptions: questionOptions
            });
        });
    }
    onSubmit(values) {
        let {formValues} = this.state;
        this.props.onSubmit(formValues);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    onContactAdd = (contact) => {
        if (contact) {
            let {formValues} = this.state;
            if (!formValues.contacts) {
                formValues.contacts = [];
            }
            formValues.contacts.push(contact);
            this.setState({
                formValues
            })
        }
    }

    onContactDelete = (contact) => {
        if (contact) {
            let {formValues} = this.state;
            let contacts = formValues.contacts;
            for (let i = 0; i < contacts.length; i++) {
                if (contacts[i].firstName === contact.firstName &&
                    contacts[i].lastName === contact.lastName) {
                    contacts.splice(i, 1);
                    i--;
                }
            }
            this.setState({formValues})
        }
    }
    onContactsDone = () => {
        let {activeStep, formValues} = this.state;
        if (formValues.contacts && formValues.contacts.length > 0){
            this.setState({
                activeStep: activeStep+1
            })
        }
    }

    onContactQuesResponse = (values) => {
        let {formValues} = this.state;
        formValues = {...formValues, ...values};
        console.log('Form values with questionnaire response are ', formValues);
        this.setState({
            formValues
        });
        this.onSubmit();
    }

    renderStepContent = () => {
        let {formValues} = this.state;
        switch (this.state.activeStep) {
            case STEP_ADD_CONTACT:
                return <ForeignContact addedContacts={formValues.contacts} addContact={this.onContactAdd} disableContactSubmit={false}
                                       removeContact={this.onContactDelete} onContinue={this.onContactsDone}
                                       onCancel={this.close} canContinue={formValues.contacts && formValues.contacts.length > 0}/>;
            case STEP_CONTACT_QUESTIONNAIRE:
                return <ReportForeignContactQuestionnaire addQuestionnaireResponse={this.onContactQuesResponse}
                                                          onCancel={this.close}/>
        }
    }

    getSteps = () => {
        const {stepItems} = this.state;

        const onActiveIndexChange = (e) => this.setState({activeStep: e.index});
        return (
            <Steps model={stepItems} activeIndex={this.state.activeStep}
                   activeIndexChange={onActiveIndexChange.bind(this)} className="foreign-contacts-create-steps"/>
        )
    };

    render() { 
        return (
        <Fragment>
        <Card> 
            <Card.Body>
                {this.getSteps()}
                {this.renderStepContent()}
            </Card.Body>
        </Card>
    </Fragment>
        )
    }
}

export default connect(null, {reportForeignContact, showSuccessGrowl, showErrorGrowl})(CommonForeignContacts);