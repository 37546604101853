import React, {Component, Fragment} from "react";
import {Card, Button, Modal, ButtonToolbar, Col, Form} from "react-bootstrap";
import PropTypes from "prop-types";
import {Field, Formik, getIn} from "formik";
import * as Yup from "yup";
import {CommonUtils} from "../../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import {FormikFieldDualListBox} from "../../../../../components/formik/formik_field_duallistbox";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class AddItineraryAndTransportComponent extends Component {
    constructor(props) {
        super(props);

    }
    onSubmit = (values, actions) => {
        console.log(this.props)
        actions.setSubmitting(false);
        this.props.onAddItinerary(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        let {previewdata} = this.props;
        console.log(previewdata);
        return (
            <Fragment>
                <Formik
                    initialValues={{
                        travelReason: previewdata.travelReason,
                        departureDate: previewdata.departureDate,
                        arrivalDate: previewdata.arrivalDate,
                        countriesVisited: previewdata.countriesVisited,
                        transportation: previewdata.transportation
                    }}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    validationSchema={
                        Yup.object().shape({
                        travelReason: Yup.string().required('Enter the reason for travel'),
                        departureDate: Yup.date()
                            .test('dates', 'Departure date must be less than or equal to arrival date', function (arrivalDate) {
                            const departureDate = this.resolve(Yup.ref('departureDate'));
                            if (!arrivalDate || !departureDate) {
                                return true;
                            }
                            return departureDate <= arrivalDate;
                            })
                            .typeError('Enter a valid date')
                            .required('Enter Departure date'),
                            arrivalDate: Yup.date()
                            .when('departureDate', (departureDate, schema) =>
                                schema
                                .test('dates', 'Arrival date must be greater than or equal to departure date', function (arrivalDate) {
                                    if (!arrivalDate || !departureDate) {
                                    return true;
                                    }
                                    return arrivalDate >= departureDate;
                                })
                                .typeError('Enter a valid date')
                                .required('Enter Arrival date')
                            ),
                            countriesVisited: Yup.array().min(1, 'Select one or more countries visited'),
                            transportation: Yup.object().shape({
                            departureCarrierName: Yup.string().required('Enter the departure carrier name'),
                            departFrom: Yup.string().required('Enter place of departure'),
                            arrivalCarrierName: Yup.string().required('Enter arrival carrier name'),
                            arriveFrom: Yup.string().required('Enter the place you are returning from')
                            })
                        })
                    }
                >
                    {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Card>
                            <Card.Body>
                                <Card>
                                    <Card.Header>Itinerary</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" controlId={"travelReason"}>
                                                <Form.Label>Specification Type</Form.Label>
                                                <Form.Control required as="select" name={'travelReason'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.travelReason && !!errors.travelReason}
                                                              value={values.travelReason}
                                                >
                                                    {CommonUtils.getOptionsForSelect([
                                                        { id: "Foreign Relationship Visit", name: "Foreign Relationship Visit" },
                                                        { id: "Official for non- DOD purposes", name: "Official for non- DOD purposes" },
                                                        { id: "NGO/Missionary Services", name: "NGO/Missionary Services" },
                                                        { id: "Vacation", name: "Vacation" },
                                                        { id: "Official Travel", name: "Official Travel" }
                                                    ])}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.travelReason}
                                                </Form.Control.Feedback>
                                                {values.travelReason==="Official Travel" &&<Form.Text>
                                                   <div className="alert alert-info mt-2"> <i className="fa fa-circle-info"></i> Please use this section to report any official DoD travel engagements as required by your position</div>
                                                </Form.Text>}
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Date of Departure</Form.Label>
                                                <Field
                                                    id="departureDate"
                                                    name="departureDate"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Enter date of departure from US"}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Date of Return</Form.Label>
                                                <Field
                                                    id="arrivalDate"
                                                    name="arrivalDate"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Enter date of return to US"}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Countries Visited</Form.Label>
                                                <Field
                                                    id="countriesVisited"
                                                    name="countriesVisited"
                                                    component={FormikFieldDualListBox}
                                                    options={CommonUtils.convertSingleArrayToDualListBox()}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.countriesVisited}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                           
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-20">
                                    <Card.Header>Transportation</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Departure Carrier Name</Form.Label>
                                                <Form.Control required type="text" name={'transportation.departureCarrierName'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder={'Flight No/Ship Name/Train/Personal Vehicle...'}
                                                              isInvalid={getIn(touched, "transportation.departureCarrierName") &&
                                                              !!getIn(errors, "transportation.departureCarrierName")}
                                                              value={getIn(values, "transportation.departureCarrierName")}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {getIn(errors, "transportation.departureCarrierName")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Departure From (US)</Form.Label>
                                                <Form.Control required type="text" name={'transportation.departFrom'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder={'Place of departure (from US): airport/city/station...'}
                                                              isInvalid={getIn(touched, "transportation.departFrom") &&
                                                              !!getIn(errors, "transportation.departFrom")}
                                                              value={getIn(values, "transportation.departFrom")}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {getIn(errors, "transportation.departFrom")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Return Carrier Name</Form.Label>
                                                <Form.Control required type="text" name={'transportation.arrivalCarrierName'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder={'Return Flight No/Ship Name/Train/Personal Vehicle...'}
                                                              isInvalid={getIn(touched, "transportation.arrivalCarrierName") &&
                                                              !!getIn(errors, "transportation.arrivalCarrierName")}
                                                              value={getIn(values, "transportation.arrivalCarrierName")}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {getIn(errors, "transportation.arrivalCarrierName")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Returning (to US) From</Form.Label>
                                                <Form.Control required type="text" name={'transportation.arriveFrom'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder={'Returning (to US) from: airport/city/station...'}
                                                              isInvalid={getIn(touched, "transportation.arriveFrom") &&
                                                              !!getIn(errors, "transportation.arriveFrom")}
                                                              value={getIn(values, "transportation.arriveFrom")}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {getIn(errors, "transportation.arriveFrom")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Card.Body>
                            <Card.Footer>
                                <div className={"pull-right1"}>
                                    <ButtonToolbar>
                                        <Button variant="primary" disabled={isSubmitting}
                                                type="submit">Next</Button>
                                        {/* <Button variant="danger" onClick={this.props.onCancel}>Cancel</Button> */}

                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Foreign Travel"
                                                         handleDeleteCallback={this.handleCancel}/>
                                    </ButtonToolbar>
                                </div>
                            </Card.Footer>
                            </Card>
                        </Form>
                    )}
                </Formik>
            </Fragment>
        )
    }
}

AddItineraryAndTransportComponent.propTypes = {
    onAddItinerary: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    previewdata: PropTypes.object.isRequired
};

export const AddItineraryAndTransport = AddItineraryAndTransportComponent;