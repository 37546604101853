import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import PropTypes from "prop-types";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {CommonUtils} from "../../../../actions/common_utils";
import {FormikFieldDropzone} from "../../../../components/formik/formik_field_dropzone";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";

class AddForeignContactModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    open = () => {
        this.props.updateParentStateWhenSubmitting(true);
        this.setState({showModal: true});
    }

    close = () => {
        this.props.updateParentStateWhenSubmitting(false);
        this.setState({
            showModal: false
        });
    }

    onSubmit = (values) => {
        // const form = event.currentTarget;
        values.supportingDocs = [CommonUtils.createDocForUploadingForFormik(values.file)];
        this.props.onAddContact(values);
        console.log('Contact Added is ', values);
        this.close();
    };
    render() {
        const {showModal} = this.state;

        return (
            <Fragment>
                <Button onClick={this.open} variant="primary">Add Foreign Contact</Button>

                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Contact Information</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            "firstName": "",
                            "lastName": "",
                            "organization": "",
                            "occupation": "",
                            "address": "",
                            "phoneNumber": "",
                            "contactLength": "",
                            "citizenCountry": "United States",
                            "placeOfContact": "",
                            "contactReason": "",
                            "startDate": "",
                            "file": ''
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                firstName: Yup.string().required('Enter first name'),
                                lastName: Yup.string().required('Enter last name'),
                                contactLength: Yup.string().required('Enter contact length'),
                                citizenCountry: Yup.string().required('Select a Country of Citizenship'),
                                placeOfContact: Yup.string().required('Enter a contact location'),
                                contactReason: Yup.string().required('Enter a contact reason'),
                                startDate: Yup.date().required('Enter date contact initiated')
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control required type="text" name={'firstName'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'First Name'}
                                                          isInvalid={touched.firstName && !!errors.firstName}
                                                          value={values.firstName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.firstName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control required type="text" name={'lastName'} onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Last Name'}
                                                          isInvalid={touched.lastName && !!errors.lastName}
                                                          value={values.lastName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.lastName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Contact Citizenship</Form.Label>
                                            <Form.Control required as="select" name={'citizenCountry'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'Enter Country of citizenship'}
                                                          isInvalid={touched.citizenCountry && !!errors.citizenCountry}
                                                          value={values.citizenCountry}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.getAvailCountries())}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.citizenCountry}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md={6} controlId={'organization'}>
                                            <Form.Label>{'Company/Organization'}</Form.Label>
                                            <Form.Control type="text" name={'organization'} onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={"Contact's company if known"}
                                                          isInvalid={touched.organization && !!errors.organization}
                                                          value={values.organization}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.organization}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId={'occupation'}>
                                            <Form.Label>{'Occupation'}</Form.Label>
                                            <Form.Control type="text" name={'occupation'} onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Occupation'}
                                                          isInvalid={touched.occupation && !!errors.occupation}
                                                          value={values.occupation}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.occupation}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md={6} controlId={'address'}>
                                            <Form.Label>{'Address'}</Form.Label>
                                            <Form.Control type="text" name={'address'} onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Address'}
                                                          isInvalid={touched.address && !!errors.address}
                                                          value={values.address}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.address}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId={'phoneNumber'}>
                                            <Form.Label>{'Phone Number'}</Form.Label>
                                            <Form.Control type="text" name={'phoneNumber'} onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Phone Number'}
                                                          isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                                          value={values.phoneNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.phoneNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Place of Contact</Form.Label>
                                            <Form.Control required type="text" name={'placeOfContact'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter Place of Contact'}
                                                          isInvalid={touched.placeOfContact && !!errors.placeOfContact}
                                                          value={values.placeOfContact}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.placeOfContact}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Reason for Contact</Form.Label>
                                            <Form.Control required type="text" name={'contactReason'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'Enter the reason for contact'}
                                                          isInvalid={touched.contactReason && !!errors.contactReason}
                                                          value={values.contactReason}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contactReason}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Length of Contact</Form.Label>
                                            <Form.Control required type="text" name={'contactLength'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'Enter how long you have known the contact. Ex: 2 years/1 month/7 days...'}
                                                          isInvalid={touched.contactLength && !!errors.contactLength}
                                                          value={values.contactLength}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contactLength}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Enter Date Contact Initiated</Form.Label>
                                            <Field
                                                id="startDate"
                                                name="startDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Contact Initiated Date"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Check label={'Is Contact a Relative'} type="checkbox"
                                                        name={'relative'} onChange={handleChange}
                                                        onBlur={handleBlur} placeholder={'Is the contact a relative'}
                                                        isInvalid={touched.relative && !!errors.relative}
                                                        value={values.relative}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.relative}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Attach Business Card of Contact(If any)</Form.Label>
                                            <Field name="file" multiple={true}
                                                   component={FormikFieldDropzone}/>
                                        </Form.Group>
                                    </Form.Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>Add
                                            Contact</Button>
                                        <Button variant="danger" onClick={this.close}>Cancel</Button>
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

AddForeignContactModal.propTypes = {
    onAddContact: PropTypes.func.isRequired,
    updateParentStateWhenSubmitting: PropTypes.func.isRequired
};

export default AddForeignContactModal;