import React, { Component, Fragment } from "react";
import { Field, reduxForm, SubmissionError, Form } from "redux-form";
import { reportMaritalStatusChange } from "../../../../actions/subject_actions";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";
import { connect } from "react-redux";
import Alert from "react-bootstrap/Alert";
import { CommonUtils } from "../../../../actions/common_utils";
import { VERIFIED_EMAIL } from "../../../../actions/auth_actions";
import { CommonReportMaritalStatus } from "../commonInfo/common_personalInfo_maritalstatus";

class ReportPersonalInfoMaritalStatus extends Component {
    constructor(props) {
        super(props);

        this.state={
            isLoading: false,
        }
    }

    onSubmit(values) {
        this.setState({isLoading: true});
        if (values.status === "marriage") {
            values.dateInvalidated = null;
            values.reasonInvalidated = null;
        } else {
            values.firstName = null;
            values.lastName = null;
            values.startDate = null;
        }

        if (values.file) values.supportingDocs = [CommonUtils.createDocForUploading(values)];
 
        this.props.reportMaritalStatusChange(values, () => {
            this.props.showSuccessGrowl("Marital Status Change Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            } else if (error.response.status === 400) {
                this.props.showErrorGrowl("Error", "You cannot enter a marriage when one already exists!");
//                throw new SubmissionError({
//                    _error: "Please report a divorce prior to reporting another marriage!"
//                });
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')) {
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        });
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const { handleSubmit, error } = this.props;

        return (

            <div className="reporting_bg">
                <div className="container">
                    <div className="heading_container">
                        <h3>Report Marital Status Form</h3>
                    </div>
                    {error &&
                        <Alert variant="danger">
                            {error}
                        </Alert>}
                    <CommonReportMaritalStatus onSubmit={handleSubmit(this.onSubmit.bind(this))} onCancel={this.handleCancel} />
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>

        )
    }
}

export default reduxForm({
    form: "ReportMaritalStatusChangeForm",
})(
    connect(null, {
        showSuccessGrowl,
        showErrorGrowl,
        reportMaritalStatusChange
    })(ReportPersonalInfoMaritalStatus)
);