import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import * as PropTypes from "prop-types";
import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../actions/common_utils";

export class PersonalInfoCohabitant extends Component {
    render(){
        const {cohabitant} = this.props;

        return (
            <Fragment>
                <Form.Row>
                    <Form.Group as={Col} md={6} controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control plaintext readOnly value={cohabitant.firstName}/>
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control plaintext readOnly value={cohabitant.lastName}/>
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="birthPlace">
                        <Form.Label>Place of Birth</Form.Label>
                        <Form.Control plaintext readOnly value={cohabitant.birthPlace}/>
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="birthDate">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control plaintext readOnly value={cohabitant.birthDate ? DateUtils.getDateString(cohabitant.birthDate) : "Unknown"}/>
                    </Form.Group>
                </Form.Row>
            </Fragment>
        )
    }
}

PersonalInfoCohabitant.propTypes = {
    cohabitant: PropTypes.object.isRequired
};