import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { FieldRBCountrySelector, FieldRBDatePicker, FieldRBInput, FieldRBRegionSelector } from "../../../../components/redux_form/field_components_rb";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
class CommonPersonalInfoAddressComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) { 
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() {
        const { handleSubmit,sel_country } = this.props;
        return (
            <Fragment>
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}> 
                    <Card>
                        <Card.Header>Current Address Information</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}> 
                                    <Field name="address1" component={FieldRBInput} label="Address Line 1" required horizontal/>
                                </Col>

                                <Col md={6}> 
                                    <Field name="address2" component={FieldRBInput} label="Address Line 2" horizontal/>
                                </Col>

                                <Col md={4}>
                                    <Field name="city" required component={FieldRBInput} label="City"/>
                                </Col>

                                <Col md={3}>
                                    <Field name="stateOrProvince" component={FieldRBRegionSelector}
                                           country={sel_country}
                                           label="State or Region" required/>
                                </Col>

                                <Col md={3}>
                                    <Field name="country" component={FieldRBCountrySelector} label="Country" required/>
                                </Col>

                                <Col md={2}>
                                    <Field name="postalCode" required="required" component={FieldRBInput}
                                           label="Postal Code"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Field name="dateInvalidated" component={FieldRBDatePicker} label="Date Moved Out of Previous Residence (If Applicable)" />
                                </Col>
                                <Col md={6}>
                                    <Field name="startDate" component={FieldRBDatePicker} label="Date Moved In" required/>
                                </Col>
                            </Row>
                        </Card.Body>

                        <Card.Footer>
                        <ButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        {/* <DeleteConfirmationModal actionText={"cancel"} buttonText={"Cancel"} deleteEntity="Report Address Change Form" variant="danger" buttonType={'danger'} /> */}
                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Address Change Form"
                                                         handleDeleteCallback={this.handleCancel.bind(this)}/>
                    
                    </ButtonToolbar>
                        </Card.Footer>
                    </Card>
                    
                </Form>
            </Fragment>
        )
    }
}
function validate(values) {
    const errors = {};

    if (!values.address1) {
        errors.address1 = "Street Address is required!";
    }
    if (!values.city) {
        errors.city = "City is required!";
    }
    if (!values.country) {
        errors.country = "Country is required!";
    }
    if (!values.postalCode) {
        errors.postalCode = "Postal or Zip Code is required!";
    }
    if (!values.startDate) {
        errors.startDate = "Enter date moved in!";
    }

    return errors;
}

function mapStateToProps(state) {
    const formValues = state.form.ReportAddressChangeForm;
    let selCountry = "";
    if (formValues.values && formValues.values.country) {
        selCountry = formValues.values.country;
    }
    return {
        sel_country: selCountry,
    }
}

export const CommonPersonalInfoAddress = reduxForm({
    validate,
    form: "ReportAddressChangeForm",
    initialValues: {
        country: "US"
    }
})(
    connect(mapStateToProps, { showSuccessGrowl, showErrorGrowl })(CommonPersonalInfoAddressComponent)
);

CommonPersonalInfoAddress.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};