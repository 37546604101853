import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectBlackmailDetails extends Component {
    getData() {
        return this.props.blackmails.map((blackMail) => {
            return <tr key={blackMail.id}>
                <td>{blackMail.namesInvolved}</td>
                <td>{blackMail.contact}</td>
                <td>{blackMail.typeOfInfoSought}</td>
                <td>{blackMail.contactMethod?blackMail.contactMethod.name:''}</td>
                <td>{blackMail.natureOfIncident?blackMail.natureOfIncident:''}</td>
                <td>{blackMail.background}</td>
            </tr>
        })
    }
    render() {
        const {blackmails} = this.props;

        if (!blackmails || blackmails.length === 0) {
            return <Alert variant="danger">No Blackmails!</Alert>
        }
        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Names Involved</th>
                        <th>Contact Information</th>
                        <th>Information Sought</th>
                        <th>Contact Method</th>
                        <th>Incident Type</th>
                        <th>Background</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getData()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}
