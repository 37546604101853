import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectMaritalStatusChange extends Component {
    getMarriedData() {
        return this.props.maritalChanges.map((maritalStatusChange) => {
            return <tr key={maritalStatusChange.id}>
                <td>{maritalStatusChange.firstName}</td>
                <td>{maritalStatusChange.lastName}</td>
                <td>{maritalStatusChange.spouseCitizenship}</td>
                <td>{maritalStatusChange.spouseDob}</td>
                <td>{maritalStatusChange.spousePlaceOfBirth}</td>
            </tr>
        })
    }
    getDivorcedData() {
        return this.props.maritalChanges.map((maritalStatusChange) => {
            return <tr key={maritalStatusChange.id}>
                <td>{maritalStatusChange.reasonInvalidated.displayName}</td>
                <td>{maritalStatusChange.dateInvalidated}</td>
            </tr>
        })
    }

    render() {
        const {maritalChanges} = this.props;

        if (!maritalChanges || maritalChanges.length === 0) {
            return <Alert variant="danger">No marital status changes!</Alert>
        }
        console.log('Marital changes are ', maritalChanges)
        let isMarriage = true;
        if (maritalChanges[0].dateInvalidated){
            isMarriage = false;
        }
        return (
            <Fragment>
                <Table>
                    <thead>
                    {isMarriage &&
                    <tr>
                        <th>Spouse First Name</th>
                        <th>Spouse Last Name</th>
                        <th>Spouse Citizenship</th>
                        <th>Spouse DOB</th>
                        <th>Spouse Place of Birth</th>
                    </tr>
                    }
                    {!isMarriage &&
                    <tr>
                        <th>Reason</th>
                        <th>End Date</th>
                    </tr>
                    }
                    </thead>
                    <tbody>
                    {isMarriage && this.getMarriedData()}
                    {!isMarriage && this.getDivorcedData()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}
