import React, {Component, Fragment} from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";

export class ReportableForeignContact extends Component {
    render() {
        const {contact} = this.props;

        return (
            <Fragment>
                <Form.Row>
                    <Form.Group as={Col} md={4} controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control plaintext readOnly value={contact.firstName}/>
                    </Form.Group>
                    <Form.Group as={Col} md={4} controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control plaintext readOnly value={contact.lastName}/>
                    </Form.Group>
                    <Form.Group as={Col} md={4} controlId="citizenCountry">
                        <Form.Label>Citizenship Country</Form.Label>
                        <Form.Control plaintext readOnly value={contact.citizenCountry}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md={6} controlId="placeOfContact">
                        <Form.Label>Place of Contact</Form.Label>
                        <Form.Control plaintext readOnly value={contact.placeOfContact}/>
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="contactReason">
                        <Form.Label>Contact Reason</Form.Label>
                        <Form.Control plaintext readOnly value={contact.contactReason}/>
                    </Form.Group>
                </Form.Row>
                <Form.Group>
                    <Form.Label>Is a Relative?</Form.Label>
                    <Form.Control plaintext readOnly value={contact.relative ? "Yes" : "No"}/>
                </Form.Group>
            </Fragment>
        )
    }
}

ReportableForeignContact.propTypes = {
    contact: PropTypes.object.isRequired
};