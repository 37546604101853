import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {Formik} from "formik";
import * as Yup from "yup";

class AddLodgingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    open = () => {
        this.props.updateParentStateWhenSubmitting(true);
        this.setState({showModal: true});
    }

    close = () => {
        this.props.updateParentStateWhenSubmitting(false);
        this.setState({
            showModal: false
        });
    }

    onSubmit = (values) => {
        if (values.noLodge) {
            values.name = ""
            values.address = "";
            values.city = "";
            values.countryInfo = "";
        }

        this.props.onAddLodge(values);
        this.close()
    };

    render() {
        const {showModal} = this.state;

        return (
            <Fragment>
                <Button onClick={this.open} variant="primary">Add Lodging Information</Button>

                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Lodging Information</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            name: "",
                            address: "",
                            city: "",
                            country: "",
                            contactInfo: "",
                            noLodge: false
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().when("noLodge", {
                                is: false,
                                then: (schema) => schema.required("Please enter an establishment name")
                            }),
                            address: Yup.string().when("noLodge", {
                                is: false,
                                then: (schema) => schema.required("Please enter an address")
                            }),
                            city: Yup.string().when("noLodge", {
                                is: false,
                                then: (schema) => schema.required("Please enter the city")
                            }),
                            country: Yup.string().required("Please enter the country"),
                            contactInfo: Yup.string().when("noLodge", {
                                is: false,
                                then: (schema) => schema.required("Please enter contact information")
                            }),
                            noLodge: Yup.boolean()
                        })}>
                        {({
                              isSubmitting,
                              values,
                              handleChange,
                              setFieldValue,
                              setFieldTouched,
                              handleSubmit,
                              touched,
                              errors,
                              handleBlur
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Establishment Name</Form.Label>
                                            <Form.Control required type="text" name="name" placeholder="Enter Name"
                                                          onChange={handleChange} onBlur={handleBlur}
                                                          isInvalid={touched.name && !!errors.name}
                                                          value={values.name} disabled={values.noLodge}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Establishment Address</Form.Label>
                                            <Form.Control required type="text" name="address"
                                                          placeholder="Enter Address" onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.address && !!errors.address}
                                                          value={values.address} disabled={values.noLodge}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.address}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control required type="text" name="city" placeholder="Enter City"
                                                          onChange={handleChange} onBlur={handleBlur}
                                                          isInvalid={touched.city && !!errors.city}
                                                          value={values.address} disabled={values.noLodge}/>
                                            <Form.Control.Feedback type="invalid">

                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control required type="text" name="country"
                                                          placeholder="Enter Country"
                                                          onChange={handleChange} onBlur={handleBlur}
                                                          isInvalid={touched.country && !!errors.country}
                                                          value={values.country}/>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.country}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Contact</Form.Label>
                                            <Form.Control required type="text" name="contactInfo"
                                                          placeholder="Enter email or phone number"
                                                          onChange={handleChange} onBlur={handleBlur}
                                                          isInvalid={touched.contactInfo && !!errors.contactInfo}
                                                          value={values.contactInfo} disabled={values.noLodge}/>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contactInfo}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group>
                                        <Form.Check name="noLodge" type="checkbox"
                                                    label="Did Not Lodge In This Country"
                                                    onChange={handleChange} onBlur={handleBlur}
                                                    value={values.noLodge}
                                        />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit">Add Lodge</Button>
                                        <Button variant="danger" onClick={this.close}>Cancel</Button>
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

AddLodgingModal.propTypes = {
    onAddLodge: PropTypes.func.isRequired,
    updateParentStateWhenSubmitting: PropTypes.func.isRequired
};

export default AddLodgingModal;