import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {   Field, reduxForm } from "redux-form";
import Form from "react-bootstrap/Form";
import BSForm from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { FieldRBDatePicker, FieldRBDropzone, FieldRBInput, FieldRBRadio, FieldRBSelectWithKeys } from "../../../../components/redux_form/field_components_rb";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";
import { CommonUtils } from "../../../../actions/common_utils";
import { getInvalidationTypesByReportableType } from "../../../../actions/fieldValue_actions";
 
// import * as Yup from "yup";
// import {Field, Formik} from "formik";

class CommonReportMaritalStatusComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marriage: true,
            availInvalidateReasons: [{id: "none", name: "Select Reason..."}]
        }
    }
    componentDidMount() {
        getInvalidationTypesByReportableType("PERSONNEL_INFO_MARITAL_STATUS").then((invalidationTypes) => {
            const reasons = [{id: "none", name: "Select Reason..."}];

            invalidationTypes.forEach((type) => {
                reasons.push({
                    id: type.id,
                    name: type.displayName
                });
            });

            this.setState({
                availInvalidateReasons: reasons
            });
        });
    }
    onSubmit(values) {
        this.props.onSubmit(values);
    }


    handleCancel = () => {
        this.props.onCancel();
    }

    onFileChange = (files) => {
        if (files[0]) {
            this.props.change("filename", files[0].name);
            this.props.change("filetype", files[0].type);
            this.props.change("previewUrl", files[0].preview);
        }
    }

    render() {
        const { handleSubmit, isMarriage } = this.props;
        const {availInvalidateReasons} = this.state; 
        return (
            <Fragment>

{/* <Formik
                        initialValues={{
                            "status": "marriage",
                            "reasonInvalidated": "none",
                            "spouseCitizenship":"United States"
                        }}
                        enableReinitialize={true} 
                        onSubmit={this.onSubmit.bind(this)}
                        validationSchema={
                            Yup.object().shape({
                                firstName: Yup.string().when("status", {
                                    is: "marriage",
                                    then: (schema) => schema.required("Enter first name")
                                }),
                                lastName: Yup.string().when("status", {
                                    is: "marriage",
                                    then: (schema) => schema.required("Enter last name")
                                }),
                                startDate: Yup.string().when("status", {
                                    is: "marriage",
                                    then: (schema) => schema.required("Enter marriage date")
                                }),
                                spouseCitizenship: Yup.string().when("status", {
                                    is: "marriage",
                                    then: (schema) => schema.required("Select a Country of Citizenship for Spouse")
                                }),
                                dateInvalidated: Yup.string().when("status", {
                                    is: "divorce",
                                    then: (schema) => schema.required("Enter divorce date")
                                }),
                                reasonInvalidated: Yup.string().when("status", {
                                    is: "divorce",
                                    then: (schema) => schema.required("Reason is required!")
                                }),
                            }) }
                    >
                        {({ isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit,   errors, handleBlur }) => (


                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Report Foreign Voting Information</Card.Header>
                                    <Card.Body>
                            <BSForm.Group>
                                <BSForm.Label>Type of Marital Status Change</BSForm.Label>
                                <Form.Group as={Col} md="6">
                                                <Form.Label>What was the election for</Form.Label>
                                                <Form.Control required type="text" name={'electionDescription'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder={'Description of the elections purpose'}
                                                    isInvalid={touched.electionDescription && !!errors.electionDescription}
                                                    value={values.electionDescription}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.electionDescription}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                <div className="row col-sm-12 m-0 p-0">
                                    <Field name="status" label="Marriage" value="marriage"
                                        component={FieldRBRadio} type="radio" inline />
                                    <Field name="status" label="Divorce/Death" value="divorce"
                                        component={FieldRBRadio} type="radio" inline />
                                </div>

                            </BSForm.Group>
                            {values.isMarriage &&
                                <Fragment>

                                    <div className="row col-sm-12 m-0 p-0">
                                        <div className="col-sm-4">
                                            <Field name="firstName" component={FieldRBInput} label="First Name" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="lastName" component={FieldRBInput} label="Last Name" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="startDate" component={FieldRBDatePicker} label="Date of Marriage" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="spouseDob" component={FieldRBDatePicker} label="Spouse Date of Birth" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="spousePlaceOfBirth" component={FieldRBInput} label="Spouse Place of Birth" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="spouseCitizenship" component={FieldRBSelectWithKeys} label="Spouse Citizenship Country" required horizontal
                                                options={CommonUtils.getAvailCountries()} />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="file" component={FieldRBDropzone} label="Spouse Citizenship Document" onChange={this.onFileChange}
                                                multiple={false} placeHolder={'Upload Passport/Naturalization Certificate or other proof of citizenship'} horizontal />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {!values.isMarriage &&
                                <Fragment>
                                    <div className="col-lg-12 row m-0 p-0">
                                        <div className="col-sm-6">
                                            <Field name="dateInvalidated" component={FieldRBDatePicker} label="End Date" required
                                                horizontal />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field name="reasonInvalidated" label="Reason" component={FieldRBSelectWithKeys}
                                                options={availInvalidateReasons} required horizontal />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field name="file" component={FieldRBDropzone} label="Divorce Document" onChange={this.onFileChange}
                                                multiple={false} placeHolder={'Upload document supporting divorce'} horizontal />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </Card.Body>
                                    <Card.Footer>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Submit</Button>
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Foreign Voting Form"
                                                handleDeleteCallback={this.handleCancel.bind(this)} />
                                        </ButtonToolbar>
                                    </Card.Footer>
                                </Card>
                            </Form>


                        )}
                    </Formik> */}



                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Card>
                        <Card.Header>Marital Status Information</Card.Header>
                        <Card.Body>
                            <BSForm.Group>
                                <BSForm.Label>Type of Marital Status Change</BSForm.Label>

                                <div className="row col-sm-12 m-0 p-0">
                                    <Field name="status" label="Marriage" value="marriage"
                                        component={FieldRBRadio} type="radio" inline />
                                    <Field name="status" label="Divorce/Death" value="divorce"
                                        component={FieldRBRadio} type="radio" inline />
                                </div>

                            </BSForm.Group>
                            {isMarriage &&
                                <Fragment>

                                    <div className="row col-sm-12 m-0 p-0">
                                        <div className="col-sm-4">
                                            <Field name="firstName" component={FieldRBInput} label="First Name" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="lastName" component={FieldRBInput} label="Last Name" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="startDate" component={FieldRBDatePicker} label="Date of Marriage" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="spouseDob" component={FieldRBDatePicker} label="Spouse Date of Birth" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="spousePlaceOfBirth" component={FieldRBInput} label="Spouse Place of Birth" required horizontal />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="spouseCitizenship" component={FieldRBSelectWithKeys} label="Spouse Citizenship Country" required horizontal
                                                options={CommonUtils.getAvailCountries()} />
                                        </div>

                                        <div className="col-sm-4">
                                            <Field name="file" component={FieldRBDropzone} label="Spouse Citizenship Document" onChange={this.onFileChange}
                                                multiple={false} placeHolder={'Upload Passport/Naturalization Certificate or other proof of citizenship'} horizontal />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {!isMarriage &&
                                <Fragment>
                                    <div className="col-lg-12 row m-0 p-0">
                                        <div className="col-sm-6">
                                            <Field name="dateInvalidated" component={FieldRBDatePicker} label="End Date" required
                                                horizontal />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field name="reasonInvalidated" label="Reason" component={FieldRBSelectWithKeys}
                                                options={availInvalidateReasons} required horizontal />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field name="file" component={FieldRBDropzone} label="Divorce Document" onChange={this.onFileChange}
                                                multiple={false} placeHolder={'Upload document supporting divorce'} horizontal />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </Card.Body>
                        <Card.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit">Submit</Button>
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Marital Status Form"
                                    handleDeleteCallback={this.handleCancel} />
                            </ButtonToolbar>
                        </Card.Footer>
                    </Card>
                </Form>
            </Fragment>
        )
    }
}
function validate(values) {
    const errors = {};

    if (values.status && values.status === "marriage") {
        if (!values.firstName) {
            errors.firstName = "Enter first name";
        }
        if (!values.lastName) {
            errors.lastName = "Enter last name";
        }
        if (!values.startDate) {
            errors.startDate = "Enter marriage date";
        }
        if (!values.spouseCitizenship) {
            errors.spouseCitizenship = "Select a Country of Citizenship for Spouse";
        }
    } else {
        if (!values.dateInvalidated) {
            errors.dateInvalidated = "Enter divorce date";
        }
        if (!values.reasonInvalidated || values.reasonInvalidated === "none") {
            errors.reasonInvalidated = "Reason is required!";
        }
    }
    // if (!values.file || values.file.size < 1) {
    //     errors.file = "You must upload a supporting document!";
    // }

    return errors;
}

function mapStateToProps({ form }) {
    const formValues = form.ReportMaritalStatusChangeForm;
    const retProps = {};
    if (formValues.values) {
        const { values } = formValues;
        // !!(bool) is the same as bool ? true : false;
        retProps.isMarriage = !!(values.status && values.status === "marriage");
    }
    return retProps;
}


export const CommonReportMaritalStatus = reduxForm({
    validate,
    form: "ReportMaritalStatusChangeForm",
    initialValues: {
        status: "marriage",
        reasonInvalidated: "none",
        spouseCitizenship:"United States"
    }
})(
    connect(mapStateToProps, { showSuccessGrowl, showErrorGrowl })(CommonReportMaritalStatusComponent)
);

CommonReportMaritalStatus.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};