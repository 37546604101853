import React, {Component} from "react";
import {Button, Card, Col, Form} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {connect} from "react-redux";
import {reportOnOthers} from "../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../actions/msg_actions";
import {VERIFIED_EMAIL} from "../../../actions/auth_actions";
import {Link} from "react-router-dom";
import DeleteConfirmationModal from "../../../components/delete_confirm_modal";

class ReportOnOthersComp extends Component {
    constructor(props) {
        super(props);

        this.state={
            isLoading: false,
        }

    }

    onSubmit = (values) => {
        this.setState({isLoading: true});
        this.props.reportOnOthers(values, () => {
            this.setState({isLoading: false});
            this.props.showSuccessGrowl("Your information is reported", "Thank you for reporting. The security officer(s) will be notified of this");
            this.props.history.push("/reporting/subject");
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        })
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <div className="reporting_bg">
                <div className="container">
                    <div className="heading_container">
                        <h3>Report On Others</h3>
                    </div>
                    <Formik
                        initialValues={{
                            reportedPersonFullName: "",
                            reportedPersonEmail: "",
                            reportedPersonEmployer: "",
                            reportedPersonPhone: "",
                            reportedPersonDepartment: "",
                            reportSummary: "",
                            reportDetails: "",
                            anonymous: false
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit.bind(this)}
                        validationSchema={
                            Yup.object().shape({
                                reportedPersonEmail: Yup.string().required("Enter the person's email address"),
                                reportedPersonFullName: Yup.string().required("Enter the person's full numae"),
                                reportSummary: Yup.string().required('Enter a brief summary of the incident'),
                            })
                        }
                    >
                        {({ isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur }) => (

                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Reporting on:</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" controlId={'reportedPersonFullName'}>
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control required type="text" name={'reportedPersonFullName'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'Enter the full name of the person you are reporting on'}
                                                    isInvalid={touched.reportedPersonFullName && !!errors.reportedPersonFullName}
                                                    value={values.reportedPersonFullName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.reportedPersonFullName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId={'reportedPersonEmail'}>
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control required type="text" name={'reportedPersonEmail'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'Enter the email address of the person you are reporting on'}
                                                    isInvalid={touched.reportedPersonEmail && !!errors.reportedPersonEmail}
                                                    value={values.reportedPersonEmail}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.reportedPersonEmail}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId={'reportedPersonPhone'}>
                                                <Form.Label>Phone Number</Form.Label>
                                                <Form.Control type="text" name={'reportedPersonPhone'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'Phone number of the person if available'}
                                                    isInvalid={touched.reportedPersonPhone && !!errors.reportedPersonPhone}
                                                    value={values.reportedPersonPhone}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.reportedPersonPhone}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'reportedPersonEmployer'}>
                                                <Form.Label>Employer</Form.Label>
                                                <Form.Control type="text" name={'reportedPersonEmployer'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'Employer information if available'}
                                                    isInvalid={touched.reportedPersonEmployer && !!errors.reportedPersonEmployer}
                                                    value={values.reportedPersonEmployer}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.reportedPersonEmployer}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId={'reportedPersonDepartment'}>
                                                <Form.Label>Department</Form.Label>
                                                <Form.Control type="text" name={'reportedPersonDepartment'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'Department if available'}
                                                    isInvalid={touched.reportedPersonDepartment && !!errors.reportedPersonDepartment}
                                                    value={values.reportedPersonDepartment}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.reportedPersonDepartment}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'reportSummary'}>
                                                <Form.Label>Summary</Form.Label>
                                                <Form.Control required type="text" name={'reportSummary'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'Brief summary of the report'}
                                                    isInvalid={touched.reportSummary && !!errors.reportSummary}
                                                    value={values.reportSummary}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.reportSummary}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId={'reportDetails'}>
                                                <Form.Label>Details</Form.Label>
                                                <Form.Control required as="textarea" name={'reportDetails'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'Please enter all relevant details, dates etc'}
                                                    isInvalid={touched.reportDetails && !!errors.reportDetails}
                                                    value={values.reportDetails}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.reportDetails}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'anonymous'}>
                                                <Form.Check
                                                    name={'anonymous'}
                                                    label={'Report Anonymously?'}
                                                    onChange={(event) => {
                                                        setFieldValue('anonymous', event.target.checked);
                                                        handleChange(event);
                                                    }}
                                                    isInvalid={touched.anonymous && !!errors.anonymous}
                                                    id={'anonymous'}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.anonymous}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                    <Card.Footer>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                                            {/* <Link to="/reporting/subject" className="btn btn-danger">Cancel</Link> */}
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report On Others"
                                                handleDeleteCallback={this.handleCancel.bind(this)} />
                                        </ButtonToolbar>
                                    </Card.Footer>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>
        );
    }
}

export const ReportOnOthers = connect(null, {reportOnOthers, showSuccessGrowl, showErrorGrowl})(ReportOnOthersComp);