import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { FieldRBDatePicker, FieldRBInput } from "../../../../components/redux_form/field_components_rb";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";

class CommonPersonalInfoArrestComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) { 
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Card>
                        <Card.Header>Arrest Information</Card.Header>
                        <Card.Body>
                            <div className="row col-lg-12 m-0 p-0">
                                <div className="col-lg-6">
                                    <Field name="incidentDate" component={FieldRBDatePicker} label="Date Incident Occurred" required horizontal />
                                </div>

                                <div className="col-lg-6">
                                    <Field name="incidentLocation" component={FieldRBInput} label="Location Incident Occurred" required horizontal />
                                </div>

                                <div className="col-lg-6">
                                    <Field name="charges" component={FieldRBInput} label="Charges or Circumstances" required horizontal />
                                </div>

                                <div className="col-lg-6">
                                    <Field name="disposition" component={FieldRBInput} label="Disposition (Case Status)" required horizontal />
                                </div>

                                <div className="col-lg-6">
                                    <Field name="startDate" component={FieldRBDatePicker} label="Case Start Date" required horizontal />
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit">Submit</Button>
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Arrest Form"
                                    handleDeleteCallback={this.handleCancel.bind(this)} />
                            </ButtonToolbar>
                        </Card.Footer>
                    </Card>
                </Form>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.incidentDate) {
        errors.incidentDate = "Incident Date is required!";
    }
    if (!values.incidentLocation) {
        errors.incidentLocation = "Incident Location is required!";
    }
    if (!values.charges) {
        errors.charges = "Charges is required!";
    }
    if (!values.disposition) {
        errors.disposition = "Disposition is required!";
    }
    if (!values.startDate) {
        errors.startDate = "Enter Start Date!";
    }
    return errors;
}

export const CommonPersonalInfoArrest = reduxForm({
    validate,
    form: "ReportArrestChangeForm"
})(
    connect(null, { showSuccessGrowl, showErrorGrowl })(CommonPersonalInfoArrestComponent)
);

CommonPersonalInfoArrest.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};