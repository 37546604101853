import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {ButtonToolbar} from "react-bootstrap";
import {getSubjectForSelfReporting} from "../../../../actions/subject_actions";
import {CommonUtils, DateUtils} from "../../../../actions/common_utils";
import {SubjectReportableUpdateModal} from "../subject_reportable_update_modal";

export class ReportingSubjectAddressDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subject: null
        }
    }

    async componentDidMount() {
        await this.refreshSubject();
    }

    refreshSubject = async () => {
        this.setState({
            subject: null
        });

        const subject = await getSubjectForSelfReporting();

        this.setState({
            subject
        });
    }

    getAddressHistory() {
        return this.state.subject.pastResidences.map((address) => {
            return <tr key={address.id}>
                <td>{CommonUtils.appendValuesIfNotEmpty([address.address1, address.address2, address.city, address.stateOrProvince, address.country])} {address.postalCode}</td>
                <td>{this.getStartDate(address)}</td>
                <td>{address.dateInvalidated ? DateUtils.getDateString(address.dateInvalidated) : "Present"}</td>
                <td>
                    <ButtonToolbar className="p-0 mb-0 float-right" style={{flexWrap: "nowrap"}}>
                        {!address.dateInvalidated &&
                        <SubjectReportableUpdateModal reportable={address}
                                                      reportableTypeString={"Address"}
                                                      onUpdate={this.refreshSubject}/>
                        }
                    </ButtonToolbar>
                </td>
            </tr>
        })
    }

    getStartDate = (residence) => {
        if (residence.startDate != null){
            return DateUtils.getDateString(residence.startDate);
        } else if (residence.dateReported != null){
            return DateUtils.getDateString(residence.dateReported);
        } else {
            return "Unknown";
        }
    }

    render() {
        const {subject} = this.state;

        if (!subject || !subject.currentResidence) {
            return <Alert variant="danger">No Current Address!</Alert>
        }

        return (
            <Fragment>
                <h4>Current Residence
                    (From {this.getStartDate(subject.currentResidence) })</h4>
                <address>
                    {subject.currentResidence.address1}<br/>
                    {subject.currentResidence.address2}<br/>
                    {CommonUtils.appendValuesIfNotEmpty([subject.currentResidence.city, subject.currentResidence.stateOrProvince, subject.currentResidence.country])} {subject.currentResidence.postalCode}
                </address>

                <h4>Past Residences</h4>
                <Table>
                    <thead>
                    <tr>
                        <th>Address</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {this.getAddressHistory()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}