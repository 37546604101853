import React, {Component, Fragment, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import {connect} from "react-redux";
import {REPORTING_PASS_CODE, VERIFIED_EMAIL} from "../../../actions/auth_actions";
import {reportingSecondFactor} from "../../../actions/subject_actions";


import foreigninfo from "../../../assets/images/icons/Foreign_Information.png";
import personalinfo from "../../../assets/images/icons/persona_Info.png";
import visiticon from "../../../assets/images/icons/Visits.png";
import updateicon from "../../../assets/images/icons/update_information.png";
import detailsicon from "../../../assets/images/icons/details.png";
import reportableOnOthersIcon from "../../../assets/images/icons/reportable_on_others.png";
import outstandingtasks from "../../../assets/images/icons/Outstanding_tasks.png";
import {CommonUtils} from "../../../actions/common_utils";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/cjs/Button";


const personalinformation = props => (
    <Tooltip {...props}>Covered individuals' responsibility is to recognize and avoid their behavior and activities that
        may affect their national security ability.</Tooltip>
);

const foreignInformationTooltip = props => (
    <Tooltip {...props}>Covered individuals' responsibility is to report foreign exposure and activities that
        may affect their national security ability.</Tooltip>
);

const reportOnOthersTooltip = props => (
    <Tooltip {...props}>Covered individuals' responsibility is to report on insider threat that
        may affect national security.</Tooltip>
);

const visits = props => (
    <Tooltip {...props}>Classified/Unclassified visit requests to cleared facilities</Tooltip>
);

const details = props => (
    <Tooltip {...props}>Previously Reported Details and Personal Information</Tooltip>
);

const reviewInformationTooltip = props => (
    <Tooltip {...props}>Review prior reported information and make changes if needed</Tooltip>
);

class ReportingSubjectMenuComponent extends Component {
    componentDidMount() {
        let emailReq = {
            emailAddress: sessionStorage.getItem(VERIFIED_EMAIL),
            passCode: sessionStorage.getItem(REPORTING_PASS_CODE)
        }
        this.props.reportingSecondFactor(emailReq);
    }


    render() {
        let {subject} = this.props;

        return (
            <div className="reporting_bg">
                <Fragment>
                    <div className="reporting_screen1 container">
                        <div className="heading_container">
                            <h3>Report Subject Changes</h3>
                        </div>
                        
                        <Row className="justify-center m-0">
                            <Col lg={3} className="mb-5">
                                <div className="border-highlt">
                                    <Link to={"/reporting/subject/reportingPersonalInformation"}>
                                        <OverlayTrigger placement="bottom" overlay={personalinformation}
                                                        className="tooltip">
                                            <div className="h_icons">
                                                <img src={personalinfo} className="f_icons"></img>

                                                <div className="tab_name">
                                                    <h5>Personal Information</h5>
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    </Link>
                                </div>
                            </Col>

                            <Col lg={3} className="mb-5">
                                <div className="border-highlt">
                                    <Link to={"/reporting/subject/reportingForeignInformation"}>
                                        <OverlayTrigger placement="bottom" overlay={foreignInformationTooltip()}
                                                        className="tooltip">
                                        <div className="h_icons">
                                            <img src={foreigninfo} className="f_icons"></img>
                                            <div className="tab_name">
                                                <h5>Foreign Information</h5>
                                            </div>
                                        </div>
                                        </OverlayTrigger>
                                    </Link>
                                </div>
                            </Col>

                            <Col lg={3} className="mb-5">
                                <div className="border-highlt">
                                    <Link to={"/reporting/subject/visits"}>
                                        <OverlayTrigger placement="bottom" overlay={visits} className="tooltip">
                                            <div className="h_icons">
                                                <img src={visiticon} className="f_icons"></img>
                                                <div className="tab_name">
                                                    <h5><Link to={"/reporting/subject/visits"}>Visits</Link></h5>
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    </Link>
                                </div>
                            </Col>

                            <Col lg={3} className="mb-5">
                                <div className="border-highlt">
                                    <Link to={"/reporting/subject/reportOnOthers"}>
                                        <OverlayTrigger placement="bottom" overlay={reportOnOthersTooltip} className="tooltip">
                                            <div className="h_icons">
                                                <img src={reportableOnOthersIcon} className="f_icons"></img>
                                                <div className="tab_name">
                                                    <h5>Report on Others</h5>
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    </Link>
                                </div>
                            </Col>

                            <Col lg={3} className="mb-5">
                                <div className="border-highlt">
                                    <Link to={"/reporting/subject/reportingPriorInformation"}>
                                        <OverlayTrigger placement="bottom" overlay={details} className="tooltip">
                                            <div className="h_icons">
                                                <img src={detailsicon} className="f_icons"></img>
                                                <div className="tab_name">
                                                    <h5>Details and Prior Reported Information</h5>
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    </Link>
                                </div>
                            </Col>

                            <Col lg={3} className="mb-5">
                                <div className="border-highlt">
                                    <Link to={"/reporting/subject/taskList"}>
                                        <OverlayTrigger placement="bottom" overlay={details} className="tooltip">
                                            <div className="h_icons">
                                                <img src={outstandingtasks} className="f_icons"></img>
                                                <div className="tab_name">
                                                    <h5>Outstanding tasks</h5>
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <div className="back_btn_block">
                            <Button className="btn btn-back" onClick={()=>{
                                CommonUtils.handleExit();
                                this.props.history.push('/reporting/subject/emailCheck')}
                            }>Exit</Button>
                        </div>
                    </div>
                </Fragment>


            </div>


        )
    }
}

function mapStateToProps({subject_for_self_reporting}) {
    return {
        subject: subject_for_self_reporting
    }

}

export const ReportingSubjectMenu = connect(mapStateToProps, {reportingSecondFactor})(ReportingSubjectMenuComponent);