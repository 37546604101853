import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {checkEmail} from "../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../actions/msg_actions";
import {Field, reduxForm} from "redux-form";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import {FieldRBDatePicker, FieldRBInput} from "../../../components/redux_form/field_components_rb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {VERIFIED_EMAIL, REPORTING_PASS_CODE} from "../../../actions/auth_actions";
import {EMAIL_VALIDATION_STR} from "../../../actions/common_utils";

class ReportEmailVerifyComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        this.props.checkEmail(values).then((response) => {
            // email is valid.. if the device is recognized then go to the reporting page
            console.log('Email valid?', response.emailValid);
            if (response.emailValid){
                sessionStorage.setItem(VERIFIED_EMAIL, values.emailAddress);
                if (response.passCode){
                    sessionStorage.setItem(REPORTING_PASS_CODE, response.passCode);
                    this.props.history.push("/reporting/subject/taskList/Outstanding");
                } else {
                    // if not then go to the second factor page
                    this.props.history.push("/reporting/subject/emailCheck/passCodeCheck");
                }
            } else {
                this.props.showErrorGrowl("Invalid email");
            }
            console.log(response);
        }).catch((error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Email Not Found", "Please check the identity information.")
            }
        });
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <Fragment>

                <div className="container-fluid self_reporting">
                    <div className="col-lg-6 margin-auto top-20">
                    <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <div className="heading_container">
                        <h3>Self Reporting</h3>
                        </div>
                    
                    <Card className="col-lg-10 p-0 m-auto">
                        <Card.Header>Identifying Information</Card.Header>
                        <Card.Body>
                            <div className="col-lg-12 p-0">
                            <Field name="emailAddress" component={FieldRBInput} label="Email Address" required
                                   horizontal/>
                            </div>

                          
                           
                        </Card.Body>

                        <Card.Footer>
                        <ButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Link to="/" className="btn btn-danger">Cancel</Link>
                    </ButtonToolbar>
                        </Card.Footer>

                       
                    </Card>
                  
                </Form>
                    </div>
                </div>
               
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.emailAddress) {
        errors.emailAddress = "Enter email address";
    } else if (!EMAIL_VALIDATION_STR.test(values.emailAddress)) {
        errors.emailAddress = "Enter a valid email address";
    }

    return errors;
}

export const ReportEmailVerify = reduxForm({
    validate,
    form: "ReportEmailVerifyForm"
})(
    connect(null, {checkEmail, showSuccessGrowl, showErrorGrowl})(ReportEmailVerifyComponent)
);