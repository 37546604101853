import React, { Component, Fragment } from "react";
import { connect } from "react-redux";  
import ButtonToolbar from "react-bootstrap/ButtonToolbar"; 
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";
import { Button, Card, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup"; 

class CommonReportAlcoholDrugComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) { 
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <Formik
            initialValues={{
                "reason": "",
                "dates": "",
                "treatmentProviderName": "",
                "treatmentProvideContactInformation": "",
            }}
            enableReinitialize={true}
            onSubmit={this.onSubmit.bind(this)}
            validationSchema={
                Yup.object().shape({
                    reason: Yup.string().required('Enter the reason'),
                    dates: Yup.string().required('Enter the dates of treatment'),
                    treatmentProviderName: Yup.string().required('Enter treatment provider name'),
                    treatmentProvideContactInformation: Yup.string().required('Provide contact information for the provider'),
                })
            }
        >
            {({ isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Card>
                        <Card.Header>Add Treatment Information </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId={'reason'}>
                                    <Form.Label>Treatment reason</Form.Label>
                                    <Form.Control required type="text" name={'reason'}
                                        onChange={handleChange}
                                        onBlur={handleBlur} placeholder={'Enter the reason for your treatment'}
                                        isInvalid={touched.reason && !!errors.reason}
                                        value={values.reason}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.reason}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId={'dates'}>
                                    <Form.Label>Dates</Form.Label>
                                    <Form.Control required type="text" name={'dates'} onChange={handleChange}
                                        onBlur={handleBlur} placeholder={'Enter date(s) of treatment or range'}
                                        isInvalid={touched.dates && !!errors.dates}
                                        value={values.dates}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.dates}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId={'treatmentProviderName'}>
                                    <Form.Label>Treatment provider name</Form.Label>
                                    <Form.Control required type="text" name={'treatmentProviderName'}
                                        onChange={handleChange}
                                        onBlur={handleBlur} placeholder={'Treatment Provider Name'}
                                        isInvalid={touched.treatmentProviderName && !!errors.treatmentProviderName}
                                        value={values.treatmentProviderName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.treatmentProviderName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId={'treatmentProvideContactInformation'}>
                                    <Form.Label>Treatment Provider Contact</Form.Label>
                                    <Form.Control required type="text" name={'treatmentProvideContactInformation'} onChange={handleChange}
                                        onBlur={handleBlur} placeholder={'Enter phone number and address of the treatment provider'}
                                        isInvalid={touched.treatmentProvideContactInformation && !!errors.treatmentProvideContactInformation}
                                        value={values.treatmentProvideContactInformation}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.treatmentProvideContactInformation}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                        </Card.Body>
                        <Card.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Alcohol & Drug Treatment"
                                    handleDeleteCallback={this.handleCancel.bind(this)} />
                            </ButtonToolbar>
                        </Card.Footer>
                    </Card>
                </Form>
            )}
        </Formik>
        )
    }
}
 
export const CommonReportAlcoholDrug = connect(null, { showSuccessGrowl, showErrorGrowl })(CommonReportAlcoholDrugComponent)
 