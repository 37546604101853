import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectAddressChangeDetails extends Component {
    getaddressChanges() {
        return this.props.addressChanges.map((addressChange) => {
            return <tr key={addressChange.id}>
                <td>{addressChange.address1}</td>
                <td>{addressChange.address2}</td>
                <td>{addressChange.city}</td>
                <td>{addressChange.stateOrProvince}</td>
                <td>{addressChange.country}</td>
                <td>{addressChange.postalCode}</td>
                <td>{addressChange.current ? 'Yes' : 'No'}</td>
            </tr>
        })
    }

    render() {
        const {addressChanges} = this.props;

        if (!addressChanges || addressChanges.length === 0) {
            return <Alert variant="danger">No address changes!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Address 1</th>
                        <th>Address 2</th>
                        <th>City</th>
                        <th>State/Province</th>
                        <th>Country</th>
                        <th>Postal Code</th>
                        <th>Is Current Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getaddressChanges()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}
