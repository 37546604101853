import React, {useCallback} from "react";
import {useEffect} from "react";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {useRef} from "react";

import CollapsibleCard from "../../../components/bootstrap_addons/collapsible-card";
import {SubjectForeignContactListDetails} from "../../org/dash/subject/details/subject_foreign_contact_ques_details";
import {SubjectForeignPropertyDetails} from "../../org/dash/subject/details/subject_foreign_property_details";
import {SubjectForeignBankDetails} from "../../org/dash/subject/details/subject_foreign_bank_details";
import {SubjectForeignVotingDetails} from "../../org/dash/subject/details/subject_foreign_voting_details";
import {SubjectForeignCitizenshipDetails} from "../../org/dash/subject/details/subject_foreign_citizenship_details";
import {SubjectForeignIdCardDetails} from "../../org/dash/subject/details/subject_foreign_id_card_details";
import {SubjectForeignTravelDetails} from "../../org/dash/subject/details/subject_foreign_travel_details";
import {SubjectForeignAdoptionDetails} from "../../org/dash/subject/details/subject_foreign_adoption_details";
import {SubjectFinancialIssueAnomaliesDetails} from "../../org/dash/subject/details/subject_financial_anomalies_details";
import {SubjectAlcoholDrugTreatmentDetails} from "../../org/dash/subject/details/subject_alcohol_drug_treatment_details";
import {SubjectMediaContactDetails} from "../../org/dash/subject/details/subject_media_contact_details";
import {SubjectCohabitantDetails} from "../../org/dash/subject/details/subject_cohabitant_details";
import {SubjectArrestDetails} from "../../org/dash/subject/details/subject_arrest_details";
import {SubjectForeignIntelligenceContactDetails} from "../../org/dash/subject/details/subject_foreign_intelligence_contact_details";
import {REPORTING_PASS_CODE, VERIFIED_EMAIL} from "../../../actions/auth_actions";
import {reportingSecondFactor} from "../../../actions/subject_actions";
import SubjectClearanceDetails from "../../org/dash/subject/details/subject_clearance_details";
import SubjectFingerprintDetails from "../../org/dash/subject/details/subject_fingerprinting_detail";
import SubjectBackgroundInformation from "../../org/dash/subject/details/subject_background_detail";
import SubjectEqipInformation from "../../org/dash/subject/details/subject_eqip_detail";
import SubjectAddressDetails from "../../org/dash/subject/details/subject_address_details";

import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {CommonUtils} from "../../../actions/common_utils";
import {ReportingSubjectCohabitantDetails} from "./priorInfo/reporting_subject_cohabitant_details";
import {ReportingSubjectArrestDetails} from "./priorInfo/reporting_subject_arrest_details";
import {ReportingSubjectAddressDetails} from "./priorInfo/reporting_subject_address_details";
import {ReportingSubjectForeignContactsDetails} from "./priorInfo/reporting_subject_foreign_contacts_details";
import {ReportingSubjectMediaContactDetails} from "./priorInfo/reporting_subject_media_contact_details";
import {SubjectForeignBusinessInvolvementDetail} from "./personalInfo/review_foreign_business_involvement";
import {ReportingSubjectForeignBusinessInvolvementsDetails} from "./priorInfo/reporting_subject_foreign_business_involvements_details";
import {ReportingSubjectForeignBankDetails} from "./priorInfo/reporting_subject_foreign_banks_details";
import {ReportingSubjectForeignPropertyDetails} from "./priorInfo/reporting_subject_foreign_property_details";
import {ReportingSubjectForeignCitizenshipDetails} from "./priorInfo/reporting_subject_foreign_citizenship_details";
import {ReportingSubjectForeignIdCardDetails} from "./priorInfo/reporting_subject_foreign_id_card_details";
import {ReportingSubjectMaritalDetails} from "./priorInfo/reporting_subject_marital_details";
import Button from "react-bootstrap/cjs/Button";


const ReportingPriorInformation = (props) => {
    let {subject} = props;

    const divRef = useRef(null);
    const top = () => {
        divRef.current.scroll({
            top: 0,
            behavior: "smooth"
        });
    };

//      useEffect(() => {
//       document.body.style.overflow = 'auto';
// }, []);
    return (
        <div className="reporting_bg">

            <>

                <div className="reporting_screen1 show_personal_info container-fluid">
                    <div className="heading_container">
                        <h3>Details and Prior Reported Information</h3>
                    </div>

                    <div className="col-lg-12 prior_information" id="prior_information" ref={divRef}>


                        <Tab.Container id="left-tabs" defaultActiveKey="Contacts">
                            <Row>
                                <Col sm={3} className="pr-0 left-nav-scroll">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="Contacts" onClick={(top)}>Foreign Contacts</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Properties" onClick={(top)}>Foreign
                                                Properties</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Foreign Businesses" onClick={(top)}>Foreign Businesses</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Banks" onClick={(top)}>Foreign Banks</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Voting" onClick={(top)}>Foreign Voting
                                                Details</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Citizenships" onClick={(top)}>Foreign
                                                Citizenships</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="id-cards" onClick={(top)}>Foreign ID/Cards</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Travels" onClick={(top)}>Foreign Travels</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Adoptions" onClick={(top)}>Foreign Adoptions</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Anomalies" onClick={(top)}>Financial Issues &
                                                Anomalies</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Alcohol" onClick={(top)}>Alcohol/Drug
                                                Treatments</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Media" onClick={(top)}>Media Contacts</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Address" onClick={(top)}>Address</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Marital Status" onClick={(top)}>Marital Status</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Cohabitants" onClick={(top)}>Cohabitants</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Arrests" onClick={(top)}>Arrests</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Intelligence" onClick={(top)}>Foreign Intelligence
                                                Contacts</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Clearance" onClick={(top)}>Clearance</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Fingerprinting"
                                                      onClick={(top)}>Fingerprinting</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Information" onClick={(top)}>Background
                                                Information</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="Eqip" onClick={(top)}>Eqip Information</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9} className="tab_right_height">
                                    <Tab.Content>

                                        <Tab.Pane eventKey="Contacts">
                                            <ReportingSubjectForeignContactsDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Properties">
                                            <ReportingSubjectForeignPropertyDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Foreign Businesses">
                                            <ReportingSubjectForeignBusinessInvolvementsDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Banks">
                                            <ReportingSubjectForeignBankDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Voting">
                                            <SubjectForeignVotingDetails foreignVotings={subject.foreignVotings}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Citizenships">
                                            <ReportingSubjectForeignCitizenshipDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="id-cards">
                                            <ReportingSubjectForeignIdCardDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Travels">
                                            <SubjectForeignTravelDetails foreignTravels={subject.foreignTravels}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Adoptions">
                                            <SubjectForeignAdoptionDetails
                                                foreignChildAdoptions={subject.foreignChildAdoptions}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Anomalies">
                                            <SubjectFinancialIssueAnomaliesDetails
                                                financialIssuesAndAnomalies={subject.financialIssuesAndAnomalies}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Alcohol">
                                            <SubjectAlcoholDrugTreatmentDetails
                                                alcoholDrugTreatmentReportables={subject.alcoholDrugTreatmentReportables}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Media">
                                            <ReportingSubjectMediaContactDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Address">
                                            <ReportingSubjectAddressDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Marital Status">
                                            <ReportingSubjectMaritalDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Cohabitants">
                                            <ReportingSubjectCohabitantDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Arrests">
                                            <ReportingSubjectArrestDetails />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Intelligence">
                                            <SubjectForeignIntelligenceContactDetails
                                                foreignIntelligenceEntities={subject.foreignIntelligenceEntities}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Clearance">
                                            <SubjectClearanceDetails subject={subject}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Fingerprinting">
                                            <SubjectFingerprintDetails subject={subject}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Information">
                                            <SubjectBackgroundInformation subject={subject}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Eqip" id="eqip">
                                            <SubjectEqipInformation subject={subject}/>
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </div>

                    <div className="back_btn_block">
                        <Button className="btn btn-back" onClick={()=>{props.history.push('/reporting/subject')}}>Back</Button>
                        <Button className="btn btn-back" onClick={()=>{
                            CommonUtils.handleExit();
                            props.history.push('/reporting/subject/emailCheck')}
                        }>Exit</Button>
                    </div>
                </div>

            </>
        </div>
    )
}


function mapStateToProps({subject_for_self_reporting}) {
    return {
        subject: subject_for_self_reporting
    }

}

export default connect(mapStateToProps, null)(ReportingPriorInformation);