import React, {Component, Fragment} from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";

export class PersonalInfoAddress extends Component {
    render(){
        const {address} = this.props;

        return (
            <Fragment>
                <Form.Group controlId="addressLine1">
                    <Form.Label>Line 1</Form.Label>
                    <Form.Control plaintext readOnly value={address.address1}/>
                </Form.Group>
                <Form.Group controlId="addressLine2">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control plaintext readOnly value={address.address2}/>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} md={4} controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control plaintext readOnly value={address.city}/>
                    </Form.Group>
                    <Form.Group as={Col} md={3} controlId="stateOrProvince">
                        <Form.Label>State/Region</Form.Label>
                        <Form.Control plaintext readOnly value={address.stateOrProvince}/>
                    </Form.Group>
                    <Form.Group as={Col} md={3} controlId="country">
                        <Form.Label>Country</Form.Label>
                        <Form.Control plaintext readOnly value={address.country}/>
                    </Form.Group>
                    <Form.Group as={Col} md={2} controlId="postalCode">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control plaintext readOnly value={address.postalCode}/>
                    </Form.Group>
                </Form.Row>
            </Fragment>
        )
    }
}

PersonalInfoAddress.propTypes = {
    address: PropTypes.object.isRequired,
};