/**
 * Created by shiva on 12/4/19.
 */
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Card from "react-bootstrap/Card";
import { reportForeignTravel} from '../../../../actions/subject_actions';
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions"
import {Steps} from "primereact/steps";
import {AddItineraryAndTransport} from '../foreignTravel/travelWizard/itinerary_transport'
import {AddEmergencyContact} from '../foreignTravel/travelWizard/emergency_contact';
import {ForeignContact} from '../foreignTravel/travelWizard/foreignContacts';
import {ReportForeignContactQuestionnaire} from '../foreignTravel/travelWizard/foreign_contact_questionnaire';
import {AddPassportDetails} from '../foreignTravel/travelWizard/passport_details';
import "../../../../assets/css/components/stepper.scss";
import {ForeignCountryVisitForm} from "../foreignTravel/travelWizard/foreign_country_visits";


let stepCount = 0;
const STEP_FILL_ITINERARY = stepCount++;
const STEP_ADD_COUNTRY_VISIT = stepCount++;
const STEP_FILL_PP_DETAILS = stepCount++;
const STEP_FILL_EMERGENCY_CONTACT = stepCount++;
//const STEP_ADD_LODGE = stepCount++;
const STEP_ADD_CONTACT = stepCount++;
const STEP_CONTACT_QUESTIONNAIRE = stepCount++;

export class  CommonForeignTravel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disableContactSubmit: false,
            // Itinerary Transport - itinerary_transport.js
            activeStep: STEP_FILL_ITINERARY,
            formValues: {
                travelReason: "Foreign Relationship Visit",
                departureDate: "",
                arrivalDate: "",
                countriesVisited: [],
                countryVisits: [],
                transportation: {
                    departureCarrierName: "",
                    departFrom: "",
                    arrivalCarrierName: "",
                    arriveFrom: "",
                },

                // Passport Details - passport_details.js
                passportDetails: {
                    passportNumber: "",
                    issueDate: "",
                    expiryDate: "",
                    passportType: "BOOK",
                },
                // Emergency Contact - emergency_contact.js
                emergencyContactName: "",
                emergencyContactPhone: "",
                emergencyContactEmail: "",
                emergencyContactAddress: "",

                foreignContacts: []
            },
            countryVisitStatusMsg: 'Add visit details for each country travelled',
            stepItemLabels: [
                {label: "Itinerary & Transportation"},
                {label: "Country Visits"},
                {label: "Passport Details"},
                {label: "Emergency Contact"},
                {label: "Foreign Contact"},
                {label: "Contact Questionnaire"}
            ],
            stepItems: [
                {label: "Itinerary & Transportation"},
                {label: ""},
                {label: ""},
                {label: ""},
                {label: ""}
            ]
        }
    }

    checkForeignVisitForAllCountries = (formValues) => {
// check to see if country visit are added for each country defined earlier
        let statusMsg = '';
        for (let visit of formValues.countryVisits) {
            if (!visit.completed) {
                statusMsg = statusMsg + visit.country + ", "
            }
        }
        if (statusMsg !== '') {
            this.setState({
                formValues,
                countryVisitStatusMsg: 'Please add visit details for the countries travelled: ' + statusMsg.substr(0, statusMsg.length - 2)
            })
        } else {
            this.setState({
                formValues,
                countryVisitStatusMsg: ''
            })
        }
    }

    handleSubmit = (values) => {
        let {formValues} = this.state;
        this.props.onSubmit(formValues);
    }

    onContactAdd = (contact) => {
        if (contact) {
            let {formValues} = this.state;
            if (!formValues.foreignContacts) {
                formValues.foreignContacts = [];
            }
            formValues.foreignContacts.push(contact);
            this.setState({
                formValues
            })
        }
    }

    onContactDelete = (contact) => {
        if (contact) {
            let {formValues} = this.state;
            let contacts = formValues.foreignContacts;
            for (let i = 0; i < contacts.length; i++) {
                if (contacts[i].firstName === contact.firstName &&
                    contacts[i].lastName === contact.lastName) {
                    contacts.splice(i, 1);
                    i--;
                }
            }
            this.setState({formValues})
        }
    }

    getStepLabelsRuntime = (addStep) => {
        let {stepItemLabels, activeStep} = this.state;
        let stepItems = ['', '', '', '', ''];
        if (addStep) {
            stepItems.push('')
        }
        stepItems[activeStep + 1] = stepItemLabels[activeStep + 1]
        return stepItems;
    }


    onItineraryAdd = (values) => {
        console.log(values,"manoo")
        const {formValues, activeStep} = this.state;
        let newFormValues = {...formValues, ...values};
        let stepItems = this.getStepLabelsRuntime();

        // Adjust country visit details
        const {countryVisits = [], countriesVisited = []} = newFormValues;

        // Remove countries
        let newCountryVisits = countryVisits.filter((countryVisit) => countriesVisited.includes(countryVisit.country));

        // Add any new countries
        countriesVisited.forEach((country) => {
            if (!newCountryVisits.some((countryVisit) => countryVisit.country === country)) {
                newCountryVisits.push({
                    country: country,
                    arrivalDate: "",
                    departureDate: "",
                    carrierNumber: "",
                    modeOfTransport: "",
                    lodges: [],
                    didNotLodge: false,
                    completed: false
                });
            }
        });

        newFormValues.countryVisits = newCountryVisits;
             console.log(  newFormValues, activeStep + 1, stepItems , "monkey")
        this.setState({
            formValues: newFormValues,
            activeStep: activeStep + 1,
            stepItems: stepItems
        });
    }

    onPassportAdd = (passportDetail) => {
        if (passportDetail) {
            let {formValues, activeStep} = this.state;
            formValues.passportDetails = passportDetail;
            let stepItems = this.getStepLabelsRuntime();
            this.setState({
                formValues,
                activeStep: activeStep + 1,
                stepItems: stepItems
            })
        }
    }

    onCountryVisitsChanged = (visits) => {
        let {formValues} = this.state;

        formValues.countryVisits = visits;
        this.checkForeignVisitForAllCountries(formValues);
        console.log("Visits Changed", visits);
    }

    onLodgesVisitsDone = () => {
        let {activeStep} = this.state;
        let stepItems = this.getStepLabelsRuntime();
        this.setState({
            activeStep: activeStep + 1,
            stepItems: stepItems
        });
    }

    onContactQuesResponse = (values) => {
        let {formValues} = this.state;
        formValues = {...formValues, ...values};
        this.setState({
            formValues
        });
        this.handleSubmit();
    }


    onContactsDone = () => {
        let {activeStep, stepItems, formValues} = this.state;
        if (formValues.foreignContacts && formValues.foreignContacts.length > 0) {
            let stepItems = this.getStepLabelsRuntime(true);
            this.setState({
                activeStep: activeStep + 1,
                stepItems: stepItems
            });
        } else {
            this.setState({
                disableContactSubmit: true
            })
            this.handleSubmit();
        }
    }

    getSteps() {
        const {stepItems} = this.state;

        const onActiveIndexChange = (e) => this.setState({activeStep: e.index});
        return (
            <Steps model={stepItems} activeIndex={this.state.activeStep}
                   activeIndexChange={onActiveIndexChange.bind(this)}/>
        )
    }

    onStepComplete = (values) => {
        let {foreigntravel} = this.state;
        let stepItems = this.getStepLabelsRuntime();
        this.setState({
            foreigntravel: {...foreigntravel, ...values},
            activeStep: this.state.activeStep + 1,
            stepItems: stepItems
        })
    }

    previousPage = () => {
        let stepItems = this.getStepLabelsRuntime(true);
        this.setState(
            {
                activeStep: this.state.activeStep - 1,
                stepItems: stepItems,
            })
    }
    onSubmit = (values) => {
        let {formValues, activeStep} = this.state;
        formValues = {...formValues, ...values};
        console.log('Form values are ', formValues);
        let stepItems = this.getStepLabelsRuntime();
        this.setState({
            formValues,
            activeStep: activeStep + 1,
            stepItems: stepItems
        }) 
    }

    handleCancel = () => {
        // close action is differ compare to reporting and subject
        this.props.onCancel();
    }

    renderStepContent = () => {
        let {formValues, countryVisitStatusMsg, disableContactSubmit, ppWarnMessage} = this.state;
        switch (this.state.activeStep) {
            case -1:
                return <div>Loading...</div>;
            case STEP_FILL_ITINERARY:
                return <AddItineraryAndTransport onAddItinerary={this.onItineraryAdd} onCancel={this.handleCancel}
                                                 previewdata={formValues}/>;
            case STEP_ADD_COUNTRY_VISIT:
                return <ForeignCountryVisitForm previousPage={this.previousPage}
                                                previewData={formValues}
                                                onChange={this.onCountryVisitsChanged}
                                                onCancel={this.handleCancel}
                                                onContinue={this.onLodgesVisitsDone}
                                                errorMessage={countryVisitStatusMsg}/>;
            case STEP_FILL_PP_DETAILS:
                return <AddPassportDetails previousPage={this.previousPage} onAddPassportDetails={this.onPassportAdd}
                                           onCancel={this.handleCancel} previewdata={formValues} warnMessage={ppWarnMessage}/>;
            case STEP_FILL_EMERGENCY_CONTACT:
                return <AddEmergencyContact previousPage={this.previousPage}
                                            onAddEmergencyContact={this.onSubmit} onCancel={this.handleCancel}
                                            previewdata={formValues}/>;
            case STEP_ADD_CONTACT:
                return <ForeignContact previousPage={this.previousPage} addedContacts={formValues.foreignContacts}
                                       addContact={this.onContactAdd} removeContact={this.onContactDelete}
                                       onContinue={this.onContactsDone} onCancel={this.handleCancel} canContinue={true} disableContactSubmitButton={disableContactSubmit}/>;
            case STEP_CONTACT_QUESTIONNAIRE:
                return <ReportForeignContactQuestionnaire previousPage={this.previousPage}
                                                          addQuestionnaireResponse={this.onContactQuesResponse}
                                                          onCancel={this.handleCancel}/>
        }
    }


    render() {
        return (        
                    <Fragment>
                        <Card>
                            <Card.Body>
                                {this.getSteps()}
                                {this.renderStepContent()}
                            </Card.Body>
                        </Card>
                    </Fragment>
        )
    }
}

export default connect(null, {reportForeignTravel, showSuccessGrowl, showErrorGrowl})(CommonForeignTravel);
