import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {Form, Card, Col, Button} from "react-bootstrap";
import * as Yup from "yup";
import {Field, Formik} from "formik";
import ButtonToolbar from "react-bootstrap/ButtonToolbar"; 
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { CommonUtils } from "../../../../actions/common_utils";
import { FormikDatePickerComponent } from "../../../../components/formik/formik_date_picker";

export class CommonReportForeignBankAccount extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() { 
        return (
            <Fragment>
                <Formik
                        initialValues={{
                            "country": "Afghanistan",
                            "instName": "",
                            "accountNeedOrUsedFor": "",
                            "startDate": ""
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit.bind(this)}
                        validationSchema={
                            Yup.object().shape({
                                country: Yup.string().required('Country is required!'),
                                instName: Yup.string().required('Institution name is required!'),
                                accountNeedOrUsedFor: Yup.string().required('Purpose for this account is required!'),
                                startDate: Yup.date().required('Enter start date!')
                            })
                        }
                    >
                        {({ isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur }) => (

                            <Form noValidate onSubmit={handleSubmit}>

                                <Card>
                                    <Card.Header>Foreign Bank Account Information</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control required as="select" name={'country'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.country && !!errors.country}
                                                    value={values.country}
                                                >
                                                    {CommonUtils.getOptionsForSelect(CommonUtils.getAvailCountries())}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.country}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId={'instName'}>
                                                <Form.Label>Name of Institution</Form.Label>
                                                <Form.Control required type="text" name={'instName'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'Enter the name of the institution'}
                                                    isInvalid={touched.instName && !!errors.instName}
                                                    value={values.instName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.instName}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId={'accountNeedOrUsedFor'}>
                                                <Form.Label>Purpose</Form.Label>
                                                <Form.Control required type="text" name={'accountNeedOrUsedFor'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'How is this account used or what is the need/purpose'}
                                                    isInvalid={touched.accountNeedOrUsedFor && !!errors.accountNeedOrUsedFor}
                                                    value={values.accountNeedOrUsedFor}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.accountNeedOrUsedFor}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId={'startDate'}>
                                                <Form.Label>Account Open Date</Form.Label>
                                                <Field
                                                    id="startDate"
                                                    name="startDate"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Account open Date (MM/DD/YYYY)"}
                                                />
                                            </Form.Group>
                                        </Form.Row>



                                    </Card.Body>
                                    <Card.Footer>
                                        <ButtonToolbar>
                                            <Button variant="primary" disabled={isSubmitting} type="submit">Submit</Button>
                                            {/* <Link to="/reporting/subject/reportingForeignInformation" className="btn btn-danger">Cancel</Link> */}
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Foreign Bank Account Form"
                                                handleDeleteCallback={this.handleCancel.bind(this)} />
                                        </ButtonToolbar>
                                    </Card.Footer>
                                </Card>
                            </Form>



                        )}
                    </Formik>
            </Fragment>
        )
    }
}

