import React from "react";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {Card, Form} from "react-bootstrap";
import MaskedInput from "react-input-mask";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import {CommonUtils} from "../../../../actions/common_utils";
import {fetchVisitIssueForFixing, fixVisit} from "../../../../actions/visit_actions";
import Alert from "react-bootstrap/Alert";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import {getSubjectUsingEmailForVisitFix} from "../../../../actions/subject_actions";

class VisitFixComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            availSecurityClearanceLevel: ['NONE', 'CONFIDENTIAL', 'SECRET', 'TOP_SECRET'],
            otherSubjects: []
        }
    }

    componentDidMount() {
        if (this.props.match.params.emailCode) {
            // Then this is an attempt to fix the visit
            this.getIssue(this.props.match.params.emailCode);
        }
    }

    handleDelete = (subjectId) => {
        let {otherSubjects} = this.state;
        let newSubs = [];
        for (let eachSubject of otherSubjects){
            if (eachSubject.id !== subjectId){
                newSubs.push(eachSubject);
            }
        }
        this.setState({
            otherSubjects: newSubs
        })
    }

    getSubjectUsingEmail = async (email) => {
        try {
            let {otherSubjects, visitIssue} = this.state;
            // check for duplicates
            for (let eachSubject of otherSubjects){
                if (eachSubject.emailAddressOfCurrentOrg === email){
                    this.props.showErrorGrowl("Subject has already been added", "Subject with email " + email + " is already added");
                    return;
                }
            }
            console.log('Visit isssue is ', visitIssue)
            let response = await getSubjectUsingEmailForVisitFix(email, visitIssue.id);
            let subject = response.data;
            if (subject){
                otherSubjects.push(subject);
                this.setState({
                    otherSubjects
                })
            }
        } catch (error) {
            console.log('Error searching subject', error)
            this.props.showErrorGrowl("Unable to search Subject", error.response.data);
        }
    }

    getIssue = async (emailCode) => {
        const visitIssue = await fetchVisitIssueForFixing(emailCode);
        let otherSubs = [];
        if (visitIssue.visit && visitIssue.visit.subjects) {
            otherSubs = visitIssue.visit.subjects;
        }
        console.log('Other subs are ', otherSubs)
        this.setState({
            visitIssue: visitIssue,
            otherSubjects: otherSubs
        });
    }

    async onSubmit(values) {
        console.log('Visit is ', values);
        values.subjectEmailList = []

        // Split emails into array
        for (let eachSubject of this.state.otherSubjects){
            if (eachSubject.emailAddressOfCurrentOrg && eachSubject.emailAddressOfCurrentOrg.length > 0) {
                values.subjectEmailList.push(eachSubject.emailAddressOfCurrentOrg);
            }
        }
        values.subjects = [];

        await this.props.fixVisit(values, this.props.match.params.emailCode, () => {

            // Success Response
            this.props.showSuccessGrowl("Visit Updated", "Visit was been updated successfully and is under review");
            this.props.history.push("/misc/closeBrowser");
        }, (error) => {
            console.log('Visit request fix error is ', error);
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Invalid Code", "Visit request not found.")
                this.props.history.push("/misc/closeBrowser");
            } else {
                this.props.showErrorGrowl("Unexpected Error", "Unexpected error fixing the visit request.")
                this.props.history.push("/misc/closeBrowser");
            }
        });
    }

    getOtherSubjectsTable = () => {
        let {otherSubjects} = this.state;
        const columns = [{
            dataField: 'personFirstName',
            text: 'First Name',
            formatter: (cell, row) => cell,
            sort: true,
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => cell,
            sort: true,
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <div className="custom_btn">
                        <ButtonToolbar>
                            <DeleteConfirmationModal deleteEntity="Subject"  actionText={"remove"} entityId={row.id} handleDeleteCallback={this.handleDelete}
                                                     buttonText={'Remove'} jsxButtonType={<span aria-hidden="true"><i class="fa fa-trash"></i></span>}
                            />
                        </ButtonToolbar>
                    </div>
                )
            }
        }];

        return (
            <Card>
                <Card.Header>Visiting Subjects</Card.Header>
                <Card.Body>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={otherSubjects}
                                    columns={columns} filter={filterFactory()}/>
                </Card.Body>
            </Card>
        );

    }

    render() {
        const {availSecurityClearanceLevel, _error, visitIssue, otherSubjects} = this.state;

        let visitIssueTempObj = {
            visitingSecurityOfficeName: "",
            visitingSecurityOfficeAddress: "",
            technicalPOCName: "",
            technicalPOCNumber: '',
            securityPOCName: '',
            securityPOCNumber: '',
            classificationLevel: 'NONE',
            purpose: "",
            visitStartDate: '',
            visitForSelf: true,
            visitEndDate: '',
            subjectEmail: '',
        };
        if (visitIssue) {
            visitIssueTempObj = visitIssue.visit;
            if (visitIssueTempObj.classificationLevel && visitIssueTempObj.classificationLevel.id){
                visitIssueTempObj.classificationLevel = visitIssueTempObj.classificationLevel.id;
            }
            visitIssueTempObj.rejectionReason = visitIssue.rejectionReason;
        }
        console.log('Visit temp issue is ', visitIssueTempObj)
        return (
            <React.Fragment>
                <Card>
                    <Card.Header>Fix Visit</Card.Header>
                    <Formik
                        initialValues={visitIssueTempObj}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                subjectEmail: Yup.string().test(
                                    'subject-email-test',
                                    'Please add at least one subject for visit',
                                    function (value) {
                                        if (this.parent.visitForSelf){
                                            return true;
                                        }
                                        return (otherSubjects && otherSubjects.length > 0);
                                    }
                                ),
                                visitingSecurityOfficeName: Yup.string().required('Enter visiting office name'),
                                visitingSecurityOfficeAddress: Yup.string().required('Address is required'),
                                technicalPOCName: Yup.string().required("Technical Point of Contact Name is required"),
                                technicalPOCNumber: Yup.string().required('Technical Point of Contact phone number is required'),
                                securityPOCName: Yup.string().required('Security Point of Contact Name is required a contact location'),
                                securityPOCNumber: Yup.string().required('Security Point of Contact phone number is required'),
                                classificationLevel: Yup.string().required('Select a classification level'),
                                purpose: Yup.string().required('Enter a visit reason'),
                                visitStartDate: Yup.date().required('Enter date of visit')
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card.Body>
                                    {_error &&
                                    <Alert variant={"danger"}>{_error}</Alert>
                                    }
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="subjectEmail">
                                            <Form.Label>Visiting Subjects' email addresses</Form.Label>

                                            <div className="visiting_txt_field">
                                                <Form.Control
                                                    type="text"
                                                    name="subjectEmail"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter subject email address and hit add"
                                                    isInvalid={touched.subjectEmail && !!errors.subjectEmail}
                                                    value={values.subjectEmail}
                                                />
                                                <Button controlId="addButton" disabled={!values.subjectEmail || values.subjectEmail.length === 0} onClick={() => {
                                                    this.getSubjectUsingEmail(values.subjectEmail);
                                                    setFieldValue("subjectEmail",'');
                                                }} variant={"primary"}>Add</Button>
                                            </div>


                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectEmail}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            {this.getOtherSubjectsTable()}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="VisitNewName">
                                            <Form.Label>Visiting Security Office Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="visitingSecurityOfficeName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter visiting office name"
                                                isInvalid={touched.visitingSecurityOfficeName && !!errors.visitingSecurityOfficeName}
                                                value={values.visitingSecurityOfficeName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitingSecurityOfficeName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="visitingSecurityOfficeAddress">
                                            <Form.Label>Visiting Security Office Address</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="visitingSecurityOfficeAddress"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter visiting office address"
                                                isInvalid={touched.visitingSecurityOfficeAddress && !!errors.visitingSecurityOfficeAddress}
                                                value={values.visitingSecurityOfficeAddress}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitingSecurityOfficeAddress}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="technicalPOCName">
                                            <Form.Label>Technical POC Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="technicalPOCName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter technical point of contact name"
                                                isInvalid={touched.technicalPOCName && !!errors.technicalPOCName}
                                                value={values.technicalPOCName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.technicalPOCName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="technicalPOCNumber">
                                            <Form.Label>Technical POC Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                as={MaskedInput}
                                                name="technicalPOCNumber"
                                                mask="999-999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.technicalPOCNumber && !!errors.technicalPOCNumber}
                                                value={values.technicalPOCNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.technicalPOCNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="securityPOCName">
                                            <Form.Label>Security POC Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="securityPOCName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter security point of contact name"
                                                isInvalid={touched.securityPOCName && !!errors.securityPOCName}
                                                value={values.securityPOCName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.securityPOCName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="securityPOCNumber">
                                            <Form.Label>Security POC Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                as={MaskedInput}
                                                name="securityPOCNumber"
                                                mask="999-999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.securityPOCNumber && !!errors.securityPOCNumber}
                                                value={values.securityPOCNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.securityPOCNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId={"classificationLevel"}>
                                            <Form.Label>Classification Level</Form.Label>
                                            <Form.Control required as="select" name={'classificationLevel'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.classificationLevel && !!errors.classificationLevel}
                                                          value={values.classificationLevel}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(availSecurityClearanceLevel))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.classificationLevel}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="purpose">
                                            <Form.Label>Purpose</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="purpose"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Purpose of Visit"
                                                isInvalid={touched.purpose && !!errors.purpose}
                                                value={values.purpose}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.purpose}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Visit Date</Form.Label>
                                            <Field
                                                id="visitStartDate"
                                                name="visitStartDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Enter the date of Visit"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Visit End Date</Form.Label>
                                            <Field
                                                id="visitEndDate"
                                                name="visitEndDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"End date if visit is for multiple days"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="rejectionReason">
                                            <Form.Label>Rejection Reason</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="rejectionReason"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.rejectionReason && !!errors.rejectionReason}
                                                value={values.rejectionReason}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rejectionReason}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </Card.Body>
                                <ButtonToolbar>
                                    <Button variant="primary" type="submit"
                                            disabled={isSubmitting}>Update</Button>
                                </ButtonToolbar>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </React.Fragment>
        )
    }
}

export const
    VisitFix = connect(null, {
        fixVisit,
        showSuccessGrowl,
        showErrorGrowl
    })(VisitFixComponent);

