import React, {Component, Fragment} from "react";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";
import {DateUtils} from "../../../../actions/common_utils";

export class ReportableForeignIdCard extends Component {
    render() {
        const {idCard} = this.props;

        return (
            <Fragment>
                <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control plaintext readOnly value={idCard.country}/>
                </Form.Group>
                <Form.Group controlId="idCardNumber">
                    <Form.Label>ID Card/Passport Number</Form.Label>
                    <Form.Control plaintext readOnly value={idCard.idCardNumber}/>
                </Form.Group>
                <Form.Group controlId="reason">
                    <Form.Label>Reason</Form.Label>
                    <Form.Control plaintext readOnly value={idCard.reason}/>
                </Form.Group>
                <Form.Group controlId="expireDate">
                    <Form.Label>Expiration Date</Form.Label>
                    <Form.Control plaintext readOnly value={idCard.expireDate ? DateUtils.getDateString(idCard.expireDate) : "Unknown"}/>
                </Form.Group>
            </Fragment>
        )
    }
}

ReportableForeignIdCard.propTypes = {
    idCard: PropTypes.object.isRequired
};