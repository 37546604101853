import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {ButtonToolbar} from "react-bootstrap";
import {DateUtils} from "../../../../actions/common_utils";
import {SubjectReportableUpdateModal} from "../subject_reportable_update_modal";
import {getSubjectForSelfReporting} from "../../../../actions/subject_actions";

export class ReportingSubjectArrestDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            arrests: []
        };
    }

    componentDidMount() {
        this.refreshSubject();
    }

    refreshSubject = async () => {
        const subject = await getSubjectForSelfReporting();

        this.setState({
            arrests: subject.arrests
        });
    }

    getArrests() {
        return this.state.arrests.map((arrest) => {
            return <tr key={arrest.id}>
                <td>{arrest.incidentDate ? DateUtils.getDateString(arrest.incidentDate) : "Unknown"}</td>
                <td>{arrest.incidentLocation}</td>
                <td>{arrest.charges}</td>
                <td>{arrest.disposition}</td>
                <td>{arrest.startDate ? DateUtils.getDateString(arrest.startDate) : "Unknown"}</td>
                <td>
                    {arrest.dateInvalidated ?
                        `${DateUtils.getDateString(arrest.dateInvalidated)} (${arrest.reasonInvalidated ? arrest.reasonInvalidated.displayName : "Unknown"})`
                        : "Present"}
                </td>
                <td>
                    <ButtonToolbar className="p-0 mb-0 float-right" style={{flexWrap: "nowrap"}}>
                        {!arrest.dateInvalidated &&
                        <SubjectReportableUpdateModal reportable={arrest}
                                                      reportableTypeString={"Arrest"}
                                                      onUpdate={this.refreshSubject}/>
                        }
                    </ButtonToolbar>
                </td>
            </tr>
        });
    }

    render() {
        const {arrests} = this.state;

        if (!arrests || arrests.length === 0) {
            return <Alert variant="danger">No Arrests!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Incident Date</th>
                        <th>Incident Location</th>
                        <th>Charges</th>
                        <th>Disposition</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {this.getArrests()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}
