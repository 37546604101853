import React from "react";
import {CUR_ORG} from "../../actions/session_constants";
import {fetchCurOrg, updateOrg} from "../../actions/org_actions";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import BreadcrumbLink from "../../components/bootstrap_addons/breadcrumb_link";
import {
    FieldRBCheckbox,
    FieldRBCountrySelector,
    FieldRBDropzone, FieldRBDualListBox,
    FieldRBInput,
    FieldRBRegionSelector, FieldRBSelect
} from "../../components/redux_form/field_components_rb";
import {fetchOrgFacilities} from "../../actions/facility_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {getComplianceDocTypesForSubject, getDocTypesByEntityType} from "../../actions/fieldValue_actions";
import Col from "react-bootstrap/Col";
import DeleteConfirmationModal from "../../components/delete_confirm_modal";

class OrgUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            availSubjectDocTypes: [],
            availFacilityDocTypes: [],
        }
    }

    handleCancel = () => {
        window.history.back();
    }


    onSubmit(values) {
        values.allowBroadcastEmail = (values.allowBroadcastEmail === 'Yes'?true:false);
        values.doNotAllowEmailNotificationWithinOrg = (values.doNotAllowEmailNotificationWithinOrg === 'Yes'?true:false);
        values.signatureCountByYears = null;
        console.log('Org update values', values)
        this.props.updateOrg(values, this.props.orgObj.org.id, () => {
            this.props.fetchOrgFacilities(sessionStorage.getItem(CUR_ORG));
            this.props.history.push("/org/dash/settings");
            this.didFormInit = false;
            this.props.reset();
        });
    }

    componentDidMount() {
        this.didFormInit = false;
        getDocTypesByEntityType("FACILITY", (response) => {
            this.setState({
                availFacilityDocTypes: response.data.map(docType => {
                    return {
                        value: docType.id,
                        label: docType.type
                    }
                })
            });
        });
        getComplianceDocTypesForSubject((response) => {
            this.setState({
                availSubjectDocTypes: response.data.map(docType => {
                    return {
                        value: docType.id,
                        label: docType.type
                    }
                })
            });
        });
    }

    componentDidUpdate() {
        if (this.props.orgObj.org.id && this.didFormInit === false) {
            const org = this.props.orgObj.org;
            org.subjectRequiredDocs = org.subjectRequiredDocs ? org.subjectRequiredDocs.map((reqDoc) => reqDoc.id) : [];
            org.facilityRequiredDocs = org.facilityRequiredDocs ? org.facilityRequiredDocs.map((reqDoc) => reqDoc.id) : [];
            org.allowBroadcastEmail = org.allowBroadcastEmail ? 'Yes' : 'No';
            org.doNotAllowEmailNotificationWithinOrg = org.doNotAllowEmailNotificationWithinOrg ? 'Yes' : 'No';
            this.props.initialize(this.props.orgObj.org); // This initializes the form with the values from the organization object
            // Names need to match the form field name
            this.didFormInit = true;
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Settings
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        const {handleSubmit, sel_country, orgObj} = this.props;
        const {org} = orgObj;

        if (!orgObj || !org || !org.id) {
            return (
                <div>Loading...</div>
            );
        }
        return (
            <div>
                {/* {this.getBreadCrumb()} */}
              
               
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>

                <div className="create_org">
                    <div className="section_title mb-20">
                        <h4>Edit Organization</h4>
                    </div>
                    <div className="form-row">
                        <div className="col-lg-6">
                            <Field name="name" component={FieldRBInput} label="Organization Name" horizontal/>
                        </div>

                        <div className="col-lg-6">
                            <Field name="description" component={FieldRBInput} label="Description" horizontal/>
                        </div>
                    </div>
                </div>

                <div className="create_org">
                    <div className="section_title mb-20">
                        <h4>Primary Address</h4>
                    </div>
                    <div className="form-row">
                        <div className="col-lg-4">
                            <Field name="street" component={FieldRBInput} label="Street Address" horizontal/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="city" component={FieldRBInput} label="City" horizontal/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="country" component={FieldRBCountrySelector} label="Country" horizontal/>
                        </div>

                        <div className="col-lg-6">
                            <Field name="state" component={FieldRBRegionSelector} country={sel_country}
                           label="State or Region" horizontal/>
                        </div>

                        <div className="col-lg-6">
                            <Field name="zipcode" component={FieldRBInput} label="Postal or Zip Code" horizontal/>
                        </div>
                    </div>
                </div>

                <div className="create_org">
                    <div className="section_title mb-20">
                        <h4>Point of Contact Information</h4>
                    </div>

                    <div className="form-row">
                        <div className="col-lg-6">
                            <Field name="pocName" component={FieldRBInput} label="POC Name" horizontal/>
                        </div>

                        <div className="col-lg-6">
                            <Field name="pocPhone" component={FieldRBInput} label="POC Phone Number" type="tel" horizontal/>
                        </div>

                        <div className="col-lg-6">
                            <Field name="pocEmail" component={FieldRBInput} label="POC Email Address" type="email" horizontal/>
                        </div>
                    </div>
                </div>

                <div className="create_org">
                    <div className="section_title mb-20">
                        <h4>Disable Email Communication</h4>
                    </div>

                    <div className="form-row">
                        <Field name="doNotAllowEmailNotificationWithinOrg" component={FieldRBSelect} options={['Yes','No']} label="Disable Email communication to anyone in the Org" horizontal/>
                    </div>
                </div>

                <div className="create_org">
                    <div className="section_title mb-20">
                        <h4>Broadcast email option</h4>
                    </div>

                    <div className="form-row">
                        <Field name="allowBroadcastEmail" component={FieldRBSelect} options={['Yes','No']} label="Allow system administrator broadcast email" horizontal/>
                    </div>
                </div>

                <div className="create_org">
                    <div className="section_title mb-20">
                        <h4>Compliance Requirements</h4>
                    </div>

                    <div className="form-row">
                       <div className="col-lg-6">
                            <Field name="subjectRequiredDocs" component={FieldRBDualListBox}
                                label="Subject Compliance" options={this.state.availSubjectDocTypes}/>
                       </div>
                       <div className="col-lg-6">
                            <Field name="facilityRequiredDocs" component={FieldRBDualListBox}
                                label="Location Compliance" options={this.state.availFacilityDocTypes}/>
                        </div>
                    </div>
                </div>

                <div className="create_org">
                    <div className="section_title mb-20">
                    <h4>Logo</h4>
                    </div>

                    <div className="form-row">
                        <Field name="logoDoc" component={FieldRBDropzone} label="Logo" horizontal/>
                    </div>
                </div>


                   
                   

                    <ButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        {/* <Link to="/org/dash/facility" className="btn btn-danger">Cancel</Link> */}
                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Edit Organization"
                                                    handleDeleteCallback={this.handleCancel}/>
                    </ButtonToolbar>
                </Form>
            </div>
        );
    }
}

function validate(values) {
    const errors = {};

    if (!values.name) {
        errors.name = "Name is required!";
    }
    if (!values.cageCode) {
        errors.cageCode = "Cage Code is required!";
    } else if (values.cageCode.length > 15) {
        errors.cageCode = "Cage Code must be 15 digits or less!";
    }
    if (!values.street) {
        errors.street = "Street Address is required!";
    }
    if (!values.city) {
        errors.city = "City is required!";
    }
    if (!values.country) {
        errors.country = "Country is required!";
    }
    if (!values.zipcode) {
        errors.zipcode = "Postal or Zip Code is required!";
    }

    return errors;
}

function mapStateToProps(state) {
    const formValues = state.form.OrgDetailForm;
    let selCountry = "";
    if (formValues.values && formValues.values.country) {
        selCountry = formValues.values.country;
    }
    return {
        orgObj: state.cur_org,
        sel_country: selCountry,
    }
}

export default reduxForm({
    validate, // same as validate:validate
    form: 'OrgDetailForm',
    initialValues: {
        country: 'US'
    }
})(
    connect(mapStateToProps, {updateOrg, fetchOrgFacilities})(OrgUpdate)
);