import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {Form, Card, Col, Button} from "react-bootstrap";
import * as Yup from "yup";
import {Field, Formik} from "formik";
import ButtonToolbar from "react-bootstrap/ButtonToolbar"; 
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { CommonUtils } from "../../../../actions/common_utils";
import { FormikDatePickerComponent } from "../../../../components/formik/formik_date_picker";

export class CommonForeignVoting extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() { 
        return (
            <Fragment>
                <Formik
                        initialValues={{
                            "electionDescription": "",
                            "country": "United States",
                            "date": ""
                        }}
                        enableReinitialize={true} 
                        onSubmit={this.onSubmit.bind(this)}
                        validationSchema={
                            Yup.object().shape({
                                electionDescription: Yup.string().required('Enter what the election was for'),
                                country: Yup.string().required('Select Country where voting occurred'),
                                date: Yup.date().required('Enter date of voting')
                            })
                        }
                    >
                        {({ isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur }) => (


                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Report Foreign Voting Information</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>What was the election for</Form.Label>
                                                <Form.Control required type="text" name={'electionDescription'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder={'Description of the elections purpose'}
                                                    isInvalid={touched.electionDescription && !!errors.electionDescription}
                                                    value={values.electionDescription}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.electionDescription}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Country where voting occurred</Form.Label>
                                                <Form.Control required as="select" name={'country'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder={'Enter the country in which voting occurred'}
                                                    isInvalid={!!errors.country}
                                                    value={values.country}
                                                >
                                                    {CommonUtils.getOptionsForSelect(CommonUtils.getAvailCountries())}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.country}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Date of Voting</Form.Label>
                                                <Field
                                                    id="date"
                                                    name="date"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Date of voting (MM/DD/YYYY)"}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                    <Card.Footer>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Submit</Button>
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Foreign Voting Form"
                                                handleDeleteCallback={this.handleCancel.bind(this)} />
                                        </ButtonToolbar>
                                    </Card.Footer>
                                </Card>
                            </Form>


                        )}
                    </Formik>
            </Fragment>
        )
    }
}

