import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {Form, Card, Col, Button} from "react-bootstrap";
import * as Yup from "yup";
import {Field, Formik} from "formik";
import ButtonToolbar from "react-bootstrap/ButtonToolbar"; 
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { CommonUtils } from "../../../../actions/common_utils";
import { FormikDatePickerComponent } from "../../../../components/formik/formik_date_picker";

export class CommonForeignIntelligenceContact extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        this.props.onSubmit(values);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() { 
        return (
            <Fragment>
                 <Formik
                        initialValues={{
                            "serviceInvolved": "",
                            "namesContactedAndDates": "",
                            "natureOfContact": "",
                            "futureContactsLikelihood": false
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit.bind(this)}
                        validationSchema={
                            Yup.object().shape({
                                serviceInvolved: Yup.string().required('List services involved'),
                                namesContactedAndDates: Yup.string().required('Enter contact names with dates'),
                                natureOfContact: Yup.string().required('Nature of contact is required')
                            })
                        }
                    >
                        {({ isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur }) => (

                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Foreign Intelligence Contact Information</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'serviceInvolved'}>
                                                <Form.Label>Service Involved</Form.Label>
                                                <Form.Control required type="text" name={'serviceInvolved'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder={'Services involved'}
                                                    isInvalid={touched.serviceInvolved && !!errors.serviceInvolved}
                                                    value={values.serviceInvolved}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.serviceInvolved}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId={'namesContactedAndDates'}>
                                                <Form.Label>Names of Contact with Dates</Form.Label>
                                                <Form.Control required type="text" name={'namesContactedAndDates'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder={'John Doe - 4/5/2020, Sam P - 4/3/2020...'}
                                                    isInvalid={touched.namesContactedAndDates && !!errors.namesContactedAndDates}
                                                    value={values.namesContactedAndDates}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.namesContactedAndDates}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId={'natureOfContact'}>
                                                <Form.Label>Nature of Contact</Form.Label>
                                                <Form.Control required type="text" name={'natureOfContact'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder={'Enter the nature of contact'}
                                                    isInvalid={touched.natureOfContact && !!errors.natureOfContact}
                                                    value={values.natureOfContact}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.natureOfContact}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId={'futureContactsLikelihood'} className="d_flex">
                                                <Form.Check
                                                    name={'futureContactsLikelihood'}
                                                    label={'Future Contact Likely'}
                                                    onChange={(event) => {
                                                        console.log('Event is ', event.target.checked);
                                                        setFieldValue('futureContactsLikelihood', event.target.checked);
                                                        handleChange(event);
                                                    }}
                                                    isInvalid={touched.futureContactsLikelihood && !!errors.futureContactsLikelihood}
                                                    id={'futureContactsLikelihood'}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.futureContactsLikelihood}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>


                                    </Card.Body>
                                    <Card.Footer>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Submit</Button>
                                            {/* <Link to="/reporting/subject/reportingForeignInformation" className="btn btn-danger">Cancel</Link> */}
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Foreign Intelligence Contact Form"
                                                handleDeleteCallback={this.handleCancel.bind(this)} />
                                        </ButtonToolbar>
                                    </Card.Footer>
                                </Card>
                            </Form>

                        )}

                    </Formik>
            </Fragment>
        )
    }
}

