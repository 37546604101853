import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {DateUtils} from "../../../../actions/common_utils";
import {getSubjectForSelfReporting} from "../../../../actions/subject_actions";
import {ButtonToolbar} from "react-bootstrap";
import {SubjectReportableUpdateModal} from "../subject_reportable_update_modal";

export class ReportingSubjectForeignIdCardDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            foreignIdCards: []
        };
    }

    async componentDidMount() {
        await this.refreshSubject();
    }

    refreshSubject = async () => {
        this.setState({
            foreignIdCards: []
        });

        const subject = await getSubjectForSelfReporting();

        this.setState({
            foreignIdCards: subject.foreignIdCards
        });
    }

    getForeignIdCards() {
        return this.state.foreignIdCards.map((foreignIdCard) => {
            return <tr key={foreignIdCard.id}>
                <td>{foreignIdCard.country}</td>
                <td>{foreignIdCard.idCardNumber}</td>
                <td>{foreignIdCard.reason}</td>
                <td>{foreignIdCard.startDate ? DateUtils.getDateString(foreignIdCard.startDate) : "Unknown"}</td>
                <td>{foreignIdCard.expireDate ? DateUtils.getDateString(foreignIdCard.expireDate) : "Present"}</td>
                <td>
                    <ButtonToolbar className="p-0 mb-0 float-right" style={{flexWrap: "nowrap"}}>
                        {!foreignIdCard.dateInvalidated &&
                        <SubjectReportableUpdateModal reportable={foreignIdCard}
                                                      reportableTypeString={"Foreign ID Card"}
                                                      onUpdate={this.refreshSubject}/>
                        }
                    </ButtonToolbar>
                </td>
            </tr>
        })
    }

    render() {
        const {foreignIdCards} = this.state;

        if (!foreignIdCards || foreignIdCards.length === 0) {
            return <Alert variant="danger">No Foreign ID/Cards!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Country</th>
                        <th>ID Card #</th>
                        <th>Reason</th>
                        <th>Date Acquired</th>
                        <th>Date Relinquished</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignIdCards()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}