/**
 * Created by shivaraman on 8/10/17.
 */

import React from 'react';

export default (props) => {
    return (
        <p>The page you were looking for does not exist or has been moved...</p>
    )
};