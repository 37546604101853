import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {reportArrestChange} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import { CommonPersonalInfoArrest } from "../commonInfo/common_personalInfo_arrest";

class ReportPersonalInfoArrestComponent extends Component {
    constructor(props) {
        super(props);

        this.state={
            isLoading: false,
        }
    }

    onSubmit(values) {
        this.setState({isLoading: true});
        this.props.reportArrestChange(values).then(() => {
            this.props.showSuccessGrowl("Arrest Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }).catch((error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
            this.setState({isLoading: false});
        });
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {  
        return (

            <div className="reporting_bg">
                <div className="container">
                    <Fragment>
                        <div className="heading_container">
                            <h3>Report Arrest Form</h3>
                        </div>
                        <CommonPersonalInfoArrest onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
                    </Fragment>
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>
            
        )
    }
}

  
export const ReportPersonalInfoArrest = connect(null, {reportArrestChange, showSuccessGrowl, showErrorGrowl})(ReportPersonalInfoArrestComponent);