import React, {Component, Fragment} from "react";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";

export class ReportableForeignProperty extends Component {
    render() {
        const {property} = this.props;

        return (
            <Fragment>
                <Form.Group controlId="location">
                    <Form.Label>Location</Form.Label>
                    <Form.Control plaintext readOnly value={property.location}/>
                </Form.Group>
                <Form.Group controlId="valueUsd">
                    <Form.Label>Value (USD)</Form.Label>
                    <Form.Control plaintext readOnly value={property.valueUsd}/>
                </Form.Group>
                <Form.Group controlId="balanceDue">
                    <Form.Label>Balance Due (USD)</Form.Label>
                    <Form.Control plaintext readOnly value={property.balanceDue}/>
                </Form.Group>
                <Form.Group controlId="purpose">
                    <Form.Label>Purpose</Form.Label>
                    <Form.Control plaintext readOnly value={property.purpose}/>
                </Form.Group>
                <Form.Group controlId="howAcquired">
                    <Form.Label>How Was Property Acquired</Form.Label>
                    <Form.Control plaintext readOnly value={property.howAcquired}/>
                </Form.Group>
            </Fragment>
        )
    }
}

ReportableForeignProperty.propTypes = {
    property: PropTypes.object.isRequired
};