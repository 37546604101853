import React, {Component} from "react";
import {connect} from "react-redux";
import {reportForeignChildAdoption} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions"; 
import { CommonForeignAdoptions } from "../commonInfo/common_foreign_adoptions";

class ReportForeignAdoptionsComp extends Component {
    constructor(props) {
        super(props);
        
        this.state={
            isLoading: false,
        }
    } 

    onSubmit(values) {
        this.setState({isLoading: true});
        values.foreignTravelRequired = (values.foreignTravelRequired === 'Yes'?true:false);
        this.props.reportForeignChildAdoption(values, () => {
            this.props.showSuccessGrowl("Foreign adoption Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        })
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {handleSubmit} = this.props;

        return (

            <div className="reporting_bg">
                <div className="container">
                <div className="heading_container">
                    <h3>Report Foreign Adoptions Form</h3>
                    </div>
              <CommonForeignAdoptions onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>
        
        )
    }
}



export const ReportForeignAdoptions  = connect(null, {reportForeignChildAdoption, showSuccessGrowl, showErrorGrowl})(ReportForeignAdoptionsComp);