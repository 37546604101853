import React, {Component, Fragment} from "react";
import Card from "react-bootstrap/Card";
import {connect} from "react-redux";
import {reportForeignContact} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import {getForeignContactQuestionTypes} from "../../../../actions/fieldValue_actions";
import {ForeignContact} from "./travelWizard/foreignContacts";
import {ReportForeignContactQuestionnaire} from "./travelWizard/foreign_contact_questionnaire";
import {Steps} from "primereact/steps";
import { CommonForeignContacts } from "../commonInfo/common_foreign_contact";

const STEP_ADD_CONTACT = 0;
const STEP_CONTACT_QUESTIONNAIRE = 1;

class ReportForeignContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: STEP_ADD_CONTACT,
            formValues: {contacts: []},
            generalQuestionOptions: [],
            stepItems: [
                {label: "Foreign Contact"},
                {label: "Contact Questionnaire"}
            ],
            isLoading: false
        }

    }

    componentDidMount() {
        // Get General Question Types
        getForeignContactQuestionTypes().then((response) => {
            const questionOptions = response.data.map((questionType) => {
                return {
                    questionType: questionType,
                    response: false,
                    comments: ""
                };
            });

            this.setState({
                generalQuestionOptions: questionOptions
            });
        });
    }

    onSubmit = (values) => {
        this.setState({isLoading: true});
        // let {formValues} = this.state;
        console.log("rty",values);
        this.props.reportForeignContact(values, () => {
            this.props.showSuccessGrowl("Foreign Contact(s) Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        })
    }

    close = () => {
        this.props.history.push("/reporting/subject");
    }

    render() {
        return (
            <div className="reporting_bg">
                <div className="container">
                    <div className="heading_container">
                        <h3> Foreign Contact Reporting</h3>
                    </div>
                    <CommonForeignContacts onSubmit={this.onSubmit.bind(this)} onCancel={this.close} />
                  
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>
        )
    }

}

export default connect(null, {reportForeignContact, showSuccessGrowl, showErrorGrowl})(ReportForeignContacts);