import React, {Component, Fragment} from "react";
import {getSubjectReportables, updateSubjectReportables} from "../../../actions/subject_actions";
import {connect} from "react-redux";
import Table from "react-bootstrap/Table";
import {ReportingSubjectReviewDetailsModal} from "./reporting_subject_review_modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {showErrorGrowl, showSuccessGrowl} from "../../../actions/msg_actions";

class ReportingSubjectReviewComponent extends Component {
    constructor(props) {
        super(props);

        this.finishReview = this.finishReview.bind(this);
        this.markNoChanges = this.markNoChanges.bind(this);
        this.markInvalidated = this.markInvalidated.bind(this);

        this.state = {
            reportables: [],
            error: null
        }
    }

    componentDidMount() {
        const hashCode = this.props.match.params.hashCode;
        this.props.getSubjectReportables(hashCode).then((reportables) => {
            this.setState({
                reportables: reportables,
                error: null
            })
        });
    }

    markNoChanges(id) {
        const reportables = this.state.reportables;

        const i = reportables.findIndex(r => r.id === id);
        reportables[i].reviewed = true;

        this.setState({
            reportables: reportables
        });
    }

    markInvalidated(id, dateInvalidated, reasonInvalidated, invalidatedName) {
        const reportables = this.state.reportables;
        console.log("id", id);

        const i = reportables.findIndex(r => r.id === id);
        console.log("index", i);
        console.log("reportable", reportables[i]);
        reportables[i].reviewed = true;
        reportables[i].reasonInvalidated = reasonInvalidated;
        reportables[i].invalidated = true;
        reportables[i].dateInvalidated = dateInvalidated;
        reportables[i].invalidatedReasonName = invalidatedName;

        this.setState({
            reportables: reportables
        });
    }

    getReportableActivities() {
        const {reportables} = this.state;
        console.log(reportables);
        return reportables.map((reportable, index) => {
            const name = reportable.uiName ? reportable.uiName : "";
            const type = reportable.uiType;

            const actions = () => {
                if (reportable.reviewed !== true) {
                    return <ReportingSubjectReviewDetailsModal reportable={reportable}
                                                        onReviewed={this.markNoChanges}
                                                        onInvalidate={this.markInvalidated} />;
                }
                if (!reportable.invalidated) {
                    return "Reviewed, No Changes";
                }
                return "Reviewed, " + reportable.invalidatedReasonName;
            };

            return (
                <tr key={index}>
                    <td>{name}</td>
                    <td>{type}</td>
                    <td>{actions()}</td>
                </tr>
            );
        });
    }

    finishReview(){
        const {reportables} = this.state;
        const {showSuccessGrowl, showErrorGrowl} = this.props;

        if (!reportables){
            showErrorGrowl("Finish Review Failed", "No Information Was Found!");
            this.setState({
                error: "No Information to Review!"
            });
            return;
        }

        if (reportables.includes(r => !r.reviewed)){
            showErrorGrowl("Finish Review Failed", "You Must Review All Information!");
            this.setState({
                error: "You Must Review All Information!"
            });
            return;
        }

        // Construct request objects
        const reportablesRequest = reportables.map((reportable) => {
            return {
                activityId: reportable.id,
                invalidated: reportable.invalidated,
                dateInvalidated: reportable.dateInvalidated,
                reasonInvalidated: reportable.reasonInvalidated
            }
        });

        this.props.updateSubjectReportables(this.props.match.params.hashCode, reportablesRequest).then(() => {
            showSuccessGrowl("Information Reviewed Successfully", "Your Information was successfully reviewed!");
            this.props.history.push("/misc/closeBrowser");
        });
    }

    render() {
        const {error} = this.state;
        return (
            <Fragment>
                <h3>Review Your Information</h3>
                {error && <Alert variant={"danger"}>{error}</Alert>}
                <Table striped hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getReportableActivities()}
                    </tbody>
                </Table>
                <ButtonToolbar>
                    <Button variant="primary" onClick={this.finishReview}>Finish Review</Button>
                </ButtonToolbar>
            </Fragment>
        )
    }
}

export const ReportingSubjectReview = connect(null, {getSubjectReportables, showSuccessGrowl, showErrorGrowl, updateSubjectReportables})(ReportingSubjectReviewComponent);