import React, {Component, Fragment} from "react";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";

export class ReportableForeignBankAccount extends Component {
    render() {
        const {bankAcct} = this.props;

        return (
            <Fragment>
                <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control plaintext readOnly value={bankAcct.country}/>
                </Form.Group>
                <Form.Group controlId="instName">
                    <Form.Label>Institution Name</Form.Label>
                    <Form.Control plaintext readOnly value={bankAcct.instName}/>
                </Form.Group>
            </Fragment>
        )
    }
}

ReportableForeignBankAccount.propTypes = {
    bankAcct: PropTypes.object.isRequired
};