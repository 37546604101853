import React, {Component, Fragment} from "react";
import {Card, Button, Modal, ButtonToolbar, Col, Form, Alert} from "react-bootstrap";
import PropTypes from "prop-types";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {CommonUtils} from "../../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import {FormikFieldDualListBox} from "../../../../../components/formik/formik_field_duallistbox";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class AddPassportDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passportTypes: ['BOOK', 'CARD']
        }
    }

    onSubmit = (values, actions) => {
        console.log(values);
        actions.setSubmitting(false);
        console.log(values,"values")
        this.props.onAddPassportDetails(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        let {previewdata, warnMessage} = this.props;
        const {previousPage} = this.props;
        return (
            <Fragment>
                <Formik
                    initialValues={{
                        passportNumber: previewdata.passportDetails.passportNumber,
                        issueDate: previewdata.passportDetails.issueDate,
                        expiryDate: previewdata.passportDetails.expiryDate,
                        passportType: previewdata.passportDetails.passportType
                    }}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    validationSchema={
                        Yup.object().shape({
                            passportNumber: Yup.string().required('Enter passport number'),
                            issueDate: Yup.date().required('Enter date of issue'),
                            expiryDate: Yup.date().required('Enter date of expiry'),
                            passportType: Yup.string().required('Select passport type'),
                        })
                    }
                >
                    {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            
                                <Card>
                                    <Card.Header>Passport Details</Card.Header>
                                    <Card.Body>
                                        {warnMessage &&
                                        <Alert variant="warning">Please review passport information for accuracy. It has been retrieved from prior trip.</Alert>
                                        }
                                        <Form.Row>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Passport Number</Form.Label>
                                                <Form.Control required type="text" name={'passportNumber'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter passport number'}
                                                              isInvalid={touched.passportNumber && !!errors.passportNumber}
                                                              value={values.passportNumber}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.passportNumber}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Date of Issue</Form.Label>
                                                <Field
                                                    id="issueDate"
                                                    name="issueDate"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Enter date of issue"}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.issueDate}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Date of Expiry</Form.Label>
                                                <Field
                                                    id="expiryDate"
                                                    name="expiryDate"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Enter date of expiry"}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.expiryDate}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId={'passportType'}>
                                                <Form.Label>Passport Type</Form.Label>
                                                <Form.Control required as="select" name={'passportType'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.passportType && !!errors.passportType}
                                                              value={values.passportType}
                                                >
                                                    {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(this.state.passportTypes))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                    <Card.Footer>
                                    <div className={"pull-right1"}>
                                    <ButtonToolbar>
                                    <Button variant="primary" onClick={previousPage}>Previous</Button>
                                        <Button variant="primary" disabled={isSubmitting}
                                                type="submit">Next</Button>
                                        {/* <Button variant="danger" onClick={this.props.onCancel}>Cancel</Button> */}
                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Foreign Travel"
                                                         handleDeleteCallback={this.handleCancel}/>
                                    </ButtonToolbar>
                                </div>
                                    </Card.Footer>
                                </Card>
                           
                          
                        </Form>
                    )}
                </Formik>
            </Fragment>
        )
    }
}

AddPassportDetailsComponent.propTypes = {
    onAddPassportDetails: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    previewdata: PropTypes.object.isRequired,
    warnMessage: PropTypes.string
};

export const AddPassportDetails = AddPassportDetailsComponent;