import React, {Component, Fragment} from "react";
import {Formik, FieldArray, getIn} from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {SUCCESS} from '../../../../actions/action_constants';
import {reportPostForeignTravel} from '../../../../actions/task_actions';
import {showErrorGrowl, showSuccessGrowl} from '../../../../actions/msg_actions';
import {Link} from "react-router-dom";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {getDocTypes, getEmployeeTypes, getPostForeignTravelQuestionTypes} from "../../../../actions/fieldValue_actions";
import {connect} from "react-redux";
import Col from "react-bootstrap/Col";
import {CommonUtils} from "../../../../actions/common_utils";
import {ISSUE_TO_UPLOAD} from "../../../../actions/session_constants";
import * as Yup from "yup";
import { Card } from "react-bootstrap";

class ReportPostForeignTravelComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            generalQuestionOptions: [],
            isLoading: false
        }
    }

    componentDidMount() {
        // Get General Question Types
        getPostForeignTravelQuestionTypes().then((response) => {
            const questionOptions = response.data.map((questionType) => {
                return {
                    questionType: questionType,
                    response: undefined,
                    comments: ""
                };
            });

            this.setState({
                generalQuestionOptions: questionOptions
            });
        });
    }

    onSubmit(values) {
        this.setState({isLoading: true});
        if (values && values.generalQuestions) {
            for (let eachQuestion of values.generalQuestions) {
                eachQuestion.questionType = eachQuestion.questionType.name;
            }
        }
        console.log(values);
        this.props.reportPostForeignTravel(values, this.props.match.params.emailCode, (response) => {
            if (SUCCESS === response) {
                showSuccessGrowl("Post Travel Response", "Your post travel response has been submitted successfully!");
                this.close();
                this.setState({isLoading: false});
            } else {
                showErrorGrowl("Post Travel Response submission error", "Cannot confirm your post travel response. Please contact your security officer");
                this.setState({
                    result: 'Cannot confirm your post travel response. Please contact your security officer'
                })
                this.setState({isLoading: false});
            }
        })
    }

    close = () => {
        let hashKey = sessionStorage.getItem(ISSUE_TO_UPLOAD);
        if (hashKey) {
            sessionStorage.setItem(ISSUE_TO_UPLOAD, null);
            this.props.history.push("/reporting/subject/taskList");
        } else {
            this.props.history.push("/misc/closeBrowser");
        }
    }

    render() {
        const trueFalseOptions = [
            {name: "Select ...", id: undefined},
            {name: "No", id: false},
            {name: "Yes", id: true}
        ]

        return (
            <div className="reporting_bg">
                <div className="container">
                    <Fragment>
                        <div className="heading_container">
                            <h3>Post Foreign Travel Questionnaire</h3>
                        </div>
                        <Formik
                            initialValues={{
                                "generalQuestions": this.state.generalQuestionOptions,
                                "additionalComments": ""
                            }}
                            validationSchema={Yup.object().shape({
                                generalQuestions: Yup.array().of(
                                    Yup.object().shape({
                                        questionType: Yup.mixed().nullable(),
                                        response: Yup.boolean().required("Select Yes or No"),
                                        comments: Yup.string().when("response", {
                                            is: true,
                                            then: (schema) => schema.required("Comments are required")
                                        })
                                    })
                                )
                            })}
                            enableReinitialize={true}
                            onSubmit={this.onSubmit.bind(this)}
                        >
                            {({isSubmitting, values, handleChange, setFieldValue, handleSubmit, touched, errors, handleBlur}) => (
                                <form onSubmit={handleSubmit} noValidate className="post_overflow">
                                    <CommonUtils.ScrollToFieldError/>
                                    {values.generalQuestions && values.generalQuestions.map((question, index) => {
                                        return (
                                            <Card>
                                                <Card.Body>
                                                <Fragment key={index}>
                                                {/*<Form.Group>*/}
                                                {/*    <Form.Check*/}
                                                {/*        name={`generalQuestions.${index}.response`}*/}
                                                {/*        label={question.questionType.displayName}*/}
                                                {/*        onChange={handleChange}*/}
                                                {/*        isInvalid={touched.generalQuestions && errors.generalQuestions && touched.generalQuestions[index].response && !!errors.generalQuestions[index].response}*/}
                                                {/*        feedback={errors.generalQuestions && errors.generalQuestions[index].response}*/}
                                                {/*        id={`generalQuestions.${index}.response`}*/}
                                                {/*    />*/}
                                                {/*</Form.Group>*/}
                                              
                                                <Form.Row className="justify_center">
                                                    <Form.Group as={Col} md="12"
                                                                controlId={`generalQuestions.${index}.responselabel`}>
                                                        <Form.Label>{question.questionType.displayName}</Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="3"
                                                                controlId={`generalQuestions.${index}.response`}>
                                                        <Form.Control required as="select"
                                                                      name={`generalQuestions.${index}.response`}
                                                                      onChange={handleChange}
                                                                      onBlur={handleBlur}
                                                                      isInvalid={getIn(touched, `generalQuestions.${index}.response`) && !!getIn(errors, `generalQuestions.${index}.response`)}
                                                                      value={values[`generalQuestions.${index}.response`]}
                                                        >
                                                            {CommonUtils.getOptionsForSelect(trueFalseOptions)}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            {getIn(errors, `generalQuestions.${index}.response`)}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="justify_center">
                                                    <Form.Group as={Col} md="12"
                                                                controlId={`generalQuestions.${index}.comments`}>
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name={`generalQuestions.${index}.comments`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Comments"
                                                            isInvalid={getIn(touched, `generalQuestions.${index}.comments`) && !!getIn(errors, `generalQuestions.${index}.comments`)}
                                                            value={values.generalQuestions[index].comments}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {getIn(errors, `generalQuestions.${index}.comments`)}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                            </Fragment>
                                                </Card.Body>
                                            </Card>
                                        )
                                    })}
                                    <Card>
                                        <Card.Body>
                                        <Form.Row className="justify_center">
                                        <Form.Group as={Col} md="12" controlId={`additionalComments`}>
                                            <Form.Label>Additional Comments</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="additionalComments"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Additional Comments"
                                                isInvalid={touched.additionalComments && !!errors.additionalComments}
                                                value={values.additionalComments}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.additionalComments}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                        </Card.Body>
                                        <Card.Footer>
                                        <ButtonToolbar className="p-0">
                                                <Button variant="primary" type="submit"
                                                        disabled={isSubmitting}>Submit</Button>
                                                <Button variant="danger" onClick={this.close}>Cancel</Button>
                                            </ButtonToolbar>
                                        </Card.Footer>
                                    </Card>
                                   

                                </form>
                            )}
                        </Formik>
                    </Fragment>
                </div>
                {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
            </div>
        );
    }
}

export const ReportPostForeignTravel = connect(null, {reportPostForeignTravel})(ReportPostForeignTravelComponent);