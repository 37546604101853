import React, {Component, Fragment} from "react";
import {Form, Card, Col, Button} from "react-bootstrap";
import * as Yup from "yup";
import {CommonUtils} from "../../../../actions/common_utils"
import {Link} from "react-router-dom";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {connect} from "react-redux";
import {reportBlackmail} from "../../../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import {Formik, Field} from "formik";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";

class ReportBlackmailComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incidentTypes: [],
            isLoading: false
        }
    }

    onSubmit = (values) => {
        this.setState({isLoading: true});
        this.props.reportBlackmail(values, () => {
            this.props.showSuccessGrowl("Blackmail Reported");
            this.props.history.push("/reporting/subject");
            this.setState({isLoading: false});
        }, (error) => {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
                this.props.history.push("/reporting/subject/emailCheck");
            } else if (error.response.status === 503 && error.response.data && error.response.data.includes('OTP code has expired')){
                this.props.showErrorGrowl("Session has expired", "Please start over and provide your email.")
                sessionStorage.setItem(VERIFIED_EMAIL, undefined);
                this.props.history.push("/reporting/subject/emailCheck");
            }
            this.setState({isLoading: false});
        })
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {handleSubmit} = this.props;
        const {incidentTypes} = this.state;

        return (
            <Formik
                initialValues={{
                    "namesInvolved": "",
                    "contactMethod": "PHONE",
                    "contact": "",
                    "typeOfInfoSought": "",
                    "background": "",
                    "natureOfIncident": "ELICITATION",
                    "startDate": ""
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                        Yup.object().shape({
                            namesInvolved: Yup.string().required('Enter name(s) of threat'),
                            contactMethod: Yup.string().required('Please select one contact method'),
                            typeOfInfoSought: Yup.string().required('Enter the type of information sought'),
                            natureOfIncident: Yup.string().required('Enter the Incident Type'),
                            startDate: Yup.date().required('Enter date contact initiated!')
                        })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                   
                   <div className="reporting_bg">
                    <div className="container">
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="heading_container">
                        <h3>Report Blackmail/Elicitation Form</h3>
                        </div>
                        
                        <Card>
                            <Card.Header>Threat Information</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId={'namesInvolved'}>
                                        <Form.Label>Names Involved</Form.Label>
                                        <Form.Control required type="text" name={'namesInvolved'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Names Involved (Comma separated)'}
                                                      isInvalid={touched.namesInvolved && !!errors.namesInvolved}
                                                      value={values.namesInvolved}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.namesInvolved}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'contactMethod'}>
                                        <Form.Label>Contact Method</Form.Label>
                                        <Form.Control required as="select" name={'contactMethod'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      placeholder={'Method of contact'}
                                                      isInvalid={!!errors.contactMethod}
                                                      value={values.contactMethod}
                                        >
                                            {CommonUtils.getOptionsForSelect(
                                                [{id: "PHONE", name: "Phone"},
                                                {id: "EMAIL", name: "Email"},
                                                {id: "IN_PERSON", name: "In Person"},
                                                {id: "SNAIL_MAIL", name: "By Postal Mail/Courier"}])}
                                        </Form.Control>

                                        <Form.Control.Feedback type="invalid">
                                            {errors.contactMethod}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'contact'}>
                                        <Form.Label>Contact Phone/Email or Address</Form.Label>
                                        <Form.Control required type="text" name={'contact'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      placeholder={'Phone, Email or Address'}
                                                      isInvalid={touched.contact && !!errors.contact}
                                                      value={values.contact}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contact}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'typeOfInfoSought'}>
                                        <Form.Label>Information Sought</Form.Label>
                                        <Form.Control required type="text" name={'typeOfInfoSought'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      placeholder={'Information Sought'}
                                                      isInvalid={touched.typeOfInfoSought && !!errors.typeOfInfoSought}
                                                      value={values.typeOfInfoSought}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.typeOfInfoSought}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'background'}>
                                        <Form.Label>Circumstances that lead to the threat</Form.Label>
                                        <Form.Control required type="text" name={'background'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      placeholder={'Background information'}
                                                      isInvalid={touched.background && !!errors.background}
                                                      value={values.background}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.background}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'natureOfIncident'}>
                                        <Form.Label>Incident Type</Form.Label>
                                        <Form.Control required as="select" name={'natureOfIncident'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      placeholder={'Nature of the incident'}
                                                      isInvalid={!!errors.natureOfIncident}
                                                      value={values.natureOfIncident}
                                        >
                                            {CommonUtils.getOptionsForSelect(
                                                [{id: "ELICITATION", name: "Elicitation"},
                                                    {id: "EXPLOITATION", name: "Exploitation"},
                                                    {id: "COERCION", name: "Coercion"},
                                                    {id: "ENTICEMENT", name: "Enticement"},
                                                    {id: "BLACKMAIL", name: "Black Mail"}])}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.natureOfIncident}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId={'startDate'}>
                                        <Form.Label>Incident Date</Form.Label>
                                        <Field
                                            id="startDate"
                                            name="startDate"
                                            component={FormikDatePickerComponent}
                                            placeholder={"Incident Date (MM/DD/YYYY)"}

                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                            <Card.Footer>
                            <ButtonToolbar>
                                    <Button variant="primary" type="submit">Submit</Button>
                                    {/* <Link to="/reporting/subject/reportingForeignInformation" className="btn btn-danger">Cancel</Link> */}
                                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Report Blackmail/Elicitation Form"
                                                         handleDeleteCallback={this.handleCancel}/>
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>

                    </Form>
                    </div>
                    {this.state.isLoading ? <div className="block-ui"> <div className="loader"></div></div>:null}
                   </div>
                   
               
                )}
            </Formik>
        )
    }
}



export const ReportBlackmail = connect(null, {reportBlackmail, showSuccessGrowl, showErrorGrowl})(ReportBlackmailComponent);